/**
 *
 * WidgetModal
 *
 */

import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Map } from 'immutable'

import Breadcrumb, { BreadcrumbItem } from 'components/Breadcrumb'
import TextLink from 'components/TextLink'
import Button from 'components/Button'
import { WIDGET_TYPES } from 'appConstants'
import {
  selectSavedSearches,
  selectSonraiSearches,
  selectQueryTypes,
} from 'containers/SonraiData/selectors'
import { selectControlGroups } from 'containers/ControlFrameworkData/selectors'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import {
  updateEditWidget,
  addSCWidget,
} from 'containers/SolutionCenter/actions'
import Icon from 'components/Icon'

import {
  selectSearchCardsBySearchId,
  selectWidgetSize,
  selectWidgetType,
  selectWidgetTitle,
  selectWidgetOptions,
  selectWidgetSelector,
  selectWidgetSubTitle,
  selectPreviewWidget,
  selectWidgetResultLayout,
} from './selectors'
import reducer from './reducer'
import messages from './messages'
import {
  setWidgetTitle,
  setWidgetSubTitle,
  setWidgetType,
  setWidgetSelector,
  setWidgetFormatter,
  setWidgetSearchField,
  setWidgetSavedSearch,
  clearWidgetModal,
  togglePreviewWidget,
  setWidgetSize,
  setWidgetOptions,
  setWidgetSonraiSearch,
  loadWidget,
} from './actions'
import sagas from './sagas'
import RatioConfig from './RatioConfig'
import BigCountConfig from './BigCountConfig'
import GaugeConfig from './GaugeConfig'
import RegionsWidgetConfig from './RegionsWidgetConfig'
import TableConfig from './TableConfig'
import ListWidgetConfig from './ListWidgetConfig'
import PieChartWidgetConfig from './PieChartWidgetConfig'
import BarChartWidgetConfig from './BarChartWidgetConfig'
import MapWidgetConfig from './AdvMapWidgetConfig'
import AlertWidgetConfig from './AlertWidgetConfig'
import WidgetModalErrorBoundary from './WidgetModalErrorBoundary'
import WidgetTypePicker from './WidgetTypePicker'
import SparkWidgetConfig from './SparkWidgetConfig'
import ComplianceWidgetConfig from './ComplianceWidgetConfig'
import TextWidgetConfig from './TextWidgetConfig'

const hasPreview = [
  'bigCount',
  'barChart',
  'pieChart',
  'alert',
  'spark',
  'compliance',
  'gauge',
  'ratio',
  'list',
]

const styles = {
  footerTextContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  previewModalContainer: {
    display: 'flex',
    justifyContent: 'center',
    minWidth: '1100px',
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
  },
  breadcrumbContainer: {
    marginBottom: '1em',
  },
}

export class WidgetModal extends React.PureComponent {
  state = {
    valid: false,
  }

  componentDidUpdate(oldProps) {
    if (
      !oldProps.isOpen &&
      this.props.isOpen &&
      !this.props.editingWidget.isEmpty()
    ) {
      this.props.loadWidget(this.props.editingWidget)
    }

    if (oldProps.isOpen && !this.props.isOpen) {
      this.props.clearWidgetModal()
    }
  }

  componentWillUnmount() {
    this.props.clearWidgetModal()
  }

  setValidity = valid => {
    this.setState({
      valid,
    })
  }

  getSelector = (widgetSelector, widgetType) => {
    if (widgetSelector) {
      return `${widgetSelector}`
    } else {
      return WIDGET_TYPES[widgetType]
    }
  }

  updateWidget = () => {
    const {
      widgetType,
      widgetTitle,
      widgetSubTitle,
      widgetSize,
      widgetSelector,
      widgetResultLayout,
      widgetOptions,
    } = this.props

    this.props.updateEditWidget(this.props.card, {
      sid: this.props.editingWidget.get('sid'),
      srn: this.props.editingWidget.get('srn'),
      title: widgetTitle,
      subtitle: widgetSubTitle,
      type: widgetType,
      widgetSize: widgetSize,
      resultLayout: widgetResultLayout,
      selection: this.getSelector(widgetSelector, widgetType),
      contains: {
        add: Object.values(widgetResultLayout.indexedSearches),
      },
      options: widgetOptions,
    })
  }

  addWidget = () => {
    const {
      widgetType,
      widgetTitle,
      widgetSubTitle,
      widgetSize,
      widgetSelector,
      widgetResultLayout,
      widgetOptions,
    } = this.props

    const searches = Object.values(
      widgetResultLayout.indexedSearches
    ).map(searchSID => this.props.savedSearches.get(searchSID).get('srn'))

    this.props.addSCWidget(this.props.card, {
      title: widgetTitle,
      subtitle: widgetSubTitle,
      type: widgetType,
      resultLayout: widgetResultLayout,
      selection: this.getSelector(widgetSelector, widgetType),
      widgetSize: [widgetSize[0], widgetSize[1]],
      widgetLocation: [0, 0],
      static: false,
      contains: { add: searches },
      options: widgetOptions,
    })
  }

  setWidgetType = e => {
    const type = e.target.value
    this.selectType(type)
  }

  resetType = () => {
    this.props.setWidgetType(null)
    if (this.props.previewWidget) {
      this.props.togglePreviewWidget()
    }
  }

  selectType = type => {
    this.props.setWidgetType(type)
    if (
      (hasPreview.includes(type) && !this.props.previewWidget) ||
      (!hasPreview.includes(type) && this.props.previewWidget)
    ) {
      this.props.togglePreviewWidget()
    }

    // [Width, Height]
    if (type === WIDGET_TYPES.TABLE || type === WIDGET_TYPES.TEXT) {
      this.props.setWidgetSize([20, 12])
    } else if (type === WIDGET_TYPES.GAUGE) {
      this.props.setWidgetSize([12, 8])
    } else if (type === WIDGET_TYPES.REGIONS) {
      this.props.setWidgetSize([20, 12])
    } else if (type === WIDGET_TYPES.ALERT) {
      this.props.setWidgetSize([14, 11])
    } else if (type === WIDGET_TYPES.ADVMAP) {
      this.props.setWidgetSize([30, 18])
    } else if (type === WIDGET_TYPES.LIST) {
      this.props.setWidgetSize([8, 12])
    } else if (type === WIDGET_TYPES.SPARK) {
      this.props.setWidgetSize([28, 8])
    } else if (type === WIDGET_TYPES.COMPLIANCE) {
      this.props.setWidgetSize([10, 8])
    } else {
      this.props.setWidgetSize([8, 6])
    }
  }

  setWidgetSearchField = (field, index) => {
    this.props.setWidgetSearchField(field, index, this.props.savedSearches)
  }

  getConfigComponent = () => {
    const type = this.props.widgetType
    switch (type) {
      case WIDGET_TYPES.BIG_COUNT:
        return BigCountConfig
      case WIDGET_TYPES.TABLE:
        return TableConfig
      case WIDGET_TYPES.RATIO:
        return RatioConfig
      case WIDGET_TYPES.GAUGE:
        return GaugeConfig
      case WIDGET_TYPES.LIST:
        return ListWidgetConfig
      case WIDGET_TYPES.REGIONS:
        return RegionsWidgetConfig
      case WIDGET_TYPES.PIE_CHART:
        return PieChartWidgetConfig
      case WIDGET_TYPES.BAR_CHART:
        return BarChartWidgetConfig
      case WIDGET_TYPES.ADVMAP:
        return MapWidgetConfig
      case WIDGET_TYPES.ALERT:
        return AlertWidgetConfig
      case WIDGET_TYPES.SPARK:
        return SparkWidgetConfig
      case WIDGET_TYPES.COMPLIANCE:
        return ComplianceWidgetConfig
      case WIDGET_TYPES.TEXT:
        return TextWidgetConfig
      default:
        return null
    }
  }

  getWidgetTypeLabel = () => {
    switch (this.props.widgetType) {
      case WIDGET_TYPES.BIG_COUNT:
        return 'Big Count'
      case WIDGET_TYPES.TABLE:
        return 'Table'
      case WIDGET_TYPES.RATIO:
        return 'Ratio'
      case WIDGET_TYPES.GAUGE:
        return 'Gauge'
      case WIDGET_TYPES.LIST:
        return 'List'
      case WIDGET_TYPES.REGIONS:
        return 'Regions'
      case WIDGET_TYPES.PIE_CHART:
        return 'Pie Chart'
      case WIDGET_TYPES.BAR_CHART:
        return 'Bar Chart'
      case WIDGET_TYPES.ADVMAP:
        return 'Arc Map'
      case WIDGET_TYPES.ALERT:
        return 'Alerts'
      case WIDGET_TYPES.SPARK:
        return 'Compliance over Time'
      case WIDGET_TYPES.COMPLIANCE:
        return 'Current Compliance'
      case WIDGET_TYPES.TEXT:
        return 'Text'
      default:
        return ''
    }
  }

  getWidgetInputs = () => {
    const ConfigComponent = this.getConfigComponent()

    if (!ConfigComponent) {
      return null
    }

    return (
      <WidgetModalErrorBoundary>
        <ConfigComponent
          controlGroupOptions={this.props.controlGroupOptions}
          previewWidget={this.props.previewWidget}
          getQueryBuilder={this.props.getQueryBuilder}
          setWidgetSonraiSearch={this.props.setWidgetSonraiSearch}
          savedSearches={this.props.savedSearches}
          searchCards={this.props.searchCards}
          savedSonraiSearches={this.props.sonraiSearches}
          setWidgetOptions={this.props.setWidgetOptions}
          setWidgetSavedSearch={this.props.setWidgetSavedSearch}
          setWidgetSearchField={this.setWidgetSearchField}
          setWidgetSubTitle={this.props.setWidgetSubTitle}
          editMode={!this.props.editingWidget.isEmpty()}
          queryTypes={this.props.queryTypes}
          setWidgetTitle={this.props.setWidgetTitle}
          setValidity={this.setValidity}
          saveCheckBox={this.props.saveCheckBox}
          widgetTitle={this.props.widgetTitle}
          widgetSubTitle={this.props.widgetSubTitle}
          widgetSearchCards={
            this.props.widgetResultLayout
              ? this.props.widgetResultLayout.searchCards
              : []
          }
          widgetSavedSearches={
            this.props.widgetResultLayout
              ? this.props.widgetResultLayout.indexedSearches
              : []
          }
          widgetOptions={
            this.props.widgetResultLayout
              ? this.props.widgetResultLayout.widgetOptions
              : []
          }
          widgetSonraiSearches={
            this.props.widgetOptions
              ? this.props.widgetOptions.sonraiSearches
              : []
          }
        />
      </WidgetModalErrorBoundary>
    )
  }

  renderContent = () => {
    if (!this.props.widgetType) {
      return <WidgetTypePicker selectType={this.selectType} />
    }

    return (
      <div>
        {this.props.editingWidget.isEmpty() && (
          <div style={styles.breadcrumbContainer}>
            <Breadcrumb>
              <BreadcrumbItem>
                <TextLink color="secondary" onClick={this.resetType}>
                  Widget Types
                </TextLink>
              </BreadcrumbItem>
              <BreadcrumbItem>{this.getWidgetTypeLabel()}</BreadcrumbItem>
            </Breadcrumb>
          </div>
        )}
        <div>{this.getWidgetInputs()}</div>
      </div>
    )
  }

  render() {
    const widgetCanPreview = hasPreview.includes(this.props.widgetType)
    const isDisabled =
      !this.state.valid || this.props.saving || !this.props.widgetType

    return (
      <Modal
        size="lg"
        isOpen={this.props.isOpen}
        toggle={this.props.onClose}
        backdrop="static"
        style={this.props.previewWidget ? styles.previewModalContainer : {}}
      >
        <ModalHeader toggle={this.props.onClose}>
          {this.props.editingWidget.isEmpty() ? (
            <FormattedMessage {...messages.header} />
          ) : (
            <FormattedMessage {...messages.updateHeader} />
          )}
        </ModalHeader>

        <ModalBody>{this.renderContent()}</ModalBody>
        <ModalFooter style={widgetCanPreview ? styles.footerContainer : {}}>
          <div style={widgetCanPreview ? styles.footerTextContainer : {}}>
            {widgetCanPreview && (
              <TextLink
                onClick={this.props.togglePreviewWidget}
                color="secondary"
              >
                {this.props.previewWidget
                  ? 'Hide Widget Preview'
                  : 'Show Widget Preview'}
              </TextLink>
            )}
            <div>
              <TextLink
                style={{ margin: '0.8rem' }}
                color="secondary"
                onClick={this.props.onClose}
              >
                Cancel
              </TextLink>
              <Button
                color="primary"
                onClick={
                  this.props.editingWidget.isEmpty()
                    ? this.addWidget
                    : this.updateWidget
                }
                disabled={isDisabled}
              >
                {this.props.saving ? (
                  <Icon spin fa name="sync" transform="shrink-3" />
                ) : this.props.editingWidget.isEmpty() ? (
                  'Add'
                ) : (
                  'Update'
                )}
              </Button>{' '}
            </div>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

WidgetModal.defaultProps = {
  editingWidget: Map(),
}

WidgetModal.propTypes = {
  controlGroupOptions: PropTypes.object,
  editingWidget: ImmutablePropTypes.map.isRequired,
  getQueryBuilder: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  loadWidget: PropTypes.func.isRequired,
  previewWidget: PropTypes.bool,
  queryTypes: ImmutablePropTypes.map,
  savedSearches: ImmutablePropTypes.map.isRequired,
  saving: PropTypes.bool,
  searchCards: ImmutablePropTypes.map.isRequired,
  setWidgetOptions: PropTypes.func.isRequired,
  setWidgetTitle: PropTypes.func.isRequired,
  setWidgetSize: PropTypes.func.isRequired,
  setWidgetSubTitle: PropTypes.func.isRequired,
  setWidgetType: PropTypes.func.isRequired,
  setWidgetSelector: PropTypes.func.isRequired,
  setWidgetFormatter: PropTypes.func.isRequired,
  setWidgetSearchField: PropTypes.func.isRequired,
  setWidgetSavedSearch: PropTypes.func.isRequired,
  card: PropTypes.shape({
    sid: PropTypes.string.isRequired,
    srn: PropTypes.string.isRequired,
  }).isRequired,
  layout: PropTypes.array.isRequired,
  clearWidgetModal: PropTypes.func.isRequired,
  addSCWidget: PropTypes.func.isRequired,
  setWidgetSonraiSearch: PropTypes.func.isRequired,
  sonraiSearches: ImmutablePropTypes.list.isRequired,
  saveCheckBox: PropTypes.func,
  togglePreviewWidget: PropTypes.func.isRequired,
  updateEditWidget: PropTypes.func,
  widgetType: PropTypes.string,
  widgetTitle: PropTypes.string,
  widgetSubTitle: PropTypes.string,
  widgetSize: PropTypes.array,
  widgetSelector: PropTypes.string,
  widgetResultLayout: PropTypes.object,
  widgetOptions: PropTypes.object,
}

const mapStateToProps = createStructuredSelector({
  controlGroupOptions: selectControlGroups,
  previewWidget: selectPreviewWidget,
  queryTypes: selectQueryTypes,
  savedSearches: selectSavedSearches,
  sonraiSearches: selectSonraiSearches,
  searchCards: selectSearchCardsBySearchId,
  widgetType: selectWidgetType,
  widgetTitle: selectWidgetTitle,
  widgetSubTitle: selectWidgetSubTitle,
  widgetSize: selectWidgetSize,
  widgetSelector: selectWidgetSelector,
  widgetResultLayout: selectWidgetResultLayout,
  widgetOptions: selectWidgetOptions,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addSCWidget,
      setWidgetOptions,
      setWidgetTitle,
      setWidgetSize,
      setWidgetSubTitle,
      setWidgetType,
      togglePreviewWidget,
      setWidgetSelector,
      setWidgetFormatter,
      setWidgetSearchField,
      setWidgetSavedSearch,
      clearWidgetModal,
      setWidgetSonraiSearch,
      loadWidget,
      updateEditWidget,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withReducer = injectReducer({ key: 'WidgetModal', reducer })
const withSaga = injectSaga({ key: 'WidgetModal', saga: sagas })

export default compose(withReducer, withConnect, withSaga)(WidgetModal)
