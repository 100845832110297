/*
 *
 * WidgetModal actions
 *
 */

import {
  LOAD_WIDGET,
  SET_WIDGET_TITLE,
  SET_WIDGET_SUB_TITLE,
  SET_WIDGET_TYPE,
  SET_WIDGET_SELECTOR,
  SET_WIDGET_FORMATTER,
  SET_WIDGET_SEARCH_FIELD,
  SET_WIDGET_SAVED_SEARCH,
  CLEAR_WIDGET_MODAL,
  SET_WIDGET_SIZE,
  SET_WIDGET_OPTIONS,
  SET_SONRAI_SAVED_SEARCHES,
  SET_WIDGET_SONRAI_SAVED_SEARCH,
  GET_SONRAI_SAVED_SEARCHES,
  TOGGLE_PREVIEW_WIDGET,
} from './constants'
import { createAction } from 'redux-actions'

export const clearWidgetModal = createAction(CLEAR_WIDGET_MODAL)
export const getSavedSonraiSearches = createAction(GET_SONRAI_SAVED_SEARCHES)
export const setSonraiSavedSearches = createAction(
  SET_SONRAI_SAVED_SEARCHES,
  searches => ({
    searches,
  })
)

export const setWidgetSavedSearch = createAction(
  SET_WIDGET_SAVED_SEARCH,
  (search, index) => {
    return {
      search,
      index,
    }
  }
)

export const setWidgetSonraiSearch = createAction(
  SET_WIDGET_SONRAI_SAVED_SEARCH,
  (search, index) => {
    return {
      search,
      index,
    }
  }
)

export const setWidgetSearchField = createAction(
  SET_WIDGET_SEARCH_FIELD,
  (field, index, savedSearches) => ({
    field,
    index,
    savedSearches,
  })
)
export const setWidgetTitle = createAction(SET_WIDGET_TITLE, input => ({
  input,
}))
export const setWidgetSubTitle = createAction(SET_WIDGET_SUB_TITLE, input => ({
  input,
}))
export const setWidgetType = createAction(SET_WIDGET_TYPE, input => ({
  input,
}))
export const setWidgetSelector = createAction(SET_WIDGET_SELECTOR, input => ({
  input,
}))
export const setWidgetFormatter = createAction(SET_WIDGET_FORMATTER, input => ({
  input,
}))

export const setWidgetSize = createAction(SET_WIDGET_SIZE)
export const setWidgetOptions = createAction(SET_WIDGET_OPTIONS)
export const loadWidget = createAction(LOAD_WIDGET)
export const togglePreviewWidget = createAction(TOGGLE_PREVIEW_WIDGET)
