/*
 *
 * WidgetModal reducer
 *
 */

import { fromJS, Map } from 'immutable'
import {
  LOAD_WIDGET,
  SET_WIDGET_TITLE,
  SET_WIDGET_SUB_TITLE,
  SET_WIDGET_TYPE,
  SET_WIDGET_SELECTOR,
  SET_WIDGET_FORMATTER,
  SET_WIDGET_SEARCH_FIELD,
  SET_WIDGET_SAVED_SEARCH,
  CLEAR_WIDGET_MODAL,
  SET_WIDGET_SIZE,
  SET_WIDGET_OPTIONS,
  SET_WIDGET_SONRAI_SAVED_SEARCH,
  TOGGLE_PREVIEW_WIDGET,
} from './constants'
import { handleActions } from 'redux-actions'

const initialState = fromJS({
  widgetFormatter: null,
  widgetResultLayout: {
    indexedSearches: {},
    searchCards: {},
    widgetOptions: {},
  },
  widgetSelector: '',
  widgetSize: [],
  widgetSubTitle: '',
  widgetTitle: '',
  widgetType: null,
  widgetOptions: {
    sonraiSearches: {},
  },
  previewWidget: false,
})

const WidgetModalReducer = handleActions(
  {
    [CLEAR_WIDGET_MODAL]: () => initialState,
    [SET_WIDGET_SEARCH_FIELD]: (state, { payload }) => {
      const widgetIndex = payload.index ? payload.index : 'search'
      const searchId = state.getIn([
        'widgetResultLayout',
        'indexedSearches',
        widgetIndex,
      ])
      const savedSearch = payload.savedSearches.find(
        search => search.get('sid') === searchId,
        null,
        Map()
      )
      if (payload.field) {
        return state.setIn(
          ['widgetResultLayout', 'searchCards', widgetIndex],
          savedSearch.getIn(['query', 'fields', payload.field], Map())
        )
      } else {
        return state.deleteIn([
          'widgetResultLayout',
          'searchCards',
          widgetIndex,
        ])
      }
    },
    [SET_WIDGET_SAVED_SEARCH]: (state, { payload }) => {
      if (payload.search) {
        return state.setIn(
          [
            'widgetResultLayout',
            'indexedSearches',
            payload.index ? payload.index : 'search',
          ],
          payload.search
        )
      } else {
        return state.deleteIn([
          'widgetResultLayout',
          'indexedSearches',
          payload.index ? payload.index : 'search',
        ])
      }
    },
    [LOAD_WIDGET]: (state, { payload }) => {
      return state
        .set('widgetType', payload.get('type'))
        .set('widgetTitle', payload.get('title'))
        .set('widgetSubTitle', payload.get('subtitle'))
        .set('widgetResultLayout', payload.get('resultLayout'))
        .set('widgetOptions', payload.get('options'))
        .set('widgetSize', payload.get('widgetSize'))
    },
    [SET_WIDGET_SONRAI_SAVED_SEARCH]: (state, { payload }) => {
      if (payload.search) {
        return state.setIn(
          [
            'widgetOptions',
            'sonraiSearches',
            payload.index ? payload.index : 'search',
          ],
          payload.search
        )
      } else {
        return state.deleteIn([
          'widgetOptions',
          'sonraiSearches',
          payload.index ? payload.index : 'search',
        ])
      }
    },
    [SET_WIDGET_OPTIONS]: (state, { payload }) => {
      return state.mergeIn(
        ['widgetResultLayout', 'widgetOptions'],
        fromJS(payload)
      )
    },
    [SET_WIDGET_TITLE]: (state, { payload }) =>
      state.set('widgetTitle', payload.input),
    [SET_WIDGET_SUB_TITLE]: (state, { payload }) => {
      return state.set('widgetSubTitle', payload.input)
    },
    [SET_WIDGET_TYPE]: (state, { payload }) => {
      return state.set('widgetType', payload.input)
    },
    [SET_WIDGET_SIZE]: (state, { payload }) =>
      state.set('widgetSize', fromJS(payload)),
    [SET_WIDGET_SELECTOR]: (state, { payload }) => {
      return state.set('widgetSelector', payload.input)
    },
    [SET_WIDGET_FORMATTER]: (state, { payload }) => {
      return state.set('widgetFormatter', payload.input)
    },
    [TOGGLE_PREVIEW_WIDGET]: state => {
      return state.set('previewWidget', !state.get('previewWidget'))
    },
  },
  initialState
)

export default WidgetModalReducer
