import React from 'react'
import PropTypes from 'prop-types'
import { TwitterPicker } from 'react-color'
import uuid from 'uuid/v4'
import { FormattedMessage } from 'react-intl'

import Popover, { PopoverAnchor, PopoverBody } from 'components/Popover'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import messages from './messages'

const styles = {
  colorPreview: {
    width: '1em',
    height: '1em',
    border: '1px solid #888',
    display: 'inline-block',
    marginRight: '0.5em',
    borderRadius: '0',
  },
  colorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  noColor: {
    fontStyle: 'italic',
    color: '#777',
  },
}

export class ColorPicker extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showColorPicker: false,
    }

    this.uuid = uuid()
  }

  onPickColor = color => {
    this.props.onPickColor(color.hex)
    this.setState({
      showColorPicker: false,
    })
  }

  toggleColorPicker = () => {
    this.setState(state => ({
      showColorPicker: !state.showColorPicker,
    }))
  }

  onPopoverToggle = newVisibility => {
    this.setState({
      showColorPicker: newVisibility,
    })
  }

  render() {
    return (
      <div>
        <Popover
          isOpen={this.state.showColorPicker}
          onToggle={this.onPopoverToggle}
        >
          <PopoverAnchor>
            <div style={styles.colorContainer}>
              <span
                id={`colorpicker-${this.uuid}`}
                style={{
                  ...styles.colorPreview,
                  ...{ backgroundColor: this.props.color },
                }}
              />

              {this.props.color ? (
                this.props.color
              ) : (
                <span style={styles.noColor}>
                  {' '}
                  {this.props.noColorMessage || (
                    <FormattedMessage {...messages.noColor} />
                  )}
                </span>
              )}
            </div>
          </PopoverAnchor>
          <PopoverBody>
            <TwitterPicker
              onChangeComplete={this.onPickColor}
              color={this.props.color}
              colors={this.props.theme.chartColors}
              triangle={'hide'}
              styles={{
                default: {
                  card: {
                    border: 'none',
                    boxShadow: 'none',
                  },
                },
              }}
            />
          </PopoverBody>
        </Popover>
      </div>
    )
  }
}

ColorPicker.propTypes = {
  onPickColor: PropTypes.func,
  color: PropTypes.string,
  noColorMessage: PropTypes.string,
  theme: themeShape,
}

export default themeable(ColorPicker)
