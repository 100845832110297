import {
  SET_SELECTED_PIVOT,
  CLEAR_SELECTED_PIVOTS,
  ADD_TAG_FILTER,
} from './constants'
import { createAction } from 'redux-actions'

export const setSelectedPivot = createAction(SET_SELECTED_PIVOT)
export const clearSelectedPivots = createAction(CLEAR_SELECTED_PIVOTS)
export const addTagFilter = createAction(ADD_TAG_FILTER)
