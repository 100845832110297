import React, { Fragment } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'
import { List } from 'immutable'
import CombinedSearches from 'components/CombinedSearches'
import FormLabel from 'components/FormLabel'
import { REGION_MAP_SEARCH_NAME, SUPPORT_EMAIL } from 'appConstants'

export class RegionsWidgetConfig extends React.Component {
  componentDidUpdate() {
    this.updateValidity()
  }

  updateValidity = () => {
    const hasSonraiSearch = !!this.props.widgetSonraiSearches.search
    const hasSavedSearch = !!this.props.widgetSavedSearches.search
    const hasTitle = this.props.widgetTitle

    const valid = (hasSonraiSearch || hasSavedSearch) && hasTitle
    this.props.setValidity(valid)
  }

  setTitle = e => {
    this.props.setWidgetTitle(e.target.value)
  }

  setDescription = e => {
    const string = e.target.value

    this.props.setWidgetOptions({
      description: string,
    })
  }

  setSubTitle = e => {
    this.props.setWidgetSubTitle(e.target.value)
  }

  setSavedSearch = selectedOption => {
    this.props.setWidgetSavedSearch(
      selectedOption ? selectedOption.value : null,
      'search'
    )
  }

  selectSearchField = selectedOption => {
    this.props.setWidgetSearchField(
      selectedOption ? selectedOption.value : null,
      'search'
    )
  }

  setSonraiSearch = selectedOption => {
    this.props.setWidgetSonraiSearch(
      selectedOption ? selectedOption.value : null,
      'search'
    )
  }

  render() {
    const sonraiSearchName = this.props.widgetSonraiSearches['search']

    const savedSearchId = this.props.widgetSavedSearches['search']
    const savedSearchField = this.props.widgetSearchCards['search']
    const searchFieldsList = this.props.searchCards.get(
      this.props.widgetSavedSearches['search'],
      List()
    )

    return (
      <Fragment>
        <FormLabel label="Title" for="widgetTitle" required={true} />
        <Input
          type="text"
          name="widgetTitle"
          id="widgetTitle"
          placeholder="Number of Identities"
          value={this.props.widgetTitle || ''}
          onChange={this.setTitle}
        />

        <FormLabel label="Sub Title" for="widgetSubTitle" />
        <Input
          type="text"
          name="widgetSubTitle"
          id="widgetSubTitle"
          placeholder="By Group"
          value={this.props.widgetSubTitle || ''}
          onChange={this.setSubTitle}
        />

        <FormLabel label="Description" for="widgetDescription" />
        <Input
          id="widgetDescription"
          type="textarea"
          name="widgetDescription"
          onChange={this.setDescription}
          value={this.props.widgetOptions.description || ''}
        />

        <FormLabel label="Search" required={true} />
        <p style={{ fontSize: '0.9em', color: '#777' }}>
          Note: This widget only supports resources with a region property. For
          help, please contact <a href={`mailto: ${SUPPORT_EMAIL}`}>support</a>
        </p>
        <CombinedSearches
          savedSonraiSearches={this.props.savedSonraiSearches.filter(search =>
            search.get('name').includes(REGION_MAP_SEARCH_NAME)
          )}
          setSonraiSearch={this.setSonraiSearch}
          selectedSonraiValue={sonraiSearchName}
          name="widgetSearch"
          savedSearches={this.props.savedSearches}
          selectedSearchId={savedSearchId}
          selectSavedSearch={this.setSavedSearch}
          savedSearchDisabled={!!sonraiSearchName}
          selectedFieldId={savedSearchField ? savedSearchField.id : null}
          searchCards={searchFieldsList}
          searchFieldDisabled={searchFieldsList.isEmpty()}
          selectSearchField={this.selectSearchField}
          searchFieldRequired
        />
      </Fragment>
    )
  }
}

RegionsWidgetConfig.propTypes = {
  savedSearches: ImmutablePropTypes.iterable,
  savedSonraiSearches: ImmutablePropTypes.iterableOf(
    ImmutablePropTypes.contains({
      sid: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  searchCards: ImmutablePropTypes.map,
  setWidgetOptions: PropTypes.func,
  setWidgetSavedSearch: PropTypes.func,
  setWidgetSearchField: PropTypes.func,
  setWidgetSonraiSearch: PropTypes.func,
  setWidgetSubTitle: PropTypes.func,
  setWidgetTitle: PropTypes.func,
  setValidity: PropTypes.func,
  widgetOptions: PropTypes.shape({
    description: PropTypes.string,
  }),
  widgetSavedSearches: PropTypes.objectOf(PropTypes.array),
  widgetSearchCards: PropTypes.objectOf(PropTypes.object),
  widgetSonraiSearches: PropTypes.object,
  widgetSubTitle: PropTypes.string,
  widgetTitle: PropTypes.string,
}

export default RegionsWidgetConfig
