import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'
import ImmutablePropTypes from 'react-immutable-proptypes'
import Button from 'components/Button'
import TextLink from 'components/TextLink'
import Icon from 'components/Icon'
import FormLabel from 'components/FormLabel'
import { handleSCAdd } from 'containers/SolutionCenter/actions'

import { selectAddingSolutionCard, selectSortedCards } from './selectors'
import messages from './messages'

export class ModalAddSolutionCard extends React.Component {
  state = {
    title: '',
    showMessage: false,
  }

  styles = {
    description: {
      fontSize: '0.9em',
    },
  }

  componentDidUpdate(oldProps) {
    if (oldProps.addingSolutionCard && !this.props.addingSolutionCard) {
      this.props.toggle()
      this.setState({
        title: '',
      })
    }
  }

  setTitle = e => {
    this.setState({
      title: e.target.value,
      showMessage: false,
    })
  }

  handleReturn = key => {
    if (key === 13) {
      if (this.state.title !== '') {
        this.props.handleSCAdd(this.state.title)
      }
    }
  }

  handleSCAdd = () => {
    const title =
      this.state.title !== ''
        ? this.state.title.toLowerCase()
        : this.state.title
    const sortedCardNames = this.props.sortedCards.map(card =>
      card.get('name').toLowerCase()
    )
    if (title !== '' && !sortedCardNames.includes(title)) {
      this.props.handleSCAdd(this.state.title)
    } else {
      this.setState({ showMessage: true })
    }
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>
          <FormattedMessage {...messages.header} />
        </ModalHeader>
        <ModalBody>
          <p style={this.styles.description}>
            Solution Cards enable you to create a visual representation of
            information or details about your cloud account.
          </p>
          <p style={this.styles.description}>
            Widgets on the Solution Card display the results of saved searches,
            or framework status and summary information from the Control Center
          </p>
          <FormLabel for="scTitle" required>
            <FormattedMessage {...messages.nameLabel} />
          </FormLabel>
          <Input
            type="text"
            name="scTitle"
            id="scTitle"
            placeholder={this.props.intl.formatMessage(
              messages.titlePlaceholder
            )}
            value={this.state.title}
            onChange={this.setTitle}
            onKeyUp={e => this.handleReturn(e.keyCode)}
          />
        </ModalBody>
        <ModalFooter
          style={{
            display: 'flex',
            justifyContent: this.state.showMessage && 'space-between',
            alignItems: 'center',
          }}
        >
          {this.state.showMessage && (
            <TextLink
              color="warning"
              onClick={() => this.setState({ title: '' })}
            >
              <FormattedMessage {...messages.noDup} />
            </TextLink>
          )}
          <div>
            <TextLink color="secondary" onClick={this.props.toggle}>
              <FormattedMessage {...messages.cancelButton} />
            </TextLink>
            <Button
              color="primary"
              onClick={this.handleSCAdd}
              disabled={
                !this.state.title ||
                this.props.addingSolutionCard ||
                this.state.showMessage
              }
              style={{ margin: '0rem 0.75rem' }}
            >
              {this.props.addingSolutionCard ? (
                <Icon fa name="sync" spin />
              ) : (
                <FormattedMessage {...messages.createButton} />
              )}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}

ModalAddSolutionCard.propTypes = {
  addingSolutionCard: PropTypes.bool,
  handleSCAdd: PropTypes.func.isRequired,
  intl: intlShape,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  sortedCards: ImmutablePropTypes.iterable,
}

const mapStateToProps = createStructuredSelector({
  addingSolutionCard: selectAddingSolutionCard,
  sortedCards: selectSortedCards,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      handleSCAdd,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

export default compose(
  withConnect,
  injectIntl
)(ModalAddSolutionCard)
