/**
 *
 * GaugeWidget
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Gauge } from './gauge.min'
import _ from 'lodash'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

class GaugeComponent extends React.Component {
  constructor(props) {
    super(props)
    this.canvasRef = React.createRef()
  }

  waitForRef = () => {
    return new Promise(resolve => {
      const wait = () => {
        if (!this.canvasRef.current) {
          setTimeout(wait, 200)
          return
        }

        return resolve()
      }

      wait()
    })
  }

  componentDidMount() {
    this.waitForRef().then(this.drawGauge)
  }

  shouldComponentUpdate(newProps) {
    return (
      !_.isEqual(newProps.markers, this.props.markers) ||
      newProps.value !== this.props.value
    )
  }

  componentDidUpdate() {
    if (this.gauge) {
      this.setOptions(this.gauge)
      this.gauge.set(this.props.value) // set actual value
      this.gauge.render()
    }
  }

  setOptions = gauge => {
    const opts = {
      angle: 0, // The span of the gauge arc
      lineWidth: 0.3, // The line thickness
      radiusScale: 0.8,
      pointer: {
        length: 0.5, // // Relative to gauge radius
        strokeWidth: 0.035, // The thickness
        color: '#333', // Fill color
        hoveredColor: '#000000',
        valueColor: this.props.theme.primary,
        valueCircleColor: this.props.theme.secondary,
      },
      limitMax: true, // If false, max value increases automatically if value > maxValue
      limitMin: true, // If true, the min value of the gauge will be fixed
      highDpiSupport: true, // High resolution support
      staticZones: this.props.markers.map(marker => {
        return {
          strokeStyle: marker.color,
          min: marker.start,
          max: marker.end,
        }
      }),
      tipLabel: true,
    }

    gauge.setOptions(opts)
  }

  drawGauge = () => {
    if (!this.gauge && this.canvasRef.current) {
      const ref = this.canvasRef.current

      var gauge = new Gauge(ref)
      this.setOptions(gauge)

      gauge.maxValue = 100
      gauge.setMinValue(0)
      gauge.animationSpeed = 32
      gauge.set(this.props.value)
      gauge.render()

      this.gauge = gauge
    }
  }

  render() {
    return (
      <canvas
        onClick={this.props.onClick}
        ref={this.canvasRef}
        style={{ width: '100%', height: '100%' }}
      />
    )
  }
}

GaugeComponent.propTypes = {
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.number,
      end: PropTypes.number,
      color: PropTypes.string,
    })
  ),
  onClick: PropTypes.func,
  value: PropTypes.number,
  theme: themeShape,
}

export default themeable(GaugeComponent)
