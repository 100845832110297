import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'
import { injectIntl } from 'react-intl'
import { List } from 'immutable'

import CloudAccount from 'components/CloudAccount'
import { PIVOT_FILEDS } from 'query-builder'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Icon from 'components/Icon'
import messages from './messages'
import { selectPivot } from 'containers/SonraiData/selectors'

const styles = {
  appliedFilter: {
    fontWeight: 400,
  },
  filterStatus: {
    textAlign: 'center',
    margin: 'auto',
    borderBottom: '1px solid #eee',
    padding: '1em 0',
    fontSize: '1em',
  },
}

export class SolutionCenterFilterStatus extends React.Component {
  render() {
    if (this.props.pivot.isEmpty()) {
      return <div />
    }

    const appliedFilters = this.props.pivot
      .map((value, key) => {
        if (key === PIVOT_FILEDS.RELATIVE_DATE) {
          switch (value) {
            case 1:
              return this.props.intl.formatMessage(messages.statusDays1)
            case 7:
              return this.props.intl.formatMessage(messages.statusDays7)
            case 30:
              return this.props.intl.formatMessage(messages.statusDays30)
            case 90:
              return this.props.intl.formatMessage(messages.statusDays90)
          }

          return null
        }

        if (key === PIVOT_FILEDS.ACCOUNTS) {
          const accounts = value.map(accountId => {
            return (
              <div
                key={accountId}
                style={{ display: 'inline-block', marginRight: '1em' }}
              >
                <CloudAccount accountId={accountId} />
              </div>
            )
          })

          return (
            <div style={{ display: 'inline-block' }}>
              <span>{key}:&nbsp;</span> {accounts}
            </div>
          )
        }

        if (key === PIVOT_FILEDS.HAS_TAGS) {
          const tags = value || List()

          return `${key}: ${tags.toJS().join('; ')}`
        }

        if (key === PIVOT_FILEDS.SPECIFIC_DATES) {
          return `${key}: ${value.get('startDate')} - ${value.get('endDate')}`
        }

        if (List.isList(value)) {
          return `${key}: ${value.toJS().join('; ')}`
        }

        return `${key}: ${value}`
      })
      .toList()

    return (
      <div className="flip-in-hor-bottom" style={styles.filterStatus}>
        <Icon
          fa
          name="filter-solid"
          style={{
            color: this.props.theme.emphasis,
          }}
          transform="shrink-2"
          title={this.props.intl.formatMessage(messages.filterApplied)}
        />{' '}
        Filtering By <span style={styles.appliedFilter}>{appliedFilters}</span>
      </div>
    )
  }
}

SolutionCenterFilterStatus.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  pivot: ImmutablePropTypes.map,
  theme: themeShape,
}

const mapStateToProps = createStructuredSelector({
  pivot: selectPivot,
})

const withConnect = connect(mapStateToProps)

export default compose(
  withConnect,
  themeable,
  injectIntl
)(SolutionCenterFilterStatus)
