import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'
import FormLabel from 'components/FormLabel'
import MarkdownEditor from 'components/MarkdownEditor'

class TextWidgetConfig extends React.Component {
  setTitle = e => {
    this.props.setWidgetTitle(e.target.value)
  }

  componentDidUpdate() {
    this.updateValidity()
  }

  updateValidity = () => {
    const hasTitle = !!this.props.widgetTitle
    const hasMarkdown = !!this.props.widgetOptions.description
    const isValid = hasTitle && hasMarkdown
    this.props.setValidity(isValid)
  }

  render() {
    return (
      <div>
        <div>
          <FormLabel label="Title" for="title" required={true} />
          <Input
            type="text"
            name="textWidgetTitle"
            id="textWidgetTitle"
            placeholder="Title..."
            value={this.props.widgetTitle || ''}
            onChange={this.setTitle}
          />

          <FormLabel
            label="Markdown Content"
            for="widgetDescription"
            required={true}
          />

          <MarkdownEditor
            value={this.props.widgetOptions.description}
            onChange={value =>
              this.props.setWidgetOptions({
                description: value,
              })
            }
          />
        </div>
      </div>
    )
  }
}

TextWidgetConfig.propTypes = {
  setWidgetOptions: PropTypes.func,
  setWidgetTitle: PropTypes.func,
  widgetOptions: PropTypes.shape({
    description: PropTypes.string,
  }),
  setValidity: PropTypes.func,
  widgetTitle: PropTypes.string,
}

export default TextWidgetConfig
