/**
 *
 * RegionsWidget
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import { List } from 'immutable'

import messages from './messages'
import { TopTitle, CardBody } from 'components/Card'
import WidgetCard from 'components/WidgetCard'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { queryHasPivotFilter } from 'query-builder'
import SizeMe from 'components/SizeMe'
import { getSearchIdForSonraiSearches } from 'utils/sonraiUtils'
import { Map } from 'immutable'
import {
  hasSonraiSearch,
  getSonraiSearchQuery,
  getSonraiSearchData,
  getBaseSonraiSearchData,
  getFields,
  getSearchCard,
  getSelection,
} from 'query-builder'
import RegionsMap from './RegionsMap'
import { FormattedMessage } from 'react-intl'
import TextLink from 'components/TextLink'
const styles = {
  title: {
    fontSize: '22px',
    fontWeight: '300',
    marginBottom: '5px',
  },
  subtitle: {
    fontSize: '14px',
    fontWeight: '300',
    color: '#777777',
  },
}

const SizedRegionsMap = SizeMe(RegionsMap)

class RegionsWidget extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      thisHeight: null,
      thisWidth: null,
    }
  }

  getTableSavedSearchQuery = () => {
    let fields = getFields(
      this.props.savedSearches,
      this.props.resultLayout.indexedSearches.search
    )

    if (fields.isEmpty()) {
      return {}
    }

    const rootField = fields.find(
      statement => !statement.get('parentId'),
      null,
      Map()
    )

    const queryBuilder = this.props.getQueryBuilder(fields)
    queryBuilder.enableFlattenMode()
    queryBuilder.skipCounts()

    //Force-add the required columns if they are not present
    const searchCard = getSearchCard(this.props.resultLayout, 'search')

    queryBuilder.fields = queryBuilder.fields.update(searchCard.id, field => {
      if (!field.get('displayFields', List()).includes('region')) {
        field = field.update('displayFields', List(), propList =>
          propList.push('region')
        )
      }

      return field
    })

    const query = queryBuilder.buildPivotableSource(
      rootField.get('id'),
      'regionmapwidget',
      { limit: 1000 }
    )

    return { ...query, flatten: true }
  }

  getWidgetQueryConfig = () => {
    if (hasSonraiSearch(this.props.options, 'search')) {
      return getSonraiSearchQuery(this.props.options.sonraiSearches.search)
    } else {
      return this.getTableSavedSearchQuery()
    }
  }

  getRootItem = data => {
    const keys = Object.keys(data)
    const rootKey = keys[0]

    return data[rootKey]
  }

  onClickSearch = () => {
    this.props.onClickSearch({
      savedSearchId: this.props.resultLayout.indexedSearches.search,
      sonraiSearchName: this.props.options.sonraiSearches.search,
      searchTitle: this.props.title,
    })
  }

  getSearchName = () => {
    return hasSonraiSearch(this.props.options, 'search')
      ? this.props.options.sonraiSearches.search
      : ''
  }

  onClickSonraiSearchRegion = (allData, regionName) => {
    const baseData = getBaseSonraiSearchData(allData)
    const dataType = Object.keys(baseData)[0]
    const baseQuery = this.props.sonraiSearches.find(
      search => search.get('name') === this.props.options.sonraiSearches.search
    )

    if (!baseQuery) {
      return
    }

    const baseQueryContent = baseQuery.get('query')

    let finalQuery

    const hasWhereClauseRegex = new RegExp(
      `${dataType}\\s*\\(.*where\\s*:\\s*(\\{.*\\})\\).*\\{`
    )
    const whereMatch = baseQueryContent.match(hasWhereClauseRegex)
    if (whereMatch && whereMatch.length > 1) {
      //The sonrai search has a built-in where clause, so we want to preserve that
      const originalWhereClause = whereMatch[1]
      finalQuery = `
        {
          ${dataType} ( where: {
            and: [
              {region: {op: EQ, value: "${regionName}"}},
              ${originalWhereClause}
            ]
          }) {
            items {
              name
              region
              srn
            }
          }
        }
      `
    } else {
      finalQuery = `
        {
          ${dataType} ( where: {
            region: {op: EQ, value: "${regionName}"}
          }) {
            items {
              name
              region
              srn
            }
          }
        }
      `
    }

    this.props.onClickSearch({
      advancedQuery: finalQuery,
      sonraiSearchName: this.props.options.sonraiSearches.search,
      searchTitle: `${this.props.title} - ${regionName}`,
    })
  }

  onClickRegion = (allData, regionName) => {
    if (!regionName || !this.props.onClickSearch) {
      return
    }

    if (hasSonraiSearch(this.props.options, 'search')) {
      this.onClickSonraiSearchRegion(allData, regionName)
    } else {
      const filter = {
        label: 'region',
        value: regionName,
      }

      this.props.onClickSearch({
        savedSearchId: this.props.resultLayout.indexedSearches.search,
        searchTitle: this.props.title,
        filter: filter,
      })
    }
  }

  getUsedRegionInfo = data => {
    if (_.isEmpty(data)) {
      return {}
    }

    if (hasSonraiSearch(this.props.options, 'search')) {
      //This widget expects the results to be a shape like:
      // {
      //   "data": {
      //     "Data": {
      //       "group": [
      //         {
      //           "key": {
      //             "region": "eu-west-2"
      //           },
      //           "count": 7
      //         },
      //         {
      //           "key": {
      //             "region": "eastus"
      //           },
      //           "count": 1
      //         }
      //       ]
      //     }
      //   }
      // }

      const rootData = getSonraiSearchData(data)
      const groupResults = rootData.group || []
      const formatted = {}

      groupResults.forEach(result => {
        const regionName = _.get(result, ['key', 'region'], 'UNMAPPED')
        formatted[regionName] = result.count
      })

      return formatted
    } else {
      const fields = getFields(
        this.props.savedSearches,
        this.props.resultLayout.indexedSearches.search
      ).toJS()

      const searchCard = getSearchCard(this.props.resultLayout, 'search')
      const selection = getSelection(fields, searchCard, 'items', 'items')

      if (!selection) {
        return 'DATA ERROR'
      }

      const items = _.get(data, [...selection])
      const counts = _.countBy(items, 'region')
      return counts
    }
  }

  handleSizeChange = _.throttle(size => {
    this.setState({ thisHeight: size.height })

    this.setState({ thisWidth: size.width })
  }, 100)

  getSearchId = () => {
    const { options, sonraiSearches, resultLayout } = this.props
    let searchObj = Map({ uiSearch: null, advancedSearch: null })
    if (hasSonraiSearch(options, 'search')) {
      const searches = getSearchIdForSonraiSearches(options, sonraiSearches)
      searchObj = searchObj.set('advancedSearch', searches)
    } else {
      searchObj = searchObj.set('uiSearch', resultLayout.indexedSearches.search)
    }
    return searchObj
  }

  render() {
    if (this.props.data === undefined) {
      const queryConfig = this.getWidgetQueryConfig()
      const searchId = this.getSearchId()

      if (queryConfig) {
        if (!queryConfig.gqlStatement) {
          return (
            <WidgetCard
              searchId={searchId}
              loading={true}
              title={this.props.title}
              allowDelete={this.props.allowDelete}
              allowUpdate={this.props.allowUpdate}
              onRemove={this.props.onRemove}
              onEdit={this.props.onEdit}
            />
          )
        }

        const queryObj = gql`
          ${queryConfig.gqlStatement}
        `

        const filtered = queryHasPivotFilter(queryConfig.gqlStatement)
        return (
          <Query
            query={queryObj}
            variables={queryConfig.variables}
            notifyOnNetworkStatusChange
            pollInterval={300000}
            key={JSON.stringify(queryConfig)}
            context={{
              queryName: `regionsWidget_${_.snakeCase(this.props.title)}`,
            }}
          >
            {({ error, data, refetch, networkStatus }) => {
              const regionCounts = this.getUsedRegionInfo(data)
              return (
                <WidgetCard
                  searchId={searchId}
                  createdBy={this.props.widget && this.props.widget.createdBy}
                  allowDelete={this.props.allowDelete}
                  allowUpdate={this.props.allowUpdate}
                  small
                  onEdit={this.props.onEdit}
                  error={error}
                  onRemove={this.props.onRemove}
                  toggleStatic={this.props.toggleStatic}
                  static={this.props.static}
                  refetch={refetch}
                  networkStatus={networkStatus}
                  title={this.props.title}
                  filtered={filtered}
                  description={_.get(this.props.resultLayout, [
                    'widgetOptions',
                    'description',
                  ])}
                >
                  <TopTitle>
                    <TextLink
                      title={this.getSearchName()}
                      onClick={this.onClickSearch}
                      style={styles.title}
                    >
                      {this.props.title}
                    </TextLink>
                    <p style={styles.subtitle}> {this.props.subtitle} </p>
                  </TopTitle>
                  <CardBody
                    style={{
                      display: 'block',
                      height: 'auto',
                      width: 'auto',
                      overflow: 'hidden',
                    }}
                  >
                    {networkStatus !== 1 && !error && (
                      <SizedRegionsMap
                        size={{
                          height: this.state.thisHeight,
                          width: this.state.thisWidth,
                        }}
                        onSize={this.handleSizeChange}
                        regionCounts={regionCounts}
                        onClickRegion={clickedRegionName =>
                          this.onClickRegion(data, clickedRegionName)
                        }
                      />
                    )}
                  </CardBody>
                </WidgetCard>
              )
            }}
          </Query>
        )
      } else {
        return (
          <WidgetCard
            searchId={searchId}
            small
            createdBy={this.props.widget && this.props.widget.createdBy}
            title={this.props.title}
            description={_.get(this.props.resultLayout, [
              'widgetOptions',
              'description',
            ])}
          >
            <TopTitle>
              <p style={styles.title}> {this.props.title} </p>
              <p style={styles.subtitle}> {this.props.subtitle} </p>
            </TopTitle>
            <CardBody
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div>
                <FormattedMessage {...messages.err} />{' '}
              </div>
            </CardBody>
          </WidgetCard>
        )
      }
    } else {
      const regionCounts = this.props.data
      return (
        <WidgetCard
          small
          title={this.props.title}
          createdBy={this.props.widget && this.props.widget.createdBy}
        >
          <TopTitle style={{ display: 'flex', alignItems: 'center' }}>
            <TextLink style={styles.title}>{this.props.title}</TextLink>
          </TopTitle>
          <CardBody
            style={{
              display: 'block',
              height: 'auto',
              width: 'auto',
              overflow: 'hidden',
            }}
          >
            <SizedRegionsMap
              size={{
                height: this.state.thisHeight,
                width: this.state.thisWidth,
              }}
              onSize={this.handleSizeChange}
              regionCounts={regionCounts}
              onClickRegion={clickedRegionName =>
                this.onClickRegion(this.props.data, clickedRegionName)
              }
            />
          </CardBody>
        </WidgetCard>
      )
    }
  }
}

RegionsWidget.propTypes = {
  allowDelete: PropTypes.bool,
  allowUpdate: PropTypes.bool,
  data: PropTypes.array,
  getQueryBuilder: PropTypes.func,
  resultLayout: PropTypes.object,
  onClickSearch: PropTypes.func,
  onRemove: PropTypes.func,
  options: PropTypes.object,
  toggleStatic: PropTypes.func,
  savedSearches: ImmutablePropTypes.map.isRequired,
  sonraiSearches: ImmutablePropTypes.list,
  static: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onEdit: PropTypes.func,
  widget: PropTypes.object,
}

export default RegionsWidget
