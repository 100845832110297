import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'
import { Map } from 'immutable'
import _ from 'lodash'

import SparkWidget from 'components/SparkWidget'
import FormLabel from 'components/FormLabel'
import SelectBar from 'components/SelectBar'
import PreviewContainer from './PreviewContainer'

const styles = {
  wrapperWithPreview: {
    display: 'grid',
    gridTemplateColumns: '50% 1fr',
    gridColumnGap: '2em',
  },
  sparkPreview: { minWidth: '450px', height: '260px', maxHeight: '260px' },
}

const placeholderData = {
  x: ['01-01', '01-02', '01-03', '01-04', '01-05', '01-06', '01-07'],
  y: [43, 23, 54, 77, 99, 21, 4],
}

class SparkWidgetConfig extends React.Component {
  componentDidUpdate() {
    this.updateValidity()
  }

  updateValidity = () => {
    const hasControlFrameWork = !!this.props.widgetOptions.controlFramework
    const hasTitle = this.props.widgetTitle
    const hasTimeFrame = !!this.props.widgetOptions.sparkTimeFrame

    const valid = hasControlFrameWork && hasTitle && hasTimeFrame
    this.props.setValidity(valid)
  }

  handleSelectControlFrameWork = val => {
    this.props.setWidgetOptions({
      controlFramework: val,
    })

    this.props.widgetOptions.sparkTimeFrame &&
      this.handleSuggestedTitle(
        this.getSuggestedTitle(val, this.props.widgetOptions.sparkTimeFrame)
      )
  }

  handleSelectSparkTimeFrame = val => {
    this.props.setWidgetOptions({
      sparkTimeFrame: val,
    })

    this.props.widgetOptions.controlFramework &&
      this.handleSuggestedTitle(
        this.getSuggestedTitle(this.props.widgetOptions.controlFramework, val)
      )
  }

  setTitle = e => {
    this.props.setWidgetTitle(e.target.value)
  }

  setDescription = e => {
    this.props.setWidgetOptions({
      description: e.target.value,
    })
  }

  handleSuggestedTitle = title => this.props.setWidgetTitle(title)

  getSuggestedTitle = (framework, time) => {
    if (framework !== null && time !== null) {
      const message = `${time.label} of ${framework.label}`
      return message
    }
  }

  render() {
    const controlFrameWorkOptions = this.props.controlGroupOptions
      .filter(cf => cf.get('enabled'))
      .toList()
      .map(cf =>
        Map({
          value: cf.get('srn'),
          label: cf.get('title'),
        })
      )
      .toJS()

    const timeFrameOptions = [
      { value: 1, label: 'Last 24 Hours' },
      { value: 7, label: 'Last 7 Days' },
      {
        value: 30,
        label: 'Last 30 Days',
      },
      {
        value: 90,
        label: 'Last 90 Days',
      },
    ]

    return (
      <div style={this.props.previewWidget ? styles.wrapperWithPreview : {}}>
        {this.props.previewWidget && (
          <PreviewContainer>
            <div style={styles.sparkPreview}>
              <SparkWidget
                data={placeholderData}
                title={
                  this.props.widgetTitle ? (
                    this.props.widgetTitle
                  ) : (
                    <span style={{ fontStyle: 'italic' }}>Widget Title</span>
                  )
                }
              />
            </div>
          </PreviewContainer>
        )}
        <div>
          <FormLabel
            label="Control Framework"
            for="controlFramework"
            required={true}
          />
          <SelectBar
            isClearable
            value={_.get(this.props.widgetOptions.controlFramework, 'value')}
            options={controlFrameWorkOptions}
            onChange={this.handleSelectControlFrameWork}
          />

          <FormLabel label="Time Frame" for="timeframe" required={true} />
          <SelectBar
            autoSort={false}
            isClearable
            value={_.get(this.props.widgetOptions.sparkTimeFrame, 'value')}
            options={timeFrameOptions}
            onChange={this.handleSelectSparkTimeFrame}
          />

          <FormLabel label="Title" for="title" required={true} />
          <Input
            type="text"
            name="widgetTitle"
            id="widgetTitle"
            placeholder="Title..."
            value={this.props.widgetTitle || ''}
            onChange={this.setTitle}
          />

          <FormLabel label="Description" for="widgetDescription" />
          <Input
            id="widgetDescription"
            type="textarea"
            name="widgetDescription"
            onChange={this.setDescription}
            value={this.props.widgetOptions.description || ''}
          />
        </div>
      </div>
    )
  }
}

SparkWidgetConfig.propTypes = {
  previewWidget: PropTypes.bool,
  setWidgetOptions: PropTypes.func,
  setWidgetTitle: PropTypes.func,
  setValidity: PropTypes.func,
  widgetOptions: PropTypes.shape({
    controlFramework: PropTypes.object,
    description: PropTypes.string,
    sparkTimeFrame: PropTypes.object,
  }),

  widgetTitle: PropTypes.string,
  controlGroupOptions: PropTypes.object,
}

export default SparkWidgetConfig
