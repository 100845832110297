import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import permissionChecker from 'containers/PermissionChecker'
import {
  selectSavedSearches,
  selectSonraiSearches,
  selectPivot,
} from 'containers/SonraiData/selectors'
import { WIDGET_TYPES } from 'appConstants'
import BigStatWidget from 'components/BigStatWidget'
import TableWidget from 'components/TableWidget'
import RatioWidget from 'components/RatioWidget'
import GaugeWidget from 'components/GaugeWidget'
import RegionsWidget from 'components/RegionsWidget'
import ListWidget from 'components/ListWidget'
import PieChartWidget from 'components/PieChartWidget'
import BarChartWidget from 'components/BarChartWidget'
import ArcMapWidget from 'components/ArcMapWidget'
import AlertWidget from 'components/AlertWidget'
import SparkWidget from 'components/SparkWidget'
import ComplianceWidget from 'components/ComplianceWidget'
import TextWidget from 'components/TextWidget'
import WidgetErrorBoundry from './WidgetErrorBoundry'
import _ from 'lodash'

export class Widget extends React.Component {
  getWidgetComponent = widgetType => {
    switch (widgetType) {
      case WIDGET_TYPES.LIST:
        return ListWidget
      case WIDGET_TYPES.BIG_COUNT:
        return BigStatWidget
      case WIDGET_TYPES.TABLE:
        return TableWidget
      case WIDGET_TYPES.RATIO:
        return RatioWidget
      case WIDGET_TYPES.GAUGE:
        return GaugeWidget
      case WIDGET_TYPES.REGIONS:
        return RegionsWidget
      case WIDGET_TYPES.PIE_CHART:
        return PieChartWidget
      case WIDGET_TYPES.BAR_CHART:
        return BarChartWidget
      case WIDGET_TYPES.ADVMAP:
        return ArcMapWidget
      case WIDGET_TYPES.ALERT:
        return AlertWidget
      case WIDGET_TYPES.SPARK:
        return SparkWidget
      case WIDGET_TYPES.COMPLIANCE:
        return ComplianceWidget
      case WIDGET_TYPES.TEXT:
        return TextWidget
      default:
        return null
    }
  }

  removeWidget = () => {
    this.props.removeWidget(this.props.widget.get('srn'))
  }

  editWidget = () => {
    this.props.onEdit(this.props.widget.get('srn'))
  }

  toggleStatic = () => {
    this.props.toggleStatic(this.props.widget.get('srn'))
  }

  getWidgetResultsTitle = (searchTitle, sonraiSearchName) => {
    const { widget } = this.props
    let widgetObj = widget.toJS()

    if (
      widgetObj.resultLayout &&
      widgetObj.resultLayout.indexedSearches &&
      widgetObj.resultLayout.indexedSearches
    ) {
      const key = _.keys(widgetObj.resultLayout.indexedSearches)
      const id = widgetObj.resultLayout.indexedSearches[key]
      const search = this.props.savedSearches.get(id)
      if (search && !sonraiSearchName) {
        return search.get('name')
      }
    }

    return searchTitle || sonraiSearchName
  }

  setWidgetOptions = options => {
    this.props.setWidgetOptions(this.props.widget.get('srn'), options)
  }

  shouldComponentUpdate(nextProps) {
    let render = nextProps.canRender === false ? false : true
    return render
  }

  render() {
    const WidgetComponent = this.getWidgetComponent(
      this.props.widget.get('type')
    )

    if (!WidgetComponent) {
      return <div />
    }
    const canEdit = this.props.userHasPermission({
      permissionName: 'edit.solutioncards',
      resourceId: this.props.widget.get('resourceId'),
    })

    const canViewData = this.props.canViewData

    return (
      <WidgetErrorBoundry
        allowDelete={canEdit}
        allowUpdate={canEdit}
        onRemove={this.removeWidget}
        onEdit={this.editWidget}
        canViewData={canViewData}
        title={this.props.widget.get('title')}
      >
        <WidgetComponent
          onClickControlFramework={this.props.onClickControlFramework}
          onClickAlert={this.props.onClickAlert}
          pivot={this.props.pivot}
          savedSearches={this.props.savedSearches}
          sonraiSearches={this.props.sonraiSearches}
          title={this.props.widget.get('title')}
          subtitle={this.props.widget.get('subtitle')}
          sid={this.props.widget.get('sid')}
          selection={this.props.widget.get('selection')}
          getQueryBuilder={this.props.getQueryBuilder}
          resultLayout={this.props.widget.get('resultLayout').toJS()}
          options={this.props.widget.get('options').toJS()}
          onEdit={this.editWidget}
          onRemove={this.removeWidget}
          toggleStatic={this.toggleStatic}
          onClickSearch={this.props.onClickSearch}
          onClickNodeView={this.props.onClickNodeView}
          static={this.props.widget.get('static')}
          setWidgetOptions={this.setWidgetOptions}
          layout={this.props.layout}
          allowDelete={canEdit}
          allowUpdate={canEdit}
          widget={this.props.widget.toJS()}
        />
      </WidgetErrorBoundry>
    )
  }
}

Widget.propTypes = {
  getQueryBuilder: PropTypes.func.isRequired,
  layout: PropTypes.object.isRequired,
  onClickAlert: PropTypes.func.isRequired,
  onClickControlFramework: PropTypes.func.isRequired,
  onClickNodeView: PropTypes.func.isRequired,
  onClickSearch: PropTypes.func.isRequired,
  pivot: ImmutablePropTypes.map.isRequired,
  removeWidget: PropTypes.func.isRequired,
  savedSearches: ImmutablePropTypes.iterable.isRequired,
  onEdit: PropTypes.func.isRequired,
  setWidgetOptions: PropTypes.func.isRequired,
  sonraiSearches: ImmutablePropTypes.iterable.isRequired,
  toggleStatic: PropTypes.func.isRequired,
  userHasPermission: PropTypes.func.isRequired,
  widget: ImmutablePropTypes.map.isRequired,
  canRender: PropTypes.bool,
  canViewData: PropTypes.bool,
}
const mapStateToProps = createStructuredSelector({
  pivot: selectPivot,
  savedSearches: selectSavedSearches,
  sonraiSearches: selectSonraiSearches,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, permissionChecker)(Widget)
