/**
 *
 * ComplianceWidget
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import messages from './messages'
import { exists } from 'utils/sonraiUtils'
import Chart from 'components/Chart'
import Card, { TopTitle, CardBody } from 'components/Card'
import _ from 'lodash'
import gql from 'graphql-tag'
import WidgetCard from 'components/WidgetCard'
import { round } from 'utils/widgetUtils'
import { FormattedMessage } from 'react-intl'
import TextLink from 'components/TextLink'
import { Map } from 'immutable'
const styles = {
  header: { fontSize: '1.1rem', fontWeight: '300' },
  chartContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

class ComplianceWidgets extends React.Component {
  getData = data => {
    if (exists(data.ControlFrameworkEvalLogs)) {
      if (!_.isEmpty(data.ControlFrameworkEvalLogs.items)) {
        const percentage = data.ControlFrameworkEvalLogs.items[0].percentPass
        if (exists(percentage)) {
          return [round(percentage, 1)]
        } else {
          return []
        }
      }
    }
  }

  renderChart = value => {
    if (_.isEmpty(value)) {
      return (
        <div>
          <FormattedMessage {...messages.noData} />
        </div>
      )
    } else {
      return (
        <Chart
          type="radialBar"
          yValues={value}
          onChartReady={this.props.onChartReady}
          static={this.props.noAnimate}
        />
      )
    }
  }

  getQuery = () => {
    const { widgetOptions } = this.props.resultLayout
    if (exists(widgetOptions)) {
      if (widgetOptions.controlFramework) {
        return gql`
      query getCompliancePercentage {
        ControlFrameworkEvalLogs(
          where: {
            controlFrameworkId: "${
              this.props.resultLayout.widgetOptions.controlFramework.value
            }"
            limit: 1
          }
        ) {
          items {
            
            percentPass
          }
        }
      }
    `
      }
    }
  }

  renderHeader = id => {
    if (exists(id)) {
      return (
        <TextLink onClick={() => this.props.onClickControlFramework(id)}>
          <div style={styles.header}>{this.props.title}</div>
        </TextLink>
      )
    } else {
      return (
        <div>
          <div style={styles.header}>{this.props.title}</div>
        </div>
      )
    }
  }

  getSearchId = () => {
    //Because it doesn't support saved or  sonrai searches - just control frameworks
    return Map({
      uiSearch: null,
      advancedSearch: null,
    })
  }

  render() {
    if (this.props.data === undefined) {
      const searchId = this.getSearchId()
      const query = this.getQuery()
      if (!query) {
        return (
          <WidgetCard
            searchId={searchId}
            loading={true}
            title={this.props.title}
            allowDelete={this.props.allowDelete}
            allowUpdate={this.props.allowUpdate}
            onRemove={this.props.onRemove}
            onEdit={this.props.onEdit}
          />
        )
      }

      return (
        <Query
          query={query}
          notifyOnNetworkStatusChange
          pollInterval={300000}
          key={JSON.stringify(query)}
          context={{
            queryName: `complianceWidget${_.snakeCase(this.props.title)}`,
          }}
        >
          {({ loading, error, data, networkStatus }) => {
            const value = loading ? null : this.getData(data)
            const id = exists(this.props.resultLayout.widgetOptions)
              ? this.props.resultLayout.widgetOptions.controlFramework.value
              : undefined

            return (
              <WidgetCard
                createdBy={this.props.widget && this.props.widget.createdBy}
                searchId={searchId}
                loading={loading}
                disableToolbar={this.props.disableToolbar}
                allowDelete={this.props.allowDelete}
                allowUpdate={this.props.allowUpdate}
                onEdit={this.props.onEdit}
                error={error}
                onRemove={this.props.onRemove}
                toggleStatic={this.props.toggleStatic}
                static={this.props.static}
                networkStatus={networkStatus}
                title={
                  <TextLink
                    onClick={() => this.props.onClickControlFramework(id)}
                  >
                    {this.props.title}
                  </TextLink>
                }
                description={_.get(this.props.resultLayout, [
                  'widgetOptions',
                  'description',
                ])}
              >
                <TopTitle>{this.renderHeader(id)}</TopTitle>
                <CardBody>
                  <div style={styles.chartContainer}>
                    {this.renderChart(value)}
                  </div>
                </CardBody>
              </WidgetCard>
            )
          }}
        </Query>
      )
    } else {
      return (
        <Card>
          {this.props.title && <TopTitle>{this.renderHeader()}</TopTitle>}
          <CardBody>
            <div style={styles.chartContainer}>
              {this.renderChart(this.props.data)}
            </div>
          </CardBody>
        </Card>
      )
    }
  }
}

ComplianceWidgets.propTypes = {
  allowDelete: PropTypes.bool,
  allowUpdate: PropTypes.bool,
  data: PropTypes.object,
  disableToolbar: PropTypes.bool,
  noAnimate: PropTypes.bool,
  title: PropTypes.string,
  onChartReady: PropTypes.func,
  onClickControlFramework: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  resultLayout: PropTypes.shape({
    widgetOptions: PropTypes.shape({
      controlFramework: PropTypes.shape({
        value: PropTypes.string,
      }),
    }),
  }),
  static: PropTypes.bool,
  toggleStatic: PropTypes.bool,
  widget: PropTypes.object,
}

export default ComplianceWidgets
