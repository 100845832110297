/**
 *
 * PieChartWidget
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { Map } from 'immutable'
import TextLink from 'components/TextLink'
import Chart from 'components/Chart'
import Card, { CardBody, BottomTitle } from 'components/Card'
import WidgetCard from 'components/WidgetCard'
import {
  getFields,
  getGroupedSavedSearchQuery,
  getSearchCard,
  hasSonraiSearch,
  getSelection,
  getSonraiSearchQuery,
  getSonraiSearchData,
} from 'query-builder'
import { queryHasPivotFilter } from 'query-builder'
import { flattenData, getSearchIdForSonraiSearches } from 'utils/sonraiUtils'

class PieChartWidget extends React.Component {
  getQuery = () => {
    if (hasSonraiSearch(this.props.options, 'pie')) {
      return getSonraiSearchQuery(this.props.options.sonraiSearches.pie)
    } else {
      return getGroupedSavedSearchQuery(
        this.props.getQueryBuilder,
        this.props.savedSearches,
        this.props.resultLayout.indexedSearches.pie,
        {},
        'piechartQuery',
        this.props.resultLayout.widgetOptions.properties.value
      )
    }
  }

  onClickSearch = () => {
    this.props.onClickSearch({
      savedSearchId: this.props.resultLayout.indexedSearches.pie,
      sonraiSearchName: this.props.options.sonraiSearches.pie,
      searchTitle: this.props.title,
    })
  }

  getSearchName = () => {
    return hasSonraiSearch(this.props.options, 'pie')
      ? this.props.options.sonraiSearches.pie
      : this.props.savedSearches.getIn([
          this.props.resultLayout.indexedSearches.pie,
          'name',
        ])
  }

  onClickPoint = (params, data) => {
    const clickedLabel = params.labels[params.index]
    const sliceRepresentsNull = clickedLabel === 'null' || clickedLabel === '-'
    const targetSliceValue = sliceRepresentsNull ? null : clickedLabel
    const property = this.props.resultLayout.widgetOptions.properties.value

    let filter

    if (hasSonraiSearch(this.props.options, 'pie')) {
      filter = {
        label: data.property,
        value: targetSliceValue,
      }
    } else {
      filter = {
        label: property,
        value: targetSliceValue,
      }
    }

    this.props.onClickSearch({
      savedSearchId: this.props.resultLayout.indexedSearches.pie,
      sonraiSearchName: this.props.options.sonraiSearches.pie,
      searchTitle: this.props.title,
      savedSearchFilterCardId: getSearchCard(this.props.resultLayout, 'pie').id,
      filter: filter,
    })
  }

  getData = data => {
    if (hasSonraiSearch(this.props.options, 'pie')) {
      const items = getSonraiSearchData(data).items
      const flattened = flattenData(items)
      const property = this.props.resultLayout.widgetOptions.properties.value
      const counts = {}
      flattened.forEach(
        item => (counts[item[property]] = 1 + (counts[item[property]] || 0))
      )

      const countKeys = _.orderBy(
        Object.keys(counts),
        key => counts[key],
        'desc'
      )
      return {
        data: { flattened, property, counts },
        labels: countKeys,
        slices: countKeys.map(key => counts[key]),
      }
    } else {
      const fields = getFields(
        this.props.savedSearches,
        this.props.resultLayout.indexedSearches.pie
      ).toJS()
      const searchCard = getSearchCard(this.props.resultLayout, 'pie')
      const selection = getSelection(fields, searchCard, 'group')
      const savedSearchResults = _.get(data, selection) || []

      const labels = []
      const slices = []

      !_.isEmpty(this.props.resultLayout.widgetOptions.properties.value) &&
        _.orderBy(savedSearchResults, item => item.count, 'desc').map(item => {
          const label =
            item.key[this.props.resultLayout.widgetOptions.properties.value]
          labels.push(label === null ? 'null' : label)
          slices.push(item.count)
        })

      return {
        data,
        labels,
        slices,
      }
    }
  }

  getSearchId = () => {
    const { options, sonraiSearches, resultLayout } = this.props
    let searchObj = Map({ uiSearch: null, advancedSearch: null })
    if (hasSonraiSearch(options, 'pie')) {
      const searches = getSearchIdForSonraiSearches(options, sonraiSearches)
      searchObj = searchObj.set('advancedSearch', searches)
    } else {
      searchObj = searchObj.set('uiSearch', resultLayout.indexedSearches.pie)
    }
    return searchObj
  }

  render() {
    if (this.props.data === undefined) {
      const searchId = this.getSearchId()
      const queryConfig = this.getQuery()
      if (!queryConfig.gqlStatement) {
        return (
          <WidgetCard
            searchId={searchId}
            loading={true}
            title={this.props.title}
            allowDelete={this.props.allowDelete}
            allowUpdate={this.props.allowUpdate}
            onRemove={this.props.onRemove}
            onEdit={this.props.onEdit}
          />
        )
      }

      const filtered = queryHasPivotFilter(queryConfig.gqlStatement)
      return (
        <Query
          query={gql`
            ${queryConfig.gqlStatement}
          `}
          notifyOnNetworkStatusChange
          pollInterval={300000}
          variables={queryConfig.variables}
          context={{
            queryName: `pieChartWidget_${_.snakeCase(this.props.title)}`,
          }}
        >
          {({ error, data, refetch, networkStatus }) => {
            const results = this.getData(data)
            return (
              <WidgetCard
                searchId={searchId}
                createdBy={this.props.widget && this.props.widget.createdBy}
                disableToolbar={this.props.disableToolbar}
                allowDelete={this.props.allowDelete}
                allowUpdate={this.props.allowUpdate}
                onRemove={this.props.onRemove}
                onEdit={this.props.onEdit}
                toggleStatic={this.props.toggleStatic}
                static={this.props.static}
                refetch={refetch}
                networkStatus={networkStatus}
                title={
                  <TextLink
                    title={this.getSearchName()}
                    onClick={this.onClickSearch}
                  >
                    {this.props.title}
                  </TextLink>
                }
                filtered={filtered}
                error={error}
                containerStyle={{ padding: 0 }}
                description={_.get(this.props.resultLayout, [
                  'widgetOptions',
                  'description',
                ])}
              >
                {networkStatus !== 1 && !error && (
                  <CardBody style={{ overflow: 'visible', paddingTop: '1em' }}>
                    <Chart
                      chartWrapperStyle={{ overflow: 'visible' }}
                      onClickPoint={params =>
                        this.onClickPoint(params, results.data)
                      }
                      colors={results.colors}
                      yValues={results.slices}
                      yLabels={results.labels}
                      type="donut"
                    />
                  </CardBody>
                )}
                <BottomTitle style={{ padding: '0.5em 1em' }}>
                  <TextLink
                    title={this.getSearchName()}
                    onClick={this.onClickSearch}
                  >
                    {this.props.title}
                  </TextLink>
                </BottomTitle>
              </WidgetCard>
            )
          }}
        </Query>
      )
    } else {
      return (
        <Card containerStyle={{ padding: '1em 0 0 0' }}>
          <CardBody>
            <Chart
              colors={this.props.data.colors}
              yValues={this.props.data.slices}
              yLabels={this.props.data.labels}
              type="donut"
              onChartReady={this.props.onChartReady}
              static={this.props.noAnimate}
            />
          </CardBody>

          <BottomTitle style={{ padding: '0.5em 1em' }}>
            <TextLink>{this.props.title}</TextLink>
          </BottomTitle>
        </Card>
      )
    }
  }
}

PieChartWidget.propTypes = {
  allowDelete: PropTypes.bool,
  allowUpdate: PropTypes.bool,
  data: PropTypes.shape({
    colors: PropTypes.arrayOf(PropTypes.string),
    slices: PropTypes.array,
    labels: PropTypes.array,
  }),
  disableToolbar: PropTypes.bool,
  noAnimate: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onClickSearch: PropTypes.func,
  onRemove: PropTypes.func,
  toggleStatic: PropTypes.func,
  savedSearches: ImmutablePropTypes.map.isRequired,
  static: PropTypes.bool,
  options: PropTypes.object,
  onChartReady: PropTypes.func,
  resultLayout: PropTypes.object,
  getQueryBuilder: PropTypes.func,
  onEdit: PropTypes.func,
  sonraiSearches: ImmutablePropTypes.iterable,
  widget: PropTypes.object,
}

export default PieChartWidget
