import React from 'react'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async-creatable'

export class TypeaheadFilter extends React.Component {
  componentDidUpdate() {
    if (this.optionsLoaded() && this.callback) {
      this.callback(this.props.options)
      this.callback = null
    }
  }

  onChange = value => {
    if (!value || value.length === 0) {
      this.props.onChange(null)
      return
    }

    this.props.onChange({
      value: value.map(valObj => valObj.value),
    })
  }

  onCreateOption = value => {
    this.props.onChange({
      value: value,
    })

    this.props.addNew(value)
  }

  getSelectedValues = () => {
    if (!this.props.value || this.props.value.length === 0) {
      return null
    }

    return this.props.value.map(val => ({
      label: val,
      value: val,
    }))
  }

  optionsLoaded = () => {
    return this.props.options && this.props.options.length > 0
  }

  loadOptions = (inputValue, callback) => {
    if (this.props.loadOptions) {
      if (!this.optionsLoaded()) {
        this.props.loadOptions()
        this.callback = callback
        return
      }
    }

    callback(this.getFilteredOptions(inputValue))
  }

  getFilteredOptions = inputValue => {
    const { options } = this.props
    if (inputValue) {
      return options.filter(option =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      )
    }
    return options
  }

  formatCreateLabel = inputValue => {
    return `"${inputValue}"`
  }

  render() {
    return (
      <AsyncSelect
        name={this.props.name}
        value={this.getSelectedValues()}
        onChange={this.onChange}
        isClearable
        loadOptions={this.loadOptions}
        defaultOptions
        isCreatable
        isMulti
        formatCreateLabel={this.formatCreateLabel}
      />
    )
  }
}

TypeaheadFilter.defaultProps = {
  style: {},
}

TypeaheadFilter.propTypes = {
  addNew: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ),
  loadOptions: PropTypes.func,
}

export default TypeaheadFilter
