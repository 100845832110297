import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { isImmutable } from 'immutable'
import { FormattedMessage, injectIntl } from 'react-intl'

import SelectBar from 'components/SelectBar'
import DateRangePicker from 'components/DateRangePicker'
import { CLOUD_TYPES } from 'appConstants'
import IHelp from 'containers/IHelp'
import AccountSelector from 'components/AccountSelector'
import injectReducer from 'utils/injectReducer'
import { selectPivot } from 'containers/SonraiData/selectors'
import { exists } from 'utils/sonraiUtils'
import {
  setPivot,
  fetchTags,
  fetchDataContainers,
  fetchAccounts,
  fetchSubscriptions,
} from 'containers/SonraiData/actions'
import FilterPanel, { Filter, TypeaheadFilter } from 'components/FilterPanel'
import { PIVOT_FILEDS } from 'query-builder'
import {
  selectAccounts,
  selectDataContainers,
  selectTags,
  selectSubscriptions,
} from 'containers/SonraiData/selectors'
import _ from 'lodash'
import reducer from './reducer'
import messages from './messages'
import { setSelectedPivot, clearSelectedPivots, addTagFilter } from './actions'
import { selectSelectedPivots, selectTagFilters } from './selectors'

export class SolutionCenterFilterPanel extends React.Component {
  onChangeAccountFilter = selectedAccounts => {
    this.props.setSelectedPivot({
      type: PIVOT_FILEDS.ACCOUNTS,
      value:
        Array.isArray(selectedAccounts) && selectedAccounts.length > 0
          ? selectedAccounts
          : null,
    })
  }

  onChangeSpecificDates = (newDates = {}) => {
    this.props.setSelectedPivot({
      type: PIVOT_FILEDS.SPECIFIC_DATES,
      value: newDates.startDate && newDates.endDate ? newDates : null,
    })
  }

  onChangePivotFilter = (type, selectedOption) => {
    if (Array.isArray(selectedOption)) {
      this.props.setSelectedPivot({
        type,
        value:
          selectedOption.length === 0
            ? null
            : selectedOption.map(option => option.value),
      })
    } else {
      this.props.setSelectedPivot({
        type,
        value: _.isEmpty(selectedOption) ? null : selectedOption.value,
      })
    }
  }

  onChangeSpecificDate = newDates => {
    this.props.setSelectedPivot({
      type: PIVOT_FILEDS.SPECIFIC_DATES,
      value: newDates,
    })
  }

  hasChanges = () => {
    const nonEmptyPivots = this.props.selectedPivots.filter(pivot => {
      if (isImmutable(pivot)) {
        return !pivot.isEmpty()
      } else {
        return exists(pivot)
      }
    })

    return !nonEmptyPivots.isEmpty()
  }

  applyFilters = () => {
    this.props.selectedPivots.forEach((value, key) => {
      this.props.setPivot({
        type: key,
        value,
      })
    })

    this.props.clearSelectedPivots()
  }

  getSelectedValue = type => {
    const pivotValue = this.props.selectedPivots.has(type)
      ? this.props.selectedPivots.get(type)
      : this.props.pivot.get(type)

    return isImmutable(pivotValue) ? pivotValue.toJS() : pivotValue
  }

  getTagOptions = () => {
    const serverTags = this.props.tags
      .filter(tag => !!tag.get('key'))
      .sortBy(tag => tag.get('key').toLowerCase())
      .toJS()
      .map(tag => ({ value: tag.key, label: tag.key }))

    const additionalOptions = this.props.tagFilters
      .toJS()
      .map(tagStr => ({ value: tagStr, label: tagStr }))

    return serverTags.concat(additionalOptions)
  }

  render() {
    if (!this.props.isOpen) {
      return null
    }

    return (
      <FilterPanel
        title={this.props.intl.formatMessage(messages.filtersTitle)}
        toggle={this.props.toggle}
        hasChanges={this.hasChanges()}
        applyFilters={this.applyFilters}
      >
        <Filter
          selectedValue={this.getSelectedValue(PIVOT_FILEDS.RELATIVE_DATE)}
          label="Relative Date Range"
        >
          <SelectBar
            autoSort={false}
            isClearable
            onChange={this.onChangePivotFilter.bind(
              null,
              PIVOT_FILEDS.RELATIVE_DATE
            )}
            value={this.getSelectedValue(PIVOT_FILEDS.RELATIVE_DATE)}
            options={[
              {
                value: 1,
                label: this.props.intl.formatMessage(messages.days1),
              },
              {
                value: 7,
                label: this.props.intl.formatMessage(messages.days7),
              },
              {
                value: 30,
                label: this.props.intl.formatMessage(messages.days30),
              },
              {
                value: 90,
                label: this.props.intl.formatMessage(messages.days90),
              },
            ]}
          />
        </Filter>

        <Filter
          selectedValue={this.getSelectedValue(PIVOT_FILEDS.SPECIFIC_DATES)}
          label="Specific Date Range"
        >
          <DateRangePicker
            onChange={this.onChangeSpecificDates}
            startDate={
              (this.getSelectedValue(PIVOT_FILEDS.SPECIFIC_DATES) || {})
                .startDate
            }
            endDate={
              (this.getSelectedValue(PIVOT_FILEDS.SPECIFIC_DATES) || {}).endDate
            }
          />
        </Filter>

        <Filter
          selectedValue={this.getSelectedValue(PIVOT_FILEDS.ACCOUNTS)}
          label={this.props.intl.formatMessage(messages.accountFilterTitle)}
        >
          <AccountSelector
            accounts={this.props.accounts}
            value={
              !_.isEmpty(this.getSelectedValue(PIVOT_FILEDS.ACCOUNTS))
                ? this.getSelectedValue(PIVOT_FILEDS.ACCOUNTS).map(value => ({
                    label: value,
                    value,
                  }))
                : []
            }
            onChange={this.onChangeAccountFilter}
            isMulti
          />
        </Filter>

        <Filter
          selectedValue={this.getSelectedValue(PIVOT_FILEDS.HAS_TAGS)}
          label={
            <span>
              <FormattedMessage {...messages.tagFilterTitle} />
              &nbsp;
              <IHelp help helpKey="filterPanelTagSet" />
            </span>
          }
        >
          <TypeaheadFilter
            onChange={this.onChangePivotFilter.bind(
              null,
              PIVOT_FILEDS.HAS_TAGS
            )}
            value={this.getSelectedValue(PIVOT_FILEDS.HAS_TAGS)}
            options={this.getTagOptions()}
            loadOptions={this.props.fetchTags}
            addNew={this.props.addTagFilter}
          />
        </Filter>

        <Filter
          selectedValue={this.getSelectedValue(PIVOT_FILEDS.CLOUD)}
          label="Cloud Type"
        >
          <SelectBar
            isMulti
            onChange={this.onChangePivotFilter.bind(null, PIVOT_FILEDS.CLOUD)}
            value={this.getSelectedValue(PIVOT_FILEDS.CLOUD)}
            label="Cloud Type"
            options={[
              {
                value: CLOUD_TYPES.AWS,
                label: 'AWS',
              },
              {
                value: CLOUD_TYPES.GCP,
                label: 'GCP',
              },
              {
                value: CLOUD_TYPES.AZURE,
                label: 'Azure',
              },
              {
                value: CLOUD_TYPES.HASHICORP,
                label: 'Hashicorp',
              },
              {
                value: CLOUD_TYPES.KUBERNETES,
                label: 'Kubernetes',
              },
            ]}
          />
        </Filter>
      </FilterPanel>
    )
  }
}

SolutionCenterFilterPanel.propTypes = {
  addTagFilter: PropTypes.func.isRequired,
  accounts: ImmutablePropTypes.list.isRequired,
  clearSelectedPivots: PropTypes.func.isRequired,
  dataContainers: ImmutablePropTypes.list.isRequired,
  fetchDataContainers: PropTypes.func.isRequired,
  fetchTags: PropTypes.func.isRequired,
  fetchAccounts: PropTypes.func.isRequired,
  subscriptions: ImmutablePropTypes.list.isRequired,
  fetchSubscriptions: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool,
  pivot: ImmutablePropTypes.map.isRequired,
  selectedPivots: ImmutablePropTypes.map.isRequired,
  setPivot: PropTypes.func.isRequired,
  setSelectedPivot: PropTypes.func,
  tags: ImmutablePropTypes.listOf(
    ImmutablePropTypes.contains({
      key: PropTypes.string,
    })
  ).isRequired,
  tagFilters: ImmutablePropTypes.listOf(PropTypes.string).isRequired,
  toggle: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  subscriptions: selectSubscriptions,
  accounts: selectAccounts,
  dataContainers: selectDataContainers,
  pivot: selectPivot,
  selectedPivots: selectSelectedPivots,
  tags: selectTags,
  tagFilters: selectTagFilters,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addTagFilter,
      clearSelectedPivots,
      setPivot,
      fetchDataContainers,
      fetchTags,
      fetchAccounts,
      fetchSubscriptions,
      setSelectedPivot,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: 'solutionCenterFilterPanel', reducer })

export default compose(
  withConnect,
  injectIntl,
  withReducer
)(SolutionCenterFilterPanel)
