export const FETCH_SOLUTION_CARDS_QUERY = `
  query getSCCards {
    SolutionCards {
      count
      items {
        __typename
        name
        createdDate
        sid
        srn
        lastModified
        resourceId
        createdBy
        contains {
          items {
            resourceId
            title
            subtitle
            name
            resultLayout
            static
            type
            widgetLocation
            createdDate
            createdBy
            sid
            srn
            lastModified
            widgetSize
            selection
            options
            contains {
              items {
                name
                sid
                srn
                ... on Search {
                  rootQueryName
                  lastModified
                  createdDate
                  query
                  resourceId
                }
              }
            }
          }
        }
      }
    }
  }
`
