import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { DAEMON } from 'utils/constants'
import LoadingAnim from 'components/LoadingAnim'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { selectQueryTypes } from 'containers/SonraiData/selectors'
import SolutionCenterCards from 'containers/SolutionCenterCards'
import { selectSelectedCard } from 'containers/SolutionCenterCards/selectors'
import SolutionCenterWidgets from 'containers/SolutionCenterWidgets'
import SolutionCenterFilterPanel from 'containers/SolutionCenterFilterPanel'
import SolutionCenterCardActions from 'containers/SolutionCenterCardActions'
import WithPermission from 'containers/PermissionChecker/WithPermission'
import { selectCards } from './selectors'
import reducer from './reducer'
import { startUpdateScPoll, stopUpdateScPoll } from './actions'
import sagas from './sagas'
import SolutionCenterToolbar from './SolutionCenterToolbar'
import SolutionCenterErrorBoundry from './SolutionCenterErrorBoundry'
import SolutionCenterFilterStatus from './SolutionCenterFilterStatus'
export class SolutionCenter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      error: false,
      showFiltersPanel: false,
    }

    this.styles = {
      wrapper: {
        height: '100%',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gridTemplateAreas: '"toolbar" "content"',
        position: 'relative',
      },
      toolbar: {
        gridArea: 'toolbar',
      },
      content: {
        gridArea: 'content',
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr',
        gridTemplateAreas: '"scToolbar" "widgets"',
        overflow: 'hidden',
      },

      scToolbar: {
        gridArea: 'scToolbar',
        display: 'grid',
        gridTemplateColumns: 'auto 1fr auto',
        gridTemplateAreas: '"add filterstatus cardActions"',
      },
      filterStatus: {
        gridArea: 'filterstatus',
      },
      cardActions: {
        gridArea: 'cardActions',
      },
      widgets: {
        gridArea: 'widgets',
        overflow: 'auto',
        position: 'relative',
        zIndex: 0,
      },
    }
  }

  componentDidMount() {
    this.props.startUpdateScPoll()
  }

  componentDidCatch(error) {
    this.setState({ error })
  }

  componentWillUnmount() {
    this.props.stopUpdateScPoll()
  }

  toggleFiltersPanel = () => {
    this.setState(currentState => ({
      showFiltersPanel: !currentState.showFiltersPanel,
    }))
  }

  render() {
    if (this.state.error) {
      return <LoadingAnim error={this.state.error} />
    }
    const dataLoaded =
      !this.props.solutionCards.isEmpty() && !this.props.queryTypes.isEmpty()

    if (!dataLoaded) {
      return <LoadingAnim />
    }

    return (
      <SolutionCenterErrorBoundry>
        <div style={this.styles.wrapper}>
          <div style={this.styles.content}>
            {!this.props.selectedCard.isEmpty() && (
              <div style={this.styles.widgets}>
                <SolutionCenterWidgets card={this.props.selectedCard} />
              </div>
            )}
            <div style={this.styles.scToolbar}>
              <WithPermission
                permissionName="edit.solutioncards"
                resourceId={this.props.selectedCard.get('resourceId')}
              >
                <SolutionCenterToolbar card={this.props.selectedCard} />
              </WithPermission>
              <div style={this.styles.filterStatus}>
                <SolutionCenterFilterStatus />
              </div>
              <div style={this.styles.cardActions}>
                <SolutionCenterCardActions
                  toggleFiltersPanel={this.toggleFiltersPanel}
                  showFiltersPanel={this.state.showFiltersPanel}
                />
              </div>
            </div>
          </div>

          <div style={this.styles.toolbar}>
            <SolutionCenterCards />
          </div>
          <SolutionCenterFilterPanel
            isOpen={this.state.showFiltersPanel}
            toggle={this.toggleFiltersPanel}
          />
        </div>
      </SolutionCenterErrorBoundry>
    )
  }
}

SolutionCenter.propTypes = {
  queryTypes: ImmutablePropTypes.iterable.isRequired,
  selectedCard: ImmutablePropTypes.map.isRequired,
  solutionCards: ImmutablePropTypes.iterable.isRequired,
  startUpdateScPoll: PropTypes.func.isRequired,
  stopUpdateScPoll: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  selectedCard: selectSelectedCard,
  solutionCards: selectCards,
  queryTypes: selectQueryTypes,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      startUpdateScPoll,
      stopUpdateScPoll,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: 'solutionCenter', reducer })
const withSaga = injectSaga({
  key: 'solutionCenter',
  saga: sagas,
  mode: DAEMON,
})

export default compose(withReducer, withConnect, withSaga)(SolutionCenter)
