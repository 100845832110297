import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { Dropdown, DropdownToggle } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import CreatedByBadge from 'components/CreatedByBadge'
import permissionChecker from 'containers/PermissionChecker'
import { hideSolutionCard } from 'containers/UserProfileData/actions'
import Icon from 'components/Icon'
import BorderlessButton from 'components/BorderlessButton'
import { selectUserProfile } from 'containers/UserProfileData/selectors'
import DropdownMenu from 'components/StyledReactstrapDropdownMenu'

import messages from './messages'
import { selectSelectedCard } from 'containers/SolutionCenterCards/selectors'

const styles = {
  moreButton: {
    border: 'none',
    display: 'inline',
    padding: '0.3em',
  },
  link: {
    display: 'block',
    width: '100%',
    marginBottom: '0.5em',
    paddingTop: '0.25em',
    paddingBottom: '0.25em',
    paddingLeft: '1em',
    paddingRight: '1em',
    cursor: 'pointer',
    ':hover': { backgroundColor: 'rgba(116, 180, 223, 0.4)' },
    textAlign: 'left',
    border: 'none',
    boxShadow: 'none',
  },
}

export class CardActionsMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownExpanded: false,
    }
  }

  toggleActionMenu = () => {
    this.setState(oldState => {
      return {
        dropdownExpanded: !oldState.dropdownExpanded,
      }
    })
  }

  doCardAction = action => {
    action(this.props.card.get('srn'))
    this.toggleActionMenu()
  }

  getActions = () => {
    const canEdit = this.props.userHasPermission({
      permissionName: 'edit.solutioncards',
      resourceId: this.props.card.get('resourceId'),
    })

    const canCreate = this.props.userHasPermission({
      permissionName: 'edit.solutioncards',
      resourceId: ({ org }) => `/org/${org}/`,
    })

    const actions = [
      <BorderlessButton
        key="hideCard"
        onClick={() => this.doCardAction(this.props.hideSolutionCard)}
        style={styles.link}
      >
        <FormattedMessage {...messages.hideCard} />
      </BorderlessButton>,
    ]

    if (canEdit) {
      actions.push(
        <BorderlessButton
          key="renameCard"
          onClick={() => this.doCardAction(this.props.renameSolutionCard)}
          style={styles.link}
        >
          <FormattedMessage {...messages.renameCard} />
        </BorderlessButton>
      )
    }

    if (canEdit) {
      actions.push(
        <BorderlessButton
          key="deleteCard"
          onClick={() => this.doCardAction(this.props.deleteSolutionCard)}
          style={styles.link}
        >
          <FormattedMessage {...messages.deleteCard} />
        </BorderlessButton>
      )
    }

    if (canCreate) {
      actions.push(
        <BorderlessButton
          key="copyCard"
          onClick={() => this.doCardAction(this.props.copySolutionCard)}
          style={styles.link}
        >
          <FormattedMessage {...messages.copyCard} />
        </BorderlessButton>
      )
    }

    const hasHiddenCards = !this.props.userProfile
      .get('hiddenSolutionCards', List())
      .isEmpty()

    actions.push(
      <BorderlessButton
        key="hiddenCards"
        onClick={() => this.doCardAction(this.props.toggleHiddenSCManagerModal)}
        style={styles.link}
        disabled={!hasHiddenCards}
      >
        <FormattedMessage {...messages.manageHiddenCards} />
      </BorderlessButton>
    )

    actions.push(
      <BorderlessButton
        disabled
        key="createdBy"
        style={{
          ...styles.link,
          border: 'none',
          boxShadow: 'none',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span style={{ margin: '0rem 0.5rem 0rem 0rem' }}>Created By: </span>
        <CreatedByBadge table createdBy={this.props.card.get('createdBy')} />
      </BorderlessButton>
    )

    return actions
  }

  render() {
    return (
      <Dropdown
        isOpen={this.state.dropdownExpanded}
        toggle={this.toggleActionMenu}
        className="rotate-in-2-fwd-delayed"
      >
        <DropdownToggle style={styles.moreButton}>
          <Icon fa name="config" />
        </DropdownToggle>
        <DropdownMenu style={{ borderRadius: '2px' }}>
          {this.getActions()}
        </DropdownMenu>
      </Dropdown>
    )
  }
}

CardActionsMenu.propTypes = {
  card: ImmutablePropTypes.map.isRequired,
  deleteSolutionCard: PropTypes.func.isRequired,
  copySolutionCard: PropTypes.func.isRequired,
  hideSolutionCard: PropTypes.func.isRequired,
  userHasPermission: PropTypes.func.isRequired,
  renameSolutionCard: PropTypes.func.isRequired,
  toggleHiddenSCManagerModal: PropTypes.func,
  userProfile: ImmutablePropTypes.map.isRequired,
}

const mapStateToProps = createStructuredSelector({
  card: selectSelectedCard,
  userProfile: selectUserProfile,
})
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideSolutionCard,
    },
    dispatch
  )
}
const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, permissionChecker)(CardActionsMenu)
