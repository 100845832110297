import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'
import { Map } from 'immutable'

import { ARC_MAP_SEARCH_NAME, SUPPORT_EMAIL } from 'appConstants'
import CombinedSearches from 'components/CombinedSearches'
import FormLabel from 'components/FormLabel'

export class AdvMapWidgetConfig extends React.Component {
  componentDidUpdate() {
    this.updateValidity()
  }

  updateValidity = () => {
    const hasSonraiSearch = !!this.props.widgetSonraiSearches.advmapsearch
    const hasSavedSearch = !!this.props.widgetSavedSearches.advmapsearch

    const hasTitle = this.props.widgetTitle

    const valid = (hasSonraiSearch || hasSavedSearch) && hasTitle
    this.props.setValidity(valid)
  }

  setTitle = e => {
    this.props.setWidgetTitle(e.target.value)
  }

  setDescription = e => {
    const string = e.target.value

    this.props.setWidgetOptions({
      description: string,
    })
  }

  setSonraiSearch = selectedValue => {
    this.props.setWidgetSonraiSearch(
      selectedValue ? selectedValue.value : undefined,
      'advmapsearch'
    )
  }

  selectSavedSearch = selectedOption => {
    this.props.setWidgetSavedSearch(
      selectedOption ? selectedOption.value : null,
      'advmapsearch'
    )
  }

  selectSearchField = selectedOption => {
    this.props.setWidgetSearchField(
      selectedOption ? selectedOption.value : null,
      'advmapsearch'
    )
  }

  render() {
    const sonraiSearchName = this.props.widgetSonraiSearches['advmapsearch']
    const savedSearchId = this.props.widgetSavedSearches['advmapsearch']

    const validSavedSearches = this.props.savedSearches.filter(search => {
      const fields = search.getIn(['query', 'fields'], Map())
      const actions = fields.filter(field => {
        return field.getIn(['definition', 'name'], '') === 'Actions'
      })

      const locations = fields.filter(field => {
        return (
          field.getIn(['definition', 'name'], '') === 'performedAt' &&
          actions.has(field.get('parentId'))
        )
      })

      return !actions.isEmpty() && !locations.isEmpty()
    })

    return (
      <div>
        <FormLabel label="Title" for="widgetTitle" required={true} />
        <Input
          type="text"
          name="widgetTitle"
          id="widgetTitle"
          placeholder="Number of Identities"
          value={this.props.widgetTitle || ''}
          onChange={this.setTitle}
        />

        <FormLabel label="Description" for="widgetDescription" />
        <Input
          id="widgetDescription"
          type="textarea"
          name="widgetDescription"
          onChange={this.setDescription}
          value={this.props.widgetOptions.description || ''}
        />

        <FormLabel label="Search" for="widgetSearch" required={true} />
        <p style={{ fontSize: '0.9em', color: '#777' }}>
          Note: The search must contain Actions attached to Locations. For help
          crafting these searches, please contact{' '}
          <a href={`mailto: ${SUPPORT_EMAIL}`}>support</a>
        </p>
        <CombinedSearches
          name="widgetSearch"
          savedSonraiSearches={this.props.savedSonraiSearches.filter(search =>
            search.get('name').includes(ARC_MAP_SEARCH_NAME)
          )}
          setSonraiSearch={this.setSonraiSearch}
          selectedSonraiValue={sonraiSearchName}
          savedSearches={validSavedSearches}
          selectedSearchId={savedSearchId}
          selectSavedSearch={this.selectSavedSearch}
          savedSearchDisabled={!!sonraiSearchName}
        />
      </div>
    )
  }
}

AdvMapWidgetConfig.propTypes = {
  savedSearches: ImmutablePropTypes.iterable.isRequired,
  savedSonraiSearches: ImmutablePropTypes.list.isRequired,
  setWidgetSavedSearch: PropTypes.func.isRequired,
  setWidgetSearchField: PropTypes.func.isRequired,
  setWidgetSonraiSearch: PropTypes.func,
  setWidgetOptions: PropTypes.func,
  setWidgetTitle: PropTypes.func,
  setValidity: PropTypes.func,
  widgetOptions: PropTypes.shape({
    items: PropTypes.array,
    properties: PropTypes.array,
    description: PropTypes.string,
  }).isRequired,
  widgetSavedSearches: PropTypes.object,
  widgetSonraiSearches: PropTypes.object,
  widgetTitle: PropTypes.string,
}

export default AdvMapWidgetConfig
