import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class WidgetModalErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      errorMessage: '',
      errorSource: '',
      componentStack: '',
      errorStack: '',
    }

    this.styles = {
      errorWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '20vh',
        color: '#666666',
      },
    }
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      errorMessage: error.message,
      errorStack: error.stack,
      componentStack: info.componentStack,
      errorSource: error.source ? error.source.body : '',
    })
  }

  logError = () => {
    // eslint-disable-next-line no-console
    console.log({ json: JSON.stringify(this.state), ...this.state })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <div style={this.styles.errorWrapper}>
            <p style={{ textAlign: 'center' }}>
              Oops... An error occured while editing this widget
            </p>
          </div>
          {localStorage.getItem('sonraiDebugMode') && (
            <span style={{ position: 'relative' }}>
              <small>
                <button onClick={this.logError}>click to log</button>
              </small>
              <p>
                <em>{this.state.errorMessage}</em>
              </p>
              <small style={{ paddingTop: '3px' }}>
                <pre>{this.state.errorSource}</pre>
                <pre>{this.state.errorStack}</pre>
                <pre>{this.state.componentStack}</pre>
              </small>
            </span>
          )}
        </div>
      )
    }

    return this.props.children
  }
}
WidgetModalErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}
