import React, { Component } from 'react'
import PropTypes from 'prop-types'
import WidgetCard from 'components/WidgetCard'
import MarkdownDisplay from 'components/MarkdownDisplay'

class TextWidget extends Component {
  styles = {
    title: {
      fontWeight: '400',
      fontSize: '1.15rem',
      overflow: 'hidden',
      marginBottom: '0.5rem',
    },
    description: {
      overflow: 'hidden',
    },
  }

  render() {
    const {
      title,
      resultLayout: {
        widgetOptions: { description },
      },
    } = this.props
    return (
      <WidgetCard
        title={title}
        allowDelete={this.props.allowDelete}
        allowUpdate={this.props.allowUpdate}
        onRemove={this.props.onRemove}
        onEdit={this.props.onEdit}
        static={this.props.static}
        createdBy={this.props.widget && this.props.widget.createdBy}
      >
        <div style={description !== '' ? this.styles.title : {}}> {title} </div>
        <div style={{ overflow: 'auto' }}>
          <MarkdownDisplay content={description} />
        </div>
      </WidgetCard>
    )
  }
}

TextWidget.propTypes = {
  resultLayout: PropTypes.shape({
    widgetOptions: PropTypes.shape({
      description: PropTypes.string,
    }),
  }),
  title: PropTypes.string,
  allowDelete: PropTypes.bool,
  allowUpdate: PropTypes.bool,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  static: PropTypes.bool,
  widget: PropTypes.object,
}

export default TextWidget
