/**
 *
 * BarChartWidget
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import TextLink from 'components/TextLink'
import Chart from 'components/Chart'
import Card, { CardBody, BottomTitle } from 'components/Card'
import WidgetCard from 'components/WidgetCard'
import { Map } from 'immutable'
import {
  getFields,
  getGroupedSavedSearchQuery,
  getSearchCard,
  hasSonraiSearch,
  getSelection,
  getSonraiSearchQuery,
  getSonraiSearchData,
} from 'query-builder'
import { queryHasPivotFilter } from 'query-builder'
import { flattenData, getSearchIdForSonraiSearches } from 'utils/sonraiUtils'

class BarChartWidget extends React.Component {
  getQuery = () => {
    if (hasSonraiSearch(this.props.options, 'bar')) {
      return getSonraiSearchQuery(this.props.options.sonraiSearches.bar)
    } else {
      return getGroupedSavedSearchQuery(
        this.props.getQueryBuilder,
        this.props.savedSearches,
        this.props.resultLayout.indexedSearches.bar,
        {},
        'barchartQuery',
        this.props.resultLayout.widgetOptions.properties.value
      )
    }
  }

  onClickSearch = () => {
    this.props.onClickSearch({
      savedSearchId: this.props.resultLayout.indexedSearches.bar,
      sonraiSearchName: this.props.options.sonraiSearches.bar,
      searchTitle: this.props.title,
    })
  }

  getSearchName = () => {
    return hasSonraiSearch(this.props.options, 'bar')
      ? this.props.options.sonraiSearches.bar
      : this.props.savedSearches.getIn([
          this.props.resultLayout.indexedSearches.bar,
          'name',
        ])
  }

  onClickPoint = params => {
    const clickedLabel = params.labels[params.index]
    const sliceRepresentsNull = clickedLabel === 'null' || clickedLabel === '-'
    const targetSliceValue = sliceRepresentsNull ? null : clickedLabel
    const property = this.props.resultLayout.widgetOptions.properties.value

    const filter = {
      label: property,
      value: targetSliceValue,
    }

    this.props.onClickSearch({
      savedSearchId: this.props.resultLayout.indexedSearches.bar,
      sonraiSearchName: this.props.options.sonraiSearches.bar,
      searchTitle: this.props.title,
      savedSearchFilterCardId: getSearchCard(this.props.resultLayout, 'bar').id,
      filter: filter,
    })
  }

  getData = data => {
    if (hasSonraiSearch(this.props.options, 'bar')) {
      const items = getSonraiSearchData(data).items
      const flattened = flattenData(items)

      const property = this.props.resultLayout.widgetOptions.properties.value
      const counts = {}
      flattened.forEach(
        item => (counts[item[property]] = 1 + (counts[item[property]] || 0))
      )

      const countKeys = Object.keys(counts)
      return {
        x: countKeys,
        y: countKeys.map(key => counts[key]),
      }
    } else {
      const fields = getFields(
        this.props.savedSearches,
        this.props.resultLayout.indexedSearches.bar
      ).toJS()
      const searchCard = getSearchCard(this.props.resultLayout, 'bar')
      const selection = getSelection(fields, searchCard, 'group')
      const savedSearchResults = _.get(data, selection) || []

      const labels = []
      const bars = []
      !_.isEmpty(this.props.resultLayout.widgetOptions.properties.value) &&
        savedSearchResults.map(item => {
          const label =
            item.key[this.props.resultLayout.widgetOptions.properties.value]
          labels.push(label === null ? 'null' : label)
          bars.push(item.count)
        })

      return {
        x: labels,
        y: bars,
      }
    }
  }

  getSearchId = () => {
    const { options, sonraiSearches, resultLayout } = this.props
    let searchObj = Map({ uiSearch: null, advancedSearch: null })
    if (hasSonraiSearch(options, 'bar')) {
      const searches = getSearchIdForSonraiSearches(options, sonraiSearches)
      searchObj = searchObj.set('advancedSearch', searches)
    } else {
      searchObj = searchObj.set('uiSearch', resultLayout.indexedSearches.bar)
    }
    return searchObj
  }

  render() {
    if (this.props.data === undefined) {
      const searchId = this.getSearchId()
      const queryConfig = this.getQuery()
      if (!queryConfig.gqlStatement) {
        return (
          <WidgetCard
            loading={true}
            title={this.props.title}
            allowDelete={this.props.allowDelete}
            allowUpdate={this.props.allowUpdate}
            onRemove={this.props.onRemove}
            onEdit={this.props.onEdit}
          />
        )
      }

      const filtered = queryHasPivotFilter(queryConfig.gqlStatement)

      return (
        <Query
          query={gql`
            ${queryConfig.gqlStatement}
          `}
          notifyOnNetworkStatusChange
          pollInterval={300000}
          variables={queryConfig.variables}
          context={{
            queryName: `barchartWidget_${_.snakeCase(this.props.title)}`,
          }}
        >
          {({ error, data, refetch, networkStatus }) => {
            const results = this.getData(data)

            return (
              <WidgetCard
                createdBy={this.props.widget && this.props.widget.createdBy}
                searchId={searchId}
                disableToolbar={this.props.disableToolbar}
                allowDelete={this.props.allowDelete}
                allowUpdate={this.props.allowUpdate}
                onRemove={this.props.onRemove}
                onEdit={this.props.onEdit}
                toggleStatic={this.props.toggleStatic}
                static={this.props.static}
                refetch={refetch}
                networkStatus={networkStatus}
                title={
                  <TextLink
                    title={this.getSearchName()}
                    onClick={this.onClickSearch}
                  >
                    {this.props.title}
                  </TextLink>
                }
                filtered={filtered}
                error={error}
                containerStyle={{ padding: 0 }}
                description={_.get(this.props.resultLayout, [
                  'widgetOptions',
                  'description',
                ])}
              >
                <CardBody>
                  <Chart
                    onClickPoint={params => this.onClickPoint(params, data)}
                    yValues={results.y}
                    yLabels={results.x}
                    type="bar"
                    yTitle={this.props.title}
                  />
                </CardBody>
                <BottomTitle style={{ padding: '0.5em 1em' }}>
                  <TextLink
                    title={this.getSearchName()}
                    onClick={this.onClickSearch}
                  >
                    {this.props.title}
                  </TextLink>
                </BottomTitle>
              </WidgetCard>
            )
          }}
        </Query>
      )
    } else {
      return (
        <Card containerStyle={{ padding: 0 }}>
          <CardBody>
            <Chart
              yValues={this.props.data.y}
              yLabels={this.props.data.x}
              type="bar"
              yTitle={this.props.title}
              onChartReady={this.props.onChartReady}
              static={this.props.noAnimate}
            />
          </CardBody>

          <BottomTitle style={{ padding: '0.5em 1em' }}>
            <TextLink>{this.props.title}</TextLink>
          </BottomTitle>
        </Card>
      )
    }
  }
}

BarChartWidget.propTypes = {
  allowDelete: PropTypes.bool,
  allowUpdate: PropTypes.bool,
  disableToolbar: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  toggleStatic: PropTypes.func,
  savedSearches: ImmutablePropTypes.map.isRequired,
  static: PropTypes.bool,
  noAnimate: PropTypes.bool,
  options: PropTypes.object,
  onClickSearch: PropTypes.func.isRequired,
  onChartReady: PropTypes.func,
  resultLayout: PropTypes.object,
  getQueryBuilder: PropTypes.func,
  data: PropTypes.shape({
    x: PropTypes.array,
    y: PropTypes.array,
  }),
  onEdit: PropTypes.func,
  sonraiSearches: ImmutablePropTypes.iterable,
  widget: PropTypes.object,
}

export default BarChartWidget
