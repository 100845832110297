/*
 * SolutionCenter Messages
 *
 * This contains all the text for the SolutionCenter component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  identity: {
    id: 'app.container.SolutionCenter.header',
    defaultMessage: 'Identity',
  },
  infrastructure: {
    id: 'app.container.SolutionCenter.infrastructure',
    defaultMessage: 'Infrastructure',
  },
  data: {
    id: 'app.container.SolutionCenter.data',
    defaultMessage: 'Data',
  },
  cloud: {
    id: 'app.container.SolutionCenter.cloud',
    defaultMessage: 'Cloud',
  },
  header: {
    id: 'app.containers.SolutionCenter.header',
    defaultMessage: 'This is SolutionCenter container !',
  },
  createSC: {
    id: 'app.containers.SolutionCenter.createSC',
    defaultMessage: 'Create New Solution Card',
  },
  saveLayoutButton: {
    id: 'app.containers.SolutionCenter.saveLayoutButton',
    defaultMessage: 'Save Layout',
  },
  saveScEditButton: {
    id: 'app.containers.SolutionCenter.saveScEditButton',
    defaultMessage: 'Update',
  },
  cancelScEditButton: {
    id: 'app.containers.SolutionCenter.cancelScEditButton',
    defaultMessage: 'Cancel',
  },
  error: {
    id: 'app.containers.SolutionCenter.error',
    defaultMessage: "Ooops...that wasn't supposed to happen!",
  },
  errorContact: {
    id: 'app.containers.SolutionCenter.contact',
    defaultMessage:
      "We'd love to hear about it, though! You can contact support at",
  },
  statusDaysAll: {
    id: 'app.containers.SolutionCenterFilterStatus.statusDaysAll',
    defaultMessage: 'all time',
  },
  statusDays1: {
    id: 'app.containers.SolutionCenterFilterStatus.statusDays1',
    defaultMessage: 'the past 24 hours',
  },
  statusDays7: {
    id: 'app.containers.SolutionCenterFilterStatus.statusDays7',
    defaultMessage: 'the past 7 days',
  },
  statusDays30: {
    id: 'app.containers.SolutionCenterFilterStatus.statusDays30',
    defaultMessage: 'the past 30 days',
  },
  statusDays90: {
    id: 'app.containers.SolutionCenterFilterStatus.statusDays90',
    defaultMessage: 'the past 90 days',
  },
  filterApplied: {
    id: 'app.containers.SolutionCenterFilterStatus.filterApplied',
    defaultMessage: 'Filter Applied',
  },
  newWidget: {
    id: 'app.container.SolutionCenterToolbar.newWidget',
    defaultMessage: 'New Widget',
  },
})
