import { defineMessages } from 'react-intl'

export default defineMessages({
  cancelScEditButton: {
    id: 'app.containers.RenameSCModal.cancelScEditButton',
    defaultMessage: 'Cancel',
  },
  saveScEditButton: {
    id: 'app.containers.RenameSCModal.saveScEditButton',
    defaultMessage: 'Save',
  },
  hideCard: {
    id: 'app.containers.CardActionsMenu.hideCard',
    defaultMessage: 'Hide Solution Card',
  },
  renameCard: {
    id: 'app.containers.CardActionsMenu.renameCard',
    defaultMessage: 'Rename Solution Card',
  },
  deleteCard: {
    id: 'app.containers.CardActionsMenu.deleteCard',
    defaultMessage: 'Delete Solution Card',
  },
  copyCard: {
    id: 'app.containers.CardActionsMenu.copyCard',
    defaultMessage: 'Copy Solution Card',
  },
  saveLayoutButton: {
    id: 'app.containers.SolutionCenterActions.saveLayoutButton',
    defaultMessage: 'Save layout changes',
  },
  manageHiddenCards: {
    id: 'app.containers.SolutionCenterActions.manageHiddenCards',
    defaultMessage: 'Manage Hidden Solution Cards',
  },
})
