/*
 *
 * WidgetModal constants
 *
 */

export const SET_WIDGET_TITLE = 'app/WidgetModal/SET_WIDGET_NAME'
export const SET_WIDGET_SUB_TITLE = 'app/WidgetModal/SET_WIDGET_SUB_TITLE'
export const SET_WIDGET_TYPE = 'app/WidgetModal/SET_WIDGET_TYPE'
export const SET_WIDGET_SELECTOR = 'app/WidgetModal/SET_WIDGET_SELECTOR'
export const SET_WIDGET_FORMATTER = 'app/WidgetModal/SET_WIDGET_FORMATTER'
export const SET_WIDGET_SEARCH_FIELD = 'app/WidgetModal/SET_WIDGET_SEARCH_FIELD'
export const SET_WIDGET_SAVED_SEARCH = 'app/WidgetModal/SET_WIDGET_SAVED_SEARCH'
export const CLEAR_WIDGET_MODAL = 'app/WidgetModal/CLEAR_WIDGET_MODAL'
export const SET_WIDGET_ONCLICK_SEARCH =
  'app/WidgetModal/SET_WIDGET_ONCLICK_SEARCH'
export const SET_WIDGET_SIZE = 'app/WidgetModal/SET_WIDGET_SIZE'
export const SET_WIDGET_OPTIONS = 'app/WidgetModal/SET_WIDGET_OPTIONS'
export const GET_SONRAI_SAVED_SEARCHES =
  'app/WidgetModal/GET_SONRAI_SAVED_SEARCHES'
export const SET_SONRAI_SAVED_SEARCHES =
  'app/WidgetModal/SET_SONRAI_SAVED_SEARCHES'
export const SET_WIDGET_SONRAI_SAVED_SEARCH =
  'app/WidgetModal/SET_WIDGET_SONRAI_SAVED_SEARCH'
export const LOAD_WIDGET = 'app/WidgetModal/LOAD_WIDGET'
export const WIDGET_SONRAI_MASTER_QUERY = 'WIDGET_SONRAI_MASTER_QUERY'
export const TOGGLE_PREVIEW_WIDGET = 'app/WidgetModal/TOGGLE_PREVIEW_WIDGET'
