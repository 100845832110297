import { defineMessages } from 'react-intl'

export default defineMessages({
  widgetError: {
    id: 'app.containers.SolutionCenterWidgets.widgetError',
    defaultMessage: 'Oops...There was an error loading your widget data',
  },
  widgetNoPermission: {
    id: 'app.containers.SolutionCenterWidgets.widgetNoPermission',
    defaultMessage: 'Insufficent Permissions to view data',
  },
  logError: {
    id: 'app.containers.SolutionCenterWidgets.logError',
    defaultMessage: 'Click to Log',
  },
})
