import { createSelector } from 'reselect'
import { List } from 'immutable'

export const selectSolutionCenterDomain = state => state.get('solutionCenter')

export const selectCards = createSelector(
  selectSolutionCenterDomain,
  solutionCenter => solutionCenter.get('solutionCards', List())
)

export const selectShowCreateWidgetModal = createSelector(
  selectSolutionCenterDomain,
  solutionCenter => {
    return solutionCenter.get('showCreateWidgetModal')
  }
)

export const selectIsScCopySaving = createSelector(
  selectSolutionCenterDomain,
  solutionCenter => solutionCenter.get('copySolutionCardSaving')
)
