import { defineMessages } from 'react-intl'

export default defineMessages({
  header: {
    id: 'app.containers.AddSolutionCardModal.header',
    defaultMessage: 'Create Solution Card',
  },
  nameLabel: {
    id: 'app.containers.AddSolutionCardModal.nameLabel',
    defaultMessage: 'Name',
  },
  titlePlaceholder: {
    id: 'app.containers.AddSolutionCardModal.titlePlaceholder',
    defaultMessage: 'E.g. Identities',
  },
  titlePlaceholderErr: {
    id: 'app.containers.AddSolutionCardModal.titlePlaceholder',
    defaultMessage: 'Please Enter A Name...',
  },
  createButton: {
    id: 'app.containers.AddSolutionCardModal.createButton',
    defaultMessage: 'Create',
  },
  cancelButton: {
    id: 'app.containers.AddSolutionCardModal.cancelButton',
    defaultMessage: 'Cancel',
  },
  noDup: {
    id: 'app.containers.AddSolutionCardModal.noDup',
    defaultMessage: 'This card name already exists.',
  },
})
