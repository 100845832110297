import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import _ from 'lodash'
import FormLabel from 'components/FormLabel'
import CombinedSearches from 'components/CombinedSearches'
import RatioWidget from 'components/RatioWidget'
import PreviewContainer from './PreviewContainer'

const styles = {
  wrapperWithPreview: {
    display: 'grid',
    gridTemplateColumns: '35% 1fr',
    gridColumnGap: '2em',
  },
}
export class RatioConfig extends React.Component {
  componentDidUpdate() {
    this.updateValidity()
  }

  updateValidity = () => {
    const hasNumSonraiSearch = !!this.props.widgetSonraiSearches.num
    const hasNumSavedSearch =
      !!this.props.widgetSavedSearches.num && !!this.props.widgetSearchCards.num

    const hasDenomSonraiSearch = !!this.props.widgetSonraiSearches.denom
    const hasDenomSavedSearch =
      !!this.props.widgetSavedSearches.denom &&
      !!this.props.widgetSearchCards.denom

    const hasValidNumSearch = hasNumSavedSearch || hasNumSonraiSearch
    const hasValidDenomSearch = hasDenomSavedSearch || hasDenomSonraiSearch

    const hasTitle = this.props.widgetTitle

    const valid = hasValidNumSearch && hasValidDenomSearch && hasTitle
    this.props.setValidity(valid)
  }

  setTitle = e => {
    this.props.setWidgetTitle(e.target.value)
  }

  setDescription = e => {
    const string = e.target.value

    this.props.setWidgetOptions({
      description: string,
    })
  }

  setNumeratorSearch = option => {
    this.props.setWidgetSavedSearch(option ? option.value : null, 'num')
  }

  setDenominatorSearch = option => {
    this.props.setWidgetSavedSearch(option ? option.value : null, 'denom')
  }

  setOnclickSearch = option => {
    this.props.setWidgetSavedSearch(option ? option.value : null, 'onclick')
  }

  setSonraiOnclickSearch = selectedOption => {
    this.props.setWidgetSonraiSearch(
      selectedOption ? selectedOption.value : null,
      'sonraionclick'
    )
  }

  setNumeratorField = option => {
    this.props.setWidgetSearchField(option ? option.value : null, 'num')
  }

  setDenominatorField = option => {
    this.props.setWidgetSearchField(option ? option.value : null, 'denom')
  }

  updateDisplayType = e => {
    this.props.setWidgetOptions({
      displayType: e.target.value,
    })
  }

  setSonraiSearchDenom = selectedOption => {
    this.props.setWidgetSonraiSearch(
      selectedOption ? selectedOption.value : null,
      'denom'
    )
  }

  setSonraiSearchNum = selectedOption => {
    this.props.setWidgetSonraiSearch(
      selectedOption ? selectedOption.value : null,
      'num'
    )
  }

  render() {
    const denominatorSearchId = this.props.widgetSavedSearches['denom']
    const denominatorField = this.props.widgetSearchCards['denom']

    const numeratorSearchId = this.props.widgetSavedSearches['num']
    const numeratorField = this.props.widgetSearchCards['num']

    const onclickSearchId = this.props.widgetSavedSearches['onclick']
    const sonraiOnclickSearch = this.props.widgetSonraiSearches['sonraionclick']

    const numeratorSonraiSearchName = this.props.widgetSonraiSearches['num']
    const denomSonraiSearchName = this.props.widgetSonraiSearches['denom']

    const denomSearchFieldsList = this.props.searchCards.get(
      denominatorSearchId,
      List()
    )
    const numSearchFieldsList = this.props.searchCards.get(
      numeratorSearchId,
      List()
    )
    return (
      <div style={this.props.previewWidget ? styles.wrapperWithPreview : {}}>
        {this.props.previewWidget && (
          <PreviewContainer>
            <div
              style={{ minWidth: '225px', height: '225px', maxHeight: '225px' }}
            >
              <RatioWidget
                disableToolbar
                data={
                  !_.isEmpty(this.props.widgetSavedSearches.num) &&
                  !_.isEmpty(this.props.widgetSavedSearches.denom) &&
                  !_.isEmpty(this.props.widgetSearchCards.num) &&
                  !_.isEmpty(this.props.widgetSearchCards.denom)
                    ? undefined
                    : { num: 0, denom: 12 }
                }
                getQueryBuilder={this.props.getQueryBuilder}
                savedSearches={this.props.savedSearches}
                resultLayout={{
                  widgetOptions: this.props.widgetOptions,
                  indexedSearches: this.props.widgetSavedSearches,
                  searchCards: this.props.widgetSearchCards,
                }}
                options={{ sonraiSearches: this.props.widgetSonraiSearches }}
                title={
                  this.props.widgetTitle ? (
                    this.props.widgetTitle
                  ) : (
                    <span style={{ fontStyle: 'italic' }}>Widget Title</span>
                  )
                }
              />
            </div>
          </PreviewContainer>
        )}
        <div>
          <FormLabel label="Title" for="widgetTitle" required={true} />
          <Input
            type="text"
            name="widgetTitle"
            id="widgetTitle"
            placeholder="Number of Identities"
            value={this.props.widgetTitle || ''}
            onChange={this.setTitle}
          />

          <FormLabel label="Description" for="widgetDescription" />
          <Input
            id="widgetDescription"
            type="textarea"
            name="widgetDescription"
            onChange={this.setDescription}
            value={this.props.widgetOptions.description || ''}
          />

          <FormLabel
            label="Display Format"
            for="widgetDisplay"
            required={true}
          />
          <Input
            type="select"
            name="widgetDisplay"
            id="widgetDisplay"
            value={this.props.widgetOptions.displayType || 'ratio'}
            onChange={this.updateDisplayType}
          >
            <option value={'ratio'}>Ratio of Counts</option>
            <option value={'percentage'}>Percentage</option>
            <option value={'average'}>Average</option>
          </Input>

          <FormLabel
            label="Target Count (Numerator)"
            for="widgetNumSearch"
            required={true}
          />
          <CombinedSearches
            name="widgetNumSearch"
            savedSonraiSearches={this.props.savedSonraiSearches}
            setSonraiSearch={this.setSonraiSearchNum}
            selectedSonraiValue={numeratorSonraiSearchName}
            savedSearches={this.props.savedSearches}
            selectedSearchId={numeratorSearchId}
            selectSavedSearch={this.setNumeratorSearch}
            savedSearchDisabled={!!numeratorSonraiSearchName}
            selectedFieldId={numeratorField ? numeratorField.id : null}
            searchCards={numSearchFieldsList}
            searchFieldDisabled={numSearchFieldsList.isEmpty()}
            selectSearchField={this.setNumeratorField}
          />

          <FormLabel
            label="Total Count (Denominator)"
            for="widgetDenomSearch"
            required={true}
          />
          <CombinedSearches
            name="widgetDenomSearch"
            savedSonraiSearches={this.props.savedSonraiSearches}
            setSonraiSearch={this.setSonraiSearchDenom}
            selectedSonraiValue={denomSonraiSearchName}
            savedSearches={this.props.savedSearches}
            selectedSearchId={denominatorSearchId}
            selectSavedSearch={this.setDenominatorSearch}
            savedSearchDisabled={!!denomSonraiSearchName}
            selectedFieldId={denominatorField ? denominatorField.id : null}
            searchCards={denomSearchFieldsList}
            searchFieldDisabled={denomSearchFieldsList.isEmpty()}
            selectSearchField={this.setDenominatorField}
          />

          <FormLabel
            label="Search Linked from Widget Title"
            for="widgetNavSearch"
          />
          <CombinedSearches
            name="widgetNavSearch"
            savedSearches={this.props.savedSearches}
            selectedSearchId={onclickSearchId}
            selectSavedSearch={this.setOnclickSearch}
            savedSonraiSearches={this.props.savedSonraiSearches}
            setSonraiSearch={this.setSonraiOnclickSearch}
            selectedSonraiValue={sonraiOnclickSearch}
          />
        </div>
      </div>
    )
  }
}

RatioConfig.propTypes = {
  getQueryBuilder: PropTypes.func.isRequired,
  previewWidget: PropTypes.bool,
  savedSearches: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      sid: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  searchCards: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  setWidgetOptions: PropTypes.func.isRequired,
  setWidgetSavedSearch: PropTypes.func,
  setWidgetSearchField: PropTypes.func,
  setWidgetTitle: PropTypes.func,
  setValidity: PropTypes.func,
  widgetSavedSearches: PropTypes.objectOf(PropTypes.array),
  widgetSearchCards: PropTypes.objectOf(PropTypes.object),
  widgetSonraiSearches: PropTypes.objectOf(PropTypes.string),
  widgetTitle: PropTypes.string,
  widgetOptions: PropTypes.shape({
    description: PropTypes.string,
    displayType: PropTypes.string,
  }),
  savedSonraiSearches: ImmutablePropTypes.iterableOf(
    ImmutablePropTypes.contains({
      sid: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  setWidgetSonraiSearch: PropTypes.func,
}

export default RatioConfig
