import React, { Fragment } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { Table, Input, Label } from 'reactstrap'
import { List } from 'immutable'
import _ from 'lodash'

import IHelp from 'containers/IHelp'
import BigStatWidget from 'components/BigStatWidget'
import TextLink from 'components/TextLink'
import Button from 'components/Button'
import ColorPicker from 'components/ColorPicker'
import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'
import FormLabel from 'components/FormLabel'
import CombinedSearches from 'components/CombinedSearches'
import { BOUNDARY_OPTIONS } from 'appConstants'
import PreviewContainer from './PreviewContainer'

const styles = {
  sectionTitle: {
    fontSize: '1em',
    fontWeight: '400',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
  },
  addThresholdButton: {
    display: 'flex',
    alignItems: 'center',
  },
  addThresholdIcon: {
    marginRight: '0.5em',
    fontSize: '20px',
  },
  colorPreview: {
    width: '1em',
    height: '1em',
    border: '1px solid #888',
    display: 'inline-block',
    marginRight: '0.5em',
    borderRadius: '0',
  },
  cancelAddThreshold: {
    marginLeft: '0.5em',
  },
  colorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  newThresholdWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  newcolorWrapper: {
    margin: '1em 0',
  },
  thresholdButton: {
    marginBottom: '15px',
    marginRight: '10px',
    width: '45%',
    outline: 'none',
  },
  bigCountPreview: {
    minWidth: '225px',
    height: '225px',
  },
  wrapperWithPreview: {
    display: 'grid',
    gridTemplateColumns: '35% 1fr',
    gridColumnGap: '2em',
  },
  config: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '2em',
  },
}

export class BigCountConfig extends React.Component {
  state = {
    showAddThreshold: false,
    startOfThreshold: 0,
    endOfThreshold: 0,
    showColorPicker: false,
    newThresholdColor: '#FFFFFF',
    operatorSign: '',
    operatorVal: 0,
    range: true,
    boundary: false,
  }

  componentDidUpdate() {
    this.updateValidity()
  }

  updateValidity = () => {
    const hasSonraiSearch = !!this.props.widgetSonraiSearches.bigcountsearch
    const hasSavedSearch =
      !!this.props.widgetSavedSearches.bigcountsearch &&
      !!this.props.widgetSearchCards.bigcountsearch
    const hasTitle = this.props.widgetTitle

    const valid = (hasSonraiSearch || hasSavedSearch) && hasTitle
    this.props.setValidity(valid)
  }

  setTitle = e => {
    this.props.setWidgetTitle(e.target.value)
  }

  selectSavedSearch = selectedOption => {
    this.props.setWidgetSavedSearch(
      selectedOption ? selectedOption.value : null,
      'bigcountsearch'
    )
  }

  selectSearchField = selectedOption => {
    this.props.setWidgetSearchField(
      selectedOption ? selectedOption.value : null,
      'bigcountsearch'
    )
  }

  setSonraiSearch = selectedOption => {
    this.props.setWidgetSonraiSearch(
      selectedOption ? selectedOption.value : null,
      'bigcountsearch'
    )
  }

  setDescription = e => {
    const string = e.target.value

    this.props.setWidgetOptions({
      description: string,
    })
  }

  removeMarker = index => {
    const thresholds = [...this.props.widgetOptions.thresholds]

    thresholds.splice(index, 1)
    this.props.setWidgetOptions({
      thresholds,
    })
  }

  handleStartOfThreshold = e => {
    this.setState({
      startOfThreshold: e.target.value,
    })
  }

  showColorPicker = () => {
    this.setState({
      showColorPicker: true,
    })
  }

  onPickColor = color => {
    this.setState({
      newThresholdColor: color,
    })
  }

  toggleColorPicker = () => {
    this.setState(state => ({
      showColorPicker: !state.showColorPicker,
    }))
  }

  handleEndOfThreshold = e => {
    this.setState({
      endOfThreshold: e.target.value,
    })
  }

  toggleAddThreshold = () => {
    this.setState(state => ({
      showAddThreshold: !state.showAddThreshold,
    }))
  }

  handleSelect = event => {
    this.setState({
      operatorSign: event.target.value,
    })
  }

  handleOpperator = event => {
    this.setState({
      operatorVal: event.target.value,
    })
  }

  handleBoundaryButton = () => {
    this.setState({
      boundary: true,
      range: false,
      newThresholdColor: '#FFFFFF',
      startOfThreshold: 0,
      endOfThreshold: 0,
      operatorSign: BOUNDARY_OPTIONS.GREATER,
      operatorVal: 0,
    })
  }

  handleRangeButton = () => {
    this.setState({
      range: true,
      boundary: false,
      newThresholdColor: '#FFFFFF',
      startOfThreshold: 0,
      endOfThreshold: 0,
      operatorSign: '',
      operatorVal: 0,
    })
  }

  resetAddThreshold = () => {
    this.setState({
      showAddThreshold: false,
      showColorPicker: false,
      newThresholdColor: '#FFFFFF',
      startOfThreshold: 0,
      endOfThreshold: 0,
      operatorSign: '',
      operatorVal: 0,
    })
  }

  addThreshold = type => {
    const options = this.props.widgetOptions || {}
    const thresholds = options.thresholds || []

    if (type === 'between') {
      thresholds.push({
        start: this.state.startOfThreshold,
        end: this.state.endOfThreshold,
        color: this.state.newThresholdColor,
        type: type,
      })
    } else {
      thresholds.push({
        operatorSign: this.state.operatorSign,
        operatorVal: this.state.operatorVal,
        color: this.state.newThresholdColor,
        type: type,
      })
    }

    this.props.setWidgetOptions({
      thresholds,
    })

    this.resetAddThreshold()
  }

  renderThresholdsConfig = () => {
    const thresholds = this.props.widgetOptions.thresholds || []
    return (
      <Table>
        <tbody>
          {thresholds.map((threshold, index) => (
            <tr key={threshold.color}>
              {threshold.type === 'between' ? (
                <Fragment>
                  <td>
                    <BorderlessButton
                      disabled={threshold.end === 0}
                      onClick={() => this.removeMarker(index)}
                    >
                      <Icon fa name="trash" size="1x" />
                    </BorderlessButton>
                  </td>
                  <td>{threshold.start}</td>
                  <td>To</td>
                  <td>{threshold.end}</td>
                  <td>
                    <div style={styles.colorContainer}>
                      <span
                        style={{
                          ...styles.colorPreview,
                          ...{ backgroundColor: threshold.color },
                        }}
                      />
                      {threshold.color}
                    </div>
                  </td>
                </Fragment>
              ) : (
                <Fragment>
                  <td>
                    <BorderlessButton
                      disabled={threshold.end === 0}
                      onClick={() => this.removeMarker(index)}
                    >
                      <Icon fa name="trash" size="1x" />
                    </BorderlessButton>
                  </td>
                  <td>{threshold.operatorSign}</td>
                  <td>{threshold.operatorVal}</td>
                  <td>
                    <div style={styles.colorContainer}>
                      <span
                        style={{
                          ...styles.colorPreview,
                          ...{ backgroundColor: threshold.color },
                        }}
                      />
                      {threshold.color}
                    </div>
                  </td>
                </Fragment>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  renderNewThresholdConfig = () => {
    return (
      <div>
        <div>
          <Button
            outline
            style={styles.thresholdButton}
            color={this.state.range ? 'primary' : undefined}
            onClick={this.handleRangeButton}
          >
            Range
          </Button>
          <Button
            style={styles.thresholdButton}
            outline
            color={this.state.boundary ? 'primary' : undefined}
            onClick={this.handleBoundaryButton}
          >
            Boundary
          </Button>
        </div>
        {this.state.range ? (
          <div style={styles.newThresholdWrapper}>
            <div>
              <Label for="StartOfThreshold">From</Label>
              <Input
                style={{ width: 100 }}
                type="number"
                name="StartOfThreshold"
                value={this.state.startOfThreshold}
                onChange={this.handleStartOfThreshold}
              />
            </div>
            <div>
              <Label for="EndOfThreshold">To</Label>
              <Input
                style={{ width: 100 }}
                type="number"
                name="EndOfThreshold"
                value={this.state.endOfThreshold}
                onChange={this.handleEndOfThreshold}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'grid',
              marginTop: '20px',
              gridTemplateColumns: '1fr 40%',
              gridColumnGap: '1em',
            }}
          >
            <div>
              <Input
                type="select"
                name="SelectAnOperator"
                onChange={this.handleSelect}
                value={this.state.operatorSign}
              >
                <option value={BOUNDARY_OPTIONS.GREATER}>Greater than</option>
                <option value={BOUNDARY_OPTIONS.LESS}>Less than</option>
                <option value={BOUNDARY_OPTIONS.EQUAL}>Equal to</option>
              </Input>
            </div>
            <div>
              <Input
                type="number"
                value={this.state.operatorVal}
                name="InputOperatorVal"
                onChange={this.handleOpperator}
              />
            </div>
          </div>
        )}
        <div style={styles.newcolorWrapper}>
          <Label>Border color</Label>
          <ColorPicker
            onPickColor={this.onPickColor}
            color={this.state.newThresholdColor}
          />
        </div>

        <div>
          <Button
            color="primary"
            disabled={!this.state.newThresholdColor}
            onClick={() =>
              this.addThreshold(
                this.state.endOfThreshold !== 0 ? 'between' : 'operator'
              )
            }
          >
            Add Threshold
          </Button>
          <TextLink
            style={styles.cancelAddThreshold}
            color="secondary"
            onClick={this.resetAddThreshold}
          >
            Cancel
          </TextLink>
        </div>
      </div>
    )
  }

  renderPreview = () => {
    return (
      <PreviewContainer>
        <div style={styles.bigCountPreview}>
          <BigStatWidget
            disableToolbar
            data={
              !_.isEmpty(this.props.widgetSavedSearches) &&
              !_.isEmpty(this.props.widgetSearchCards)
                ? undefined
                : 1997
            }
            getQueryBuilder={this.props.getQueryBuilder}
            savedSearches={this.props.savedSearches}
            resultLayout={{
              widgetOptions: this.props.widgetOptions,
              indexedSearches: this.props.widgetSavedSearches,
              searchCards: this.props.widgetSearchCards,
            }}
            options={{ sonraiSearches: this.props.widgetSonraiSearches }}
            title={
              this.props.widgetTitle ? (
                this.props.widgetTitle
              ) : (
                <span style={{ fontStyle: 'italic' }}>Widget Title</span>
              )
            }
          />
        </div>
      </PreviewContainer>
    )
  }

  render() {
    const sonraiSearchName = this.props.widgetSonraiSearches['bigcountsearch']
    const savedSearchId = this.props.widgetSavedSearches['bigcountsearch']
    const savedSearchField = this.props.widgetSearchCards['bigcountsearch']
    const searchFieldsList = this.props.searchCards.get(
      this.props.widgetSavedSearches['bigcountsearch'],
      List()
    )

    return (
      <div style={this.props.previewWidget ? styles.wrapperWithPreview : {}}>
        {this.props.previewWidget && this.renderPreview()}

        <div style={styles.config}>
          <div>
            <FormLabel label="Title" for="widgetTitle" required={true} />
            <Input
              type="text"
              name="widgetTitle"
              id="widgetTitle"
              placeholder="Number of Identities"
              value={this.props.widgetTitle || ''}
              onChange={this.setTitle}
            />

            <FormLabel label="Description" for="widgetDescription" />
            <Input
              id="widgetDescription"
              type="textarea"
              name="widgetDescription"
              onChange={this.setDescription}
              value={this.props.widgetOptions.description || ''}
            />

            <FormLabel label="Search" for="widgetSearch" required={true} />
            <CombinedSearches
              savedSonraiSearches={this.props.savedSonraiSearches}
              setSonraiSearch={this.setSonraiSearch}
              selectedSonraiValue={sonraiSearchName}
              savedSearches={this.props.savedSearches}
              selectedSearchId={savedSearchId}
              selectSavedSearch={this.selectSavedSearch}
              savedSearchDisabled={!!sonraiSearchName}
              selectedFieldId={savedSearchField ? savedSearchField.id : null}
              searchCards={searchFieldsList}
              searchFieldDisabled={searchFieldsList.isEmpty()}
              selectSearchField={this.selectSearchField}
              searchFieldRequired
            />
          </div>

          <div>
            <h3 style={styles.sectionTitle}>
              Border Color Thresholds
              <IHelp
                info
                infoMsg="Change the border color of the widget based on the result value"
              />
            </h3>
            {this.renderThresholdsConfig()}
            {this.state.showAddThreshold ? (
              this.renderNewThresholdConfig()
            ) : (
              <TextLink
                style={styles.addThresholdButton}
                onClick={this.toggleAddThreshold}
                color="secondary"
              >
                <Icon name="plus" fa style={styles.addThresholdIcon} /> Add new
                Threshold
              </TextLink>
            )}
          </div>
        </div>
      </div>
    )
  }
}

BigCountConfig.propTypes = {
  getQueryBuilder: PropTypes.func,
  previewWidget: PropTypes.bool,
  savedSearches: ImmutablePropTypes.iterableOf(
    ImmutablePropTypes.contains({
      sid: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  searchCards: ImmutablePropTypes.mapOf(
    PropTypes.oneOfType([
      ImmutablePropTypes.contains({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
      PropTypes.array,
    ])
  ),
  setWidgetOptions: PropTypes.func,
  setWidgetSavedSearch: PropTypes.func,
  setWidgetSearchField: PropTypes.func,
  setWidgetTitle: PropTypes.func,
  setValidity: PropTypes.func,
  widgetSavedSearches: PropTypes.object,
  widgetSearchCards: PropTypes.object,
  widgetSonraiSearches: PropTypes.object,
  widgetTitle: PropTypes.string,
  widgetOptions: PropTypes.shape({
    thresholds: PropTypes.arrayOf(
      PropTypes.shape({
        start: PropTypes.number,
        end: PropTypes.number,
        color: PropTypes.string,
      })
    ),
    description: PropTypes.string,
  }).isRequired,
  savedSonraiSearches: ImmutablePropTypes.iterableOf(
    ImmutablePropTypes.contains({
      sid: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  setWidgetSonraiSearch: PropTypes.func,
}

export default BigCountConfig
