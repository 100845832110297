import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'
import ImmutablePropTypes from 'react-immutable-proptypes'
import TextLink from 'components/TextLink'
import Icon from 'components/Icon'
import FormLabel from 'components/FormLabel'
import Button from 'components/Button'
import { copySolutionCard } from 'containers/SolutionCenter/actions'
import { selectIsScCopySaving } from 'containers/SolutionCenter/selectors'
import {
  selectSelectedCardName,
  selectSelectedCard,
  selectSortedCards,
} from 'containers/SolutionCenterCards/selectors'
import messages from './messages.js'

export class ModalCopySC extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: props.originalName,
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.saving && !this.props.saving) {
      this.props.toggle()
    }

    if (oldProps.originalName !== this.props.originalName) {
      this.setState({
        name: this.props.originalName,
      })
    }
  }

  copySolutionCard = () => {
    const title =
      this.state.name !== '' ? this.state.name.toLowerCase() : this.state.name
    const sortedCardNames = this.props.sortedCards.map(card =>
      card.get('name').toLowerCase()
    )
    if (title !== '' && !sortedCardNames.includes(title)) {
      this.props.copySolutionCard({
        title: this.state.name,
        widgets: this.props.selectedCard.get('widgets'),
      })
    } else {
      this.setState({ showMessage: true })
    }
  }

  setName = e => {
    this.setState({
      name: e.target.value,
    })
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>Copy Solution Card</ModalHeader>
        <ModalBody>
          <FormLabel for="scTitle" required>
            New Solution Card Name
          </FormLabel>
          <Input
            type="text"
            name="scTitle"
            id="scTitle"
            value={this.state.name}
            onChange={this.setName}
          />
        </ModalBody>
        <ModalFooter>
          <TextLink color="primary" onClick={this.props.toggle}>
            <FormattedMessage {...messages.cancelScEditButton} />
          </TextLink>
          <Button
            disabled={this.props.saving || !this.state.name}
            color="primary"
            onClick={this.copySolutionCard}
          >
            {this.props.saving ? (
              <Icon spin fa name="sync" transform="shrink-3" />
            ) : (
              <FormattedMessage {...messages.saveScEditButton} />
            )}
          </Button>{' '}
        </ModalFooter>
      </Modal>
    )
  }
}

ModalCopySC.propTypes = {
  isOpen: PropTypes.bool,
  originalName: PropTypes.string,
  saving: PropTypes.bool,
  toggle: PropTypes.func,
  sortedCards: ImmutablePropTypes.iterable,
  selectedCard: ImmutablePropTypes.map.isRequired,
  copySolutionCard: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  originalName: selectSelectedCardName,
  selectedCard: selectSelectedCard,
  sortedCards: selectSortedCards,
  saving: selectIsScCopySaving,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      copySolutionCard,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

export default compose(withConnect)(ModalCopySC)
