import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import WidgetCard from 'components/WidgetCard'
import fail from 'assets/sadcloud.png'

import messages from './messages'

export default class WidgetErrorBoundry extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      errorMessage: '',
      errorSource: '',
      componentStack: '',
      errorStack: '',
    }

    this.styles = {
      errorWrapper: {
        overflow: 'auto',
        color: '#666666',
        textAlign: 'center',
      },
      errorImg: {
        height: '40px',
        maxWidth: '150px',
      },
    }
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      errorMessage: error.message,
      errorStack: error.stack,
      componentStack: info.componentStack,
      errorSource: error.source ? error.source.body : '',
    })
  }

  render() {
    if (this.state.hasError || !this.props.canViewData) {
      return (
        <WidgetCard
          allowDelete={this.props.allowDelete}
          allowUpdate={this.props.allowUpdate}
          onEdit={this.props.onEdit}
          onRemove={this.props.onRemove}
        >
          <div style={this.styles.errorWrapper}>
            {this.props.canViewData && (
              <img style={this.styles.errorImg} src={fail} />
            )}
            <p>
              {!this.props.canViewData ? (
                `${messages.widgetNoPermission.defaultMessage} for Widget: ${
                  this.props.title
                }`
              ) : (
                <FormattedMessage {...messages.widgetError} />
              )}
            </p>
            <p> {this.state.errorMessage} </p>
          </div>
        </WidgetCard>
      )
    }

    return this.props.children
  }
}

WidgetErrorBoundry.defaultProps = {
  allowDelete: true,
  allowUpdate: false,
}

WidgetErrorBoundry.propTypes = {
  allowDelete: PropTypes.bool,
  allowUpdate: PropTypes.bool,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  title: PropTypes.string,
  canViewData: PropTypes.bool,
}
