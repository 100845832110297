import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'components/Icon'
import Hoverable from 'components/Hoverable'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import IconLayer from 'components/IconLayer'
import Tooltip from 'components/Tooltip'

export const COMPOUND_ICONS = {
  BIG_STAT: 'bigstat',
}

class WidgetType extends React.PureComponent {
  constructor(props) {
    super(props)

    this.styles = {
      pickerIcon: {
        display: 'flex',
        justifyContent: 'center',
      },
      pickerTitle: {
        gridArea: 'title',
        paddingTop: '1.5em',
        display: 'flex',
        justifyContent: 'center',
      },
      tooltip: {
        position: 'absolute',
        top: '3px',
        right: '3px',
      },
      wrapper: {
        borderRadius: '3px',
        padding: '1em',
        position: 'relative',
        height: '100%',
      },
      content: {
        display: 'grid',
        gridTemplateRows: '1fr auto',
        gridTemplateAreas: '"icon" "title"',
        justifyContent: 'center',
        height: '100%',
      },
    }
  }

  getIcon = hovered => {
    const commonIconProps = {
      color: hovered ? this.props.theme.primary : this.props.theme.neutralDark,
      style: { fontSize: '40px' },
    }

    if (this.props.icon) {
      return (
        <Icon
          fa
          name={this.props.icon}
          transform={this.props.transformIcon}
          {...commonIconProps}
        />
      )
    }

    if (this.props.compoundIcon) {
      switch (this.props.compoundIcon) {
        case COMPOUND_ICONS.BIG_STAT:
          return (
            <IconLayer>
              <Icon
                fa
                name="square"
                transform="left-4 down-4"
                {...commonIconProps}
                style={{ fontSize: '45px' }}
              />
              <Icon
                fa
                name="hashtag"
                {...commonIconProps}
                transform="down-4 left-2"
                style={{ fontSize: '20px' }}
              />
              <Icon
                fa
                name="minus"
                {...commonIconProps}
                transform="down-25 left-2"
                style={{ fontSize: '15px' }}
              />
            </IconLayer>
          )
      }
    }
  }

  render() {
    const { label, onClick, theme, tooltipContent } = this.props
    return (
      <Hoverable
        onClick={onClick}
        renderContent={({ hovered }) => {
          return (
            <div
              style={{
                ...this.styles.wrapper,
                border: hovered
                  ? `1px solid ${theme.primary}`
                  : '1px solid #eee',
                backgroundColor: hovered ? theme.highlight : '#fcfcfc',
              }}
            >
              <div style={this.styles.content}>
                <div style={this.styles.pickerIcon}>
                  {this.getIcon(hovered)}
                </div>
                <div
                  style={{
                    color: hovered ? theme.primary : undefined,
                    ...this.styles.pickerTitle,
                  }}
                >
                  {label}
                </div>
              </div>
              {tooltipContent && (
                <div
                  style={{
                    ...this.styles.tooltip,
                    color: hovered ? theme.primary : theme.neutralMedium,
                  }}
                >
                  <Tooltip
                    tooltipContent={tooltipContent}
                    anchor={<Icon name="help" fa />}
                  />
                </div>
              )}
            </div>
          )
        }}
      />
    )
  }
}

WidgetType.propTypes = {
  icon: PropTypes.string,
  compoundIcon: PropTypes.string,
  label: PropTypes.node,
  onClick: PropTypes.func,
  theme: themeShape,
  tooltipContent: PropTypes.node,
  transformIcon: PropTypes.string,
}

export default themeable(WidgetType)
