/**
 *
 * FilterPanel
 *
 */

import React from 'react'
import PropTypes from 'prop-types'

import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Button from 'components/Button'
import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'
import IHelp from 'containers/IHelp'

class FilterPanel extends React.Component {
  constructor(props) {
    super(props)

    this.styles = {
      panel: {
        backgroundColor: props.theme.light,
        boxShadow: '-1px 0 3px 0 rgba(0, 0, 0, 0.25)',
        position: 'absolute',
        right: 0,
        top: 0,
        height: '100%',
        width: '30em',
        margin: 0,
        display: 'grid',
        gridTemplateRows: 'auto 1fr 12%',
      },
      body: {
        padding: '1em',
        flex: 1,
      },
      header: {
        borderBottom: `1px solid ${props.theme.neutralLight}`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1em 0.2em',
      },
      headerContent: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 1em',
      },
      footerButton: {
        display: 'block',
        height: '100%',
        width: '100%',
        borderTop: `1px solid ${props.theme.neutralLight}`,
      },
      title: {
        fontSize: '1.1em',
        paddingLeft: '1em',
      },
    }
  }

  render() {
    return (
      <div style={this.styles.panel} className="slide-in-right">
        <div style={this.styles.header}>
          <div style={this.styles.headerContent}>
            <IHelp id="filterPanel" help helpKey="filterPanel" />
            <div style={this.styles.title}>{this.props.title}</div>
          </div>
          <div style={this.styles.headerContent}>
            <BorderlessButton onClick={this.props.toggle}>
              <Icon fa name="times" />
            </BorderlessButton>
          </div>
        </div>
        <div style={this.styles.body}>{this.props.children}</div>
        <div>
          <Button
            color={this.props.hasChanges ? 'secondary' : undefined}
            style={this.styles.footerButton}
            onClick={this.props.applyFilters}
          >
            Apply Filters
          </Button>
        </div>
      </div>
    )
  }
}

FilterPanel.propTypes = {
  applyFilters: PropTypes.func,
  children: PropTypes.node,
  hasChanges: PropTypes.bool,
  theme: themeShape,
  title: PropTypes.node,
  toggle: PropTypes.func,
}

export default themeable(FilterPanel)
export { default as Filter } from './Filter'
export { default as TypeaheadFilter } from './TypeaheadFilter'
