/*
 *
 * SolutionCenter actions
 *
 */

import {
  LOAD_SOLUTION_CARDS,
  ADD_SC_WIDGET,
  TOGGLE_SC_WIDGET_STATIC,
  REMOVE_SC_WIDGET,
  HANDLE_TAB_EDIT,
  HANDLE_TAB_SEQ_CHANGE,
  HANDLE_SC_ADD,
  GET_SOLUTION_CARDS,
  DELETE_SOLUTION_CARD,
  UPDATE_WIDGET,
  UPDATE_WIDGET_SUCCESS,
  UPDATE_EDIT_WIDGET,
  SET_WIDGET_OPTIONS,
  SAVE_LAYOUT,
  SAVE_LAYOUT_SUCCESS,
  REMOVE_WIDGET_SUCCESS,
  ADD_SOLUTION_CARD_SUCCESS,
  DELETE_SOLUTION_CARD_SUCCESS,
  ADD_WIDGET_SUCCESS,
  UPDATE_SOLUTION_CARD,
  UPDATE_SOLUTION_CARD_SUCCESS,
  START_UPDATE_SC_POLL,
  STOP_UPDATE_SC_POLL,
  SET_SHOW_CREATE_WIDGET_MODAL,
  COPY_SOLUTION_CARD,
  COPY_SOLUTION_CARD_SUCCESS,
} from './constants'
import { createAction } from 'redux-actions'

export const updateWidget = createAction(UPDATE_WIDGET, (cardId, widget) => ({
  cardId,
  widget,
}))

export const setWidgetOptions = createAction(
  SET_WIDGET_OPTIONS,
  (cardId, widgetId, options) => ({
    cardId,
    widgetId,
    options,
  })
)
export const deleteSolutionCard = createAction(DELETE_SOLUTION_CARD, srn => ({
  srn,
}))
export const getSolutionCards = createAction(GET_SOLUTION_CARDS)
export const handleTabEdit = createAction(
  HANDLE_TAB_EDIT,
  ({ type, index }) => ({
    type,
    index,
  })
)
export const handleTabSeqChange = createAction(
  HANDLE_TAB_SEQ_CHANGE,
  ({ oldIndex, newIndex }) => ({
    oldIndex,
    newIndex,
  })
)
export const handleSCAdd = createAction(HANDLE_SC_ADD, name => ({
  name,
}))

export const loadSolutionCards = createAction(LOAD_SOLUTION_CARDS, cards => ({
  cards,
}))
export const addSCWidget = createAction(ADD_SC_WIDGET, (card, widget) => ({
  card,
  widget,
}))
export const toggleSCWidgetStatic = createAction(
  TOGGLE_SC_WIDGET_STATIC,
  (cardId, widgetId) => ({
    cardId,
    widgetId,
  })
)
export const removeSCWidget = createAction(REMOVE_SC_WIDGET, (cardId, srn) => ({
  cardId,
  srn,
}))

export const saveLayout = createAction(SAVE_LAYOUT, card => ({ card }))
export const saveLayoutSuccess = createAction(SAVE_LAYOUT_SUCCESS)
export const removeWidgetSuccess = createAction(REMOVE_WIDGET_SUCCESS)
export const addSolutionCardSuccess = createAction(ADD_SOLUTION_CARD_SUCCESS)
export const deleteSolutionCardSuccess = createAction(
  DELETE_SOLUTION_CARD_SUCCESS
)
export const addWidgetSuccess = createAction(ADD_WIDGET_SUCCESS)

export const updateEditWidget = createAction(
  UPDATE_EDIT_WIDGET,
  (card, widget) => ({
    card,
    widget,
  })
)

export const updateWidgetSuccess = createAction(UPDATE_WIDGET_SUCCESS)
export const updateSolutionCard = createAction(UPDATE_SOLUTION_CARD)
export const updateSolutionCardSuccess = createAction(
  UPDATE_SOLUTION_CARD_SUCCESS
)
export const startUpdateScPoll = createAction(START_UPDATE_SC_POLL)
export const stopUpdateScPoll = createAction(STOP_UPDATE_SC_POLL)
export const setShowCreateWidgetModal = createAction(
  SET_SHOW_CREATE_WIDGET_MODAL
)
export const copySolutionCard = createAction(COPY_SOLUTION_CARD)
export const copySolutionCardSuccess = createAction(COPY_SOLUTION_CARD_SUCCESS)
