/**
 *
 * RegionsMap
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { scaleLinear } from 'd3-scale'
import Color from 'color'
import Button from 'components/Button'
import geoObj from 'assets/world-50m.json'
import Icon from 'components/Icon'

import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
  Markers,
  Marker,
} from 'react-simple-maps'
import { getCoordConf } from 'utils/widgetUtils'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

class RegionsMap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      zoom: 1,
      activeMarker: null,
      selectedMarker: null,
      itemsHover: false,
    }
    this.styles = {
      mapContainer: {
        overflow: 'hidden',
      },
      mapGrid: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        overflow: 'hidden',
      },
      tooltip: {
        fill: '#fff',
        filter: 'url(#shadow)',
      },
      zoomButton: {
        color: 'white',
        padding: '0.5rem 1rem',
      },
      zoomBtnCont: {
        bottom: '0px',
        right: '0px',
        position: 'absolute',
      },
      legendHighlight: {
        color: this.props.theme.primary,
        borderRadius: '1em',
        cursor: 'pointer',
      },
      legendHeader: {
        paddingLeft: '5px',
        backgroundColor: this.props.theme.neutralLight,
      },
      legend: {
        overflowY: 'auto',
        padding: '0.5em',
        height: '100%',
      },
      svg: {
        fontFamily: '"Font Awesome 5 Pro"',
        fontWeight: 300,
        userSelect: 'none',
        cursor: 'pointer',
      },
      svgItems: {
        fontSize: '11px',
        cursor: 'pointer',
      },
      svgItemsHighlight: {
        fontSize: '11px',
        cursor: 'pointer',
        color: this.props.theme.primary,
      },
    }
  }

  handleZoomIn = () => {
    this.setState(currentState => ({
      zoom: currentState.zoom * 1.25,
    }))
  }

  handleZoomOut = () => {
    this.setState(currentState => ({
      zoom: currentState.zoom / 1.25,
    }))
  }

  handleMarkerHover = value => {
    this.setState({
      activeMarker: value,
    })
  }

  handleMarkerExit = value => {
    if (value === this.state.activeMarker) {
      this.setState({
        activeMarker: null,
      })
    }
  }

  handleOnClickRegion = value => {
    if (this.state.selectedMarker === value) {
      this.setState({ selectedMarker: null })
    } else {
      this.setState({ selectedMarker: value })
    }
  }

  handleDeselect = () => {
    this.setState({ selectedMarker: null })
  }

  getMarker = (value, count, scale) => {
    const coordConf = getCoordConf(value, this.props.theme)
    const coordinates = coordConf.coordinates

    if (coordinates) {
      const hovered = this.state.activeMarker === value
      return (
        <Marker
          key={value}
          marker={{ coordinates }}
          onMouseEnter={this.handleMarkerHover.bind(this, value)}
          onMouseLeave={this.handleMarkerExit.bind(this, value)}
          onClick={this.handleOnClickRegion.bind(this, value)}
        >
          <circle
            cursor="pointer"
            id={value}
            cx={0}
            cy={0}
            r={scale(count)}
            fill={
              hovered
                ? Color(this.props.theme.emphasis)
                    .alpha(0.5)
                    .rgb()
                    .string()
                : Color(coordConf.color)
                    .alpha(0.8)
                    .rgb()
                    .string()
            }
            stroke={hovered ? this.props.theme.emphasis : coordConf.color}
            strokeWidth="2"
          />
        </Marker>
      )
    } else {
      return null
    }
  }

  getRegionSvgLabel = title => {
    const limit = 21 // the legal age of booze in america
    return title.length > limit ? `${title.substr(0, limit - 1)}...` : title
  }

  enterItems = () => {
    this.setState({ itemsHover: true })
  }

  leaveItems = () => {
    this.setState({ itemsHover: false })
  }

  getToolTip = (value, count) => {
    const coordConf = getCoordConf(value, this.props.theme)
    const coordinates = coordConf.coordinates

    if (!coordinates) {
      return null
    }

    return (
      <Marker key={`${value}-tooltip`} marker={{ coordinates }}>
        <g transform="scale(2,2)">
          <defs>
            <filter id="shadow">
              <feDropShadow
                dx="1"
                dy="2"
                stdDeviation="2"
                floodColor="#888"
                floodOpacity="0.7"
              />
            </filter>
          </defs>
          <rect
            x="-25"
            y="-60"
            width="150"
            height="60"
            rx="5"
            ry="5"
            style={this.styles.tooltip}
          />
          <polygon points="-5,-10 5,-10 0,-4" fill="#fff" />
          <tspan x="0" y="0" dy="1.2em" style={{ fontSize: '11px' }} />

          <text
            onClick={this.handleDeselect}
            style={this.styles.svg}
            x="112"
            y="-46"
          >
            &#xf00d;
          </text>
          <text x="-25" y="-55">
            <tspan
              x="-20"
              dy="1.2em"
              style={
                this.state.itemsHover
                  ? this.styles.svgItemsHighlight
                  : this.styles.svgItems
              }
              onClick={this.props.onClickRegion.bind(this, value)}
              onMouseEnter={this.enterItems}
              onMouseLeave={this.leaveItems}
              key="svgItemKey"
            >
              {count} items
            </tspan>
            <tspan x="-20" dy="1em" style={{ overflowX: 'hidden' }}>
              {this.getRegionSvgLabel(coordConf.label)}
            </tspan>
            <tspan
              x="-20"
              dy="1.2em"
              style={{ fontSize: '9px', fill: coordConf.color }}
            >
              {coordConf.type}
            </tspan>
          </text>
        </g>
      </Marker>
    )
  }

  renderGeography = (geographies, projection) => {
    const baseStyle = {
      fill: this.props.theme.neutralLight,
      stroke: this.props.theme.neutralMedium,
      strokeWidth: 0.5,
      outline: 'none',
    }

    return geographies
      .filter(geo => geo.properties.NAME !== 'Antarctica')
      .map((geography, i) => (
        <Geography
          key={`geography-${i}`}
          geography={geography}
          projection={projection}
          style={{
            default: baseStyle,
            hover: baseStyle,
            pressed: baseStyle,
          }}
        />
      ))
  }

  getMarkers = () => {
    const { regionCounts } = this.props
    const counts = _.toArray(regionCounts).sort((a, b) => a - b)
    const maxCount = counts.pop()

    const scale = scaleLinear()
      .domain([0, maxCount])
      .range([4, 20])

    scale.clamp(true)

    const selectedMarkerCount = this.state.selectedMarker
      ? regionCounts[this.state.selectedMarker]
      : null

    const markers = Object.keys(regionCounts)
      .map(key => this.getMarker(key, regionCounts[key], scale))
      .filter(node => !!node)

    if (this.state.selectedMarker) {
      markers.push(
        this.getToolTip(this.state.selectedMarker, selectedMarkerCount)
      )
    }

    return <Markers>{markers}</Markers>
  }

  getLegend = () => {
    let { regionCounts } = this.props

    const all = Object.keys(regionCounts)
      .filter(key => key !== 'null')
      .sort()
    let byType = {}

    all.forEach(region => {
      const conf = getCoordConf(region)
      if (!byType[conf.type]) {
        byType[conf.type] = []
      }
      byType[conf.type].push(region)
    })

    let legend = {}

    Object.keys(byType).forEach(cloudType => {
      if (!legend[cloudType]) {
        legend[cloudType] = []
      }

      byType[cloudType].forEach(region => {
        legend[cloudType].push(
          <div
            style={
              this.state.activeMarker === region
                ? this.styles.legendHighlight
                : {}
            }
            onPointerEnter={() => this.handleMarkerHover(region)}
            onPointerOut={() => this.handleMarkerExit(region)}
            onClick={() => this.handleOnClickRegion(region)}
          >
            {_.startCase(region)}
          </div>
        )
      })
    })

    return (
      <div style={this.styles.legend}>
        {Object.keys(legend).map(type => {
          return (
            <div key={`regionKey-${type}`}>
              <div style={this.styles.legendHeader}>{type}</div>
              <div style={{ paddingLeft: '5px' }}>
                {legend[type].map(thisLeg => {
                  return thisLeg
                })}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    let wwjd =
      Math.abs(nextProps.size.width - this.props.size.width) > 10 ||
      Math.abs(nextProps.size.height - this.props.size.height) > 10
    if (
      wwjd ||
      nextState.zoom !== this.state.zoom ||
      this.state.activeMarker !== nextState.activeMarker ||
      this.state.selectedMarker !== nextState.selectedMarker ||
      this.state.itemsHover !== nextState.itemsHover
    ) {
      return true
    } else {
      return false
    }
  }

  render() {
    let sizes = {}

    if (!this.props.size.height || !this.props.size.width) {
      sizes = {
        height: '100%',
        width: '100%',
      }
    } else {
      sizes = {
        height: `${this.props.size.height}px`,
        width: `${this.props.size.width * 0.8}px`,
      }
    }

    return (
      <div
        style={{
          height: '100%',
          display: 'grid',
          gridTemplateRows: '1fr auto',
        }}
      >
        <div style={this.styles.mapGrid}>
          {this.getLegend()}
          <ComposableMap style={sizes}>
            <ZoomableGroup zoom={this.state.zoom} center={[0, 30]}>
              <Geographies geography={geoObj}>
                {this.renderGeography}
              </Geographies>
              {this.getMarkers()}
            </ZoomableGroup>
          </ComposableMap>
        </div>
        <div style={this.styles.zoomBtnCont}>
          <Button
            style={{
              ...this.styles.zoomButton,
              marginRight: '0.5rem',
            }}
            onClick={this.handleZoomIn}
            color="primary"
          >
            <Icon fa name="plus" size="sm" />
          </Button>
          <Button
            style={this.styles.zoomButton}
            onClick={this.handleZoomOut}
            color="primary"
          >
            <Icon fa name="minus" size="sm" />
          </Button>
        </div>
      </div>
    )
  }
}

RegionsMap.defaultProps = {
  onClickRegion: () => {},
}

RegionsMap.propTypes = {
  onClickRegion: PropTypes.func.isRequired,
  regionCounts: PropTypes.objectOf(PropTypes.number),
  theme: themeShape,
  size: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
}

export default themeable(RegionsMap)
