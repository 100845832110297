import React from 'react'
import PropTypes from 'prop-types'
import ContainerDimensions from 'react-container-dimensions'
import SizedChart from './SizedChart'

export class Chart extends React.Component {
  render() {
    return (
      <div
        style={
          this.props.type !== 'radialBar'
            ? { height: '100%', width: '100%' }
            : {}
        }
      >
        {this.props.type !== 'radialBar' ? (
          <ContainerDimensions>
            <SizedChart {...this.props} />
          </ContainerDimensions>
        ) : (
          <SizedChart {...this.props} />
        )}
      </div>
    )
  }
}

Chart.propTypes = {
  yTitle: '',
}

Chart.propTypes = {
  yTitle: PropTypes.string,
  yLabels: PropTypes.arrayOf(PropTypes.string),
  yValues: PropTypes.arrayOf(PropTypes.number),
  onClickPoint: PropTypes.func,
  type: PropTypes.oneOf(['bar', 'pie', 'donut', 'spark', 'radialBar'])
    .isRequired,
}

export default Chart
