import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'

import TabBar from 'components/TabBar'
import ImmutablePureComponent from 'components/ImmutablePureComponent'
import { selectUserProfile } from 'containers/UserProfileData/selectors'
import { handleTabSeqChange } from 'containers/SolutionCenter/actions'

import {
  selectSortedCards,
  selectSelectedCard,
  selectSelectedCardIndex,
} from './selectors'

export class SolutionCenterTabs extends ImmutablePureComponent {
  getTabs = () => {
    return this.props.solutionCards
      .map(card => {
        return Map({
          id: card.get('srn'),
          text: card.get('name'),
          date: card.get('createdDate'),
        })
      })
      .toJS()
  }

  handleChangeTab = index => {
    const selectedSolutionCard = this.props.solutionCards.get(index) || Map()
    this.props.setSolutionCard(selectedSolutionCard.get('srn'))
  }

  render() {
    if (this.props.solutionCards.isEmpty()) {
      return null
    }

    return (
      <TabBar
        changeTab={this.handleChangeTab}
        activeTab={this.props.selectedCardIndex}
        tabs={this.getTabs()}
        showModal={false}
        showArrow={true}
      />
    )
  }
}

SolutionCenterTabs.propTypes = {
  solutionCards: ImmutablePropTypes.list,
}

const mapStateToProps = createStructuredSelector({
  userProfile: selectUserProfile,
  selectedSolutionCard: selectSelectedCard,
  selectedCardIndex: selectSelectedCardIndex,
  solutionCards: selectSortedCards,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      handleTabSeqChange,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

export default compose(withConnect)(SolutionCenterTabs)
