import { createSelector } from 'reselect'
import { List } from 'immutable'
import { WIDGET_TYPES } from 'appConstants'

export const selectSolutionCenterWidgetsDomain = state =>
  state.get('SolutionCenterWidgets')

export const selectSavingWidget = createSelector(
  selectSolutionCenterWidgetsDomain,
  state => state.get('savingWidget', false)
)

const getMinWidgetHeight = widgetType => {
  switch (widgetType) {
    case WIDGET_TYPES.LIST:
      return 6
    case WIDGET_TYPES.TABLE:
      return 5
    case WIDGET_TYPES.REGIONS:
      return 8
    case WIDGET_TYPES.PIE_CHART:
      return 6
    case WIDGET_TYPES.BAR_CHART:
      return 6
    default:
      return 4
  }
}

const getMinWidgetWidth = widgetType => {
  switch (widgetType) {
    case WIDGET_TYPES.LIST:
      return 6
    case WIDGET_TYPES.TABLE:
      return 12
    case WIDGET_TYPES.GAUGE:
      return 8
    case WIDGET_TYPES.REGIONS:
      return 15
    case WIDGET_TYPES.PIE_CHART:
      return 6
    case WIDGET_TYPES.BAR_CHART:
      return 6
    default:
      return 4
  }
}

export const selectCard = (state, props) => props.card
export const selectWidgets = createSelector(
  selectCard,
  card => card.get('widgets', List()).toJS() || []
)

export const selectLayout = createSelector(
  selectWidgets,
  widgets => {
    return widgets
      .map(widget => {
        if (widget.widgetSize) {
          return {
            w: widget.widgetSize[0],
            h: widget.widgetSize[1],
            x: widget.widgetLocation[0],
            y: widget.widgetLocation[1],
            i: widget.sid,
            static: widget.static,
            moved: false,
            isResizable: undefined,
            isDraggable: undefined,
            maxH: undefined,
            maxW: undefined,
            minH: getMinWidgetHeight(widget.type),
            minW: getMinWidgetWidth(widget.type),
          }
        }
      })
      .filter(widget => !!widget)
  }
)
