import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'
import { Map } from 'immutable'
import _ from 'lodash'

import ComplianceWidget from 'components/ComplianceWidget'
import FormLabel from 'components/FormLabel'
import SelectBar from 'components/SelectBar'
import PreviewContainer from './PreviewContainer'

const styles = {
  wrapperWithPreview: {
    display: 'grid',
    gridTemplateColumns: '45% 1fr',
    gridColumnGap: '2em',
  },
  compliancePreview: { minWidth: '225px', height: '350px', maxHeight: '350px' },
}

class ComplianceWidgetConfig extends React.Component {
  componentDidUpdate(oldProps) {
    if (
      _.get(this.props.widgetOptions.controlFramework, 'value') !==
        _.get(oldProps.widgetOptions.controlFramework, 'value') ||
      this.props.widgetTitle !== oldProps.widgetTitle
    ) {
      this.updateValidity()
    }
  }

  updateValidity = () => {
    const hasControlFrameWork = !!this.props.widgetOptions.controlFramework
    const hasTitle = this.props.widgetTitle

    const valid = hasControlFrameWork && hasTitle
    this.props.setValidity(valid)
  }

  handleSelectControlFrameWork = val => {
    this.props.setWidgetOptions({
      controlFramework: val,
    })

    this.handleSuggestedTitle(this.getSuggestedTitle(val))
  }

  setTitle = e => {
    this.props.setWidgetTitle(e.target.value)
  }

  setDescription = e => {
    this.props.setWidgetOptions({
      description: e.target.value,
    })
  }

  handleSuggestedTitle = title => this.props.setWidgetTitle(title)

  getSuggestedTitle = selection => {
    if (selection !== null) {
      const message = `${selection.label} Compliance`
      return message
    }
  }

  render() {
    const controlFrameWorkOptions = this.props.controlGroupOptions
      .filter(cf => cf.get('enabled'))
      .toList()
      .map(cf =>
        Map({
          value: cf.get('srn'),
          label: cf.get('title'),
        })
      )
      .toJS()

    return (
      <div style={this.props.previewWidget ? styles.wrapperWithPreview : {}}>
        {this.props.previewWidget && (
          <PreviewContainer>
            <div style={styles.compliancePreview}>
              <ComplianceWidget
                data={[81]}
                title={
                  this.props.widgetTitle ? (
                    this.props.widgetTitle
                  ) : (
                    <span style={{ fontStyle: 'italic' }}>Widget Title</span>
                  )
                }
              />
            </div>
          </PreviewContainer>
        )}

        <div>
          <FormLabel
            label="Control Framework"
            for="controlFramework"
            required={true}
          />
          <SelectBar
            isClearable
            value={_.get(this.props.widgetOptions.controlFramework, 'value')}
            options={controlFrameWorkOptions}
            onChange={this.handleSelectControlFrameWork}
          />
          <FormLabel label="Title" for="title" required={true} />
          <Input
            type="text"
            name="widgetTitle"
            id="widgetTitle"
            placeholder="Title..."
            value={this.props.widgetTitle || ''}
            onChange={this.setTitle}
          />

          <FormLabel label="Description" for="widgetDescription" />
          <Input
            id="widgetDescription"
            type="textarea"
            name="widgetDescription"
            onChange={this.setDescription}
            value={this.props.widgetOptions.description || ''}
          />
        </div>
      </div>
    )
  }
}

ComplianceWidgetConfig.propTypes = {
  previewWidget: PropTypes.bool,
  setWidgetOptions: PropTypes.func,
  setWidgetTitle: PropTypes.func,
  setValidity: PropTypes.func,
  widgetOptions: PropTypes.shape({
    controlFramework: PropTypes.object,
    description: PropTypes.string,
  }),

  widgetTitle: PropTypes.string,
  controlGroupOptions: PropTypes.object,
}

export default ComplianceWidgetConfig
