/*
 * SparkWidget Messages
 * This contains all the text for the SparkWidget component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  header: {
    id: 'app.components.SparkWidget.header',
    defaultMessage: 'This is the SparkWidget component !',
  },
  noHistory: {
    id: 'app.components.SparkWidget.noHistory',
    defaultMessage: 'No History to show',
  },
  total: {
    id: 'app.components.SparkWidget.total',
    defaultMessage: 'Total: ',
  },
  logs: {
    id: 'app.components.SparkWidget.logs',
    defaultMessage: 'Logs',
  },
})
