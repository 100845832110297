import { createSelector } from 'reselect'
import { Map, List } from 'immutable'

export const selectSolutionCenterFilterPanelDomain = state =>
  state.get('solutionCenterFilterPanel') || Map()

export const selectSelectedPivots = createSelector(
  selectSolutionCenterFilterPanelDomain,
  state => state.get('selectedPivots') || Map()
)

export const selectTagFilters = createSelector(
  selectSolutionCenterFilterPanelDomain,
  state => state.get('tagFilters') || List()
)
