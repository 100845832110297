/**
 *
 * AlertWidget
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'
import { Query } from 'react-apollo'
import { exists } from 'utils/sonraiUtils'
import Card, { TopTitle, CardBody } from 'components/Card'
import moment from 'moment'
import _ from 'lodash'
import gql from 'graphql-tag'
import WidgetCard from 'components/WidgetCard'
import { ALERT_LEVELS, CONTROL_FRAMEWORKS } from '../../appConstants'
import SingleAlert from './SingleAlert'
import messages from './messages'
import { FormattedMessage } from 'react-intl'
import { Map } from 'immutable'

const styles = {
  header: { fontSize: '22px', fontWeight: '300' },
  alertsMainContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    maxHeight: '5rem',
    margin: '0.5rem 0rem',
  },
}

class AlertWidget extends React.Component {
  setLevel = policy => {
    switch (policy.alertingLevel) {
      case ALERT_LEVELS.INFO:
        return 1
      case ALERT_LEVELS.WARN:
        return 2
      case ALERT_LEVELS.ERROR:
        return 3
      default:
        return 3
    }
  }

  getData = data => {
    if (exists(data)) {
      if (exists(data.PolicyEvalLogs)) {
        const logs = data.PolicyEvalLogs.items.filter(item => !item.pass)
        if (!_.isEmpty(logs)) {
          return logs
            .map(policy => ({
              id: policy.policyId,
              key: policy.id,
              // default title just put an empty tick mark
              name: (policy.controlPolicy || { title: '-' }).title,
              count: policy.count || '-', // This is here until the count: null bug is fixed :)
              time: moment.unix(policy.time).fromNow(),
              level: this.setLevel(policy),
            }))
            .sort((a, b) => b.level - a.level)
            .sort((a, b) => new Date(b.time - new Date(a.time)))
        } else {
          return []
        }
      }
    } else []
  }

  renderAlerts = alerts => {
    if (exists(alerts)) {
      if (_.isEmpty(alerts)) {
        return (
          <div>
            {' '}
            <FormattedMessage {...messages.noData} />{' '}
          </div>
        )
      } else {
        return (
          <div style={styles.alertsMainContainer}>
            {alerts.map(alert => (
              <SingleAlert
                key={alert.key}
                id={alert.key}
                onClickAlert={this.props.onClickAlert}
                count={alert.count}
                name={alert.name}
                level={alert.level}
                time={alert.time}
              />
            ))}
          </div>
        )
      }
    }
  }

  getQuery = () => {
    const { widgetOptions } = this.props.resultLayout
    if (exists(widgetOptions)) {
      if (widgetOptions.alert) {
        if (
          widgetOptions.alert.value !==
          CONTROL_FRAMEWORKS.ALL_CONTROL_FRAMEWORKS
        ) {
          const query = gql`
            query getAlertsByControlFramework {
              PolicyEvalLogs(where: { 
                  controlFrameworkId: "${widgetOptions.alert.value}", 
                  collapse: true
              }) {
                count
                items {
                  policyId
                  controlPolicy {
                    title
                    srn
                  }
                  pass
                  time
                  alertingLevel
                  count
                  srnList
                  id
                }
              }
            }
          `
          return query
        } else {
          return gql`
            query getAllAlerts {
              PolicyEvalLogs(where: { collapse: true }) {
                count
                items {
                  policyId
                  controlPolicy {
                    title
                    srn
                  }
                  pass
                  time
                  alertingLevel
                  count
                  srnList
                  id
                }
              }
            }
          `
        }
      }
    }
  }

  renderHeader = () => {
    return (
      <div>
        <div style={styles.header}>{this.props.title}</div>
      </div>
    )
  }

  getSearchId = () => {
    //Because it doesn't support saved or  sonrai searches - just control frameworks
    return Map({
      uiSearch: null,
      advancedSearch: null,
    })
  }
  render() {
    if (this.props.data === undefined) {
      const searchId = this.getSearchId()
      const query = this.getQuery()
      if (!query) {
        return (
          <WidgetCard
            searchId={searchId}
            loading={true}
            title={this.props.title}
            allowDelete={this.props.allowDelete}
            allowUpdate={this.props.allowUpdate}
            onRemove={this.props.onRemove}
            onEdit={this.props.onEdit}
          />
        )
      }

      return (
        <Query
          query={query}
          notifyOnNetworkStatusChange
          pollInterval={300000}
          key={JSON.stringify(query)}
          context={{
            queryName: `alertWidget${_.snakeCase(this.props.title)}`,
          }}
        >
          {({ error, loading, data, networkStatus, refetch }) => {
            const alerts = loading ? null : this.getData(data)

            return (
              <WidgetCard
                searchId={searchId}
                createdBy={this.props.widget && this.props.widget.createdBy}
                loading={loading}
                disableToolbar={this.props.disableToolbar}
                allowDelete={this.props.allowDelete}
                allowUpdate={this.props.allowUpdate}
                onEdit={this.props.onEdit}
                error={error}
                refetch={refetch}
                onRemove={this.props.onRemove}
                toggleStatic={this.props.toggleStatic}
                static={this.props.static}
                networkStatus={networkStatus}
                title={this.props.title}
                description={_.get(this.props.resultLayout, [
                  'widgetOptions',
                  'description',
                ])}
              >
                <TopTitle>{this.renderHeader()}</TopTitle>
                <CardBody
                  style={{
                    alignItems: !_.isEmpty(alerts) ? 'flex-start' : 'center',
                    overflow: 'auto',
                  }}
                >
                  {this.renderAlerts(alerts)}
                </CardBody>
              </WidgetCard>
            )
          }}
        </Query>
      )
    } else {
      return (
        <Card>
          {this.props.title && <TopTitle>{this.renderHeader()}</TopTitle>}
          <CardBody
            style={{
              alignItems: !_.isEmpty(this.props.data) ? 'flex-start' : 'center',
              overflow: 'auto',
            }}
          >
            {this.renderAlerts(this.props.data)}
          </CardBody>
        </Card>
      )
    }
  }
}

AlertWidget = Radium(AlertWidget) // eslint-disable-line

AlertWidget.propTypes = {
  title: PropTypes.string.isRequired,
  onClickAlert: PropTypes.func.isRequired,
  data: PropTypes.object,
  resultLayout: PropTypes.object,
  allowDelete: PropTypes.bool,
  allowUpdate: PropTypes.bool,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  static: PropTypes.bool,
  disableToolbar: PropTypes.bool,
  toggleStatic: PropTypes.func,
  widget: PropTypes.object,
}

export default AlertWidget
