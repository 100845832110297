import { defineMessages } from 'react-intl'

export default defineMessages({
  filtersTitle: {
    id: 'app.containers.SolutionCenterFilterPanel.filtersTitle',
    defaultMessage: 'Filters',
  },
  dateRange: {
    id: 'app.containers.SolutionCenterFilterPanel.dateRange',
    defaultMessage: 'Date Range',
  },
  days1: {
    id: 'app.containers.SolutionCenterFilterPanel.days1',
    defaultMessage: 'Since 24 Hours',
  },
  days7: {
    id: 'app.containers.SolutionCenterFilterPanel.days7',
    defaultMessage: 'Since 7 Days Ago',
  },
  days30: {
    id: 'app.containers.SolutionCenterFilterPanel.days30',
    defaultMessage: 'Since 30 Days Ago',
  },
  days90: {
    id: 'app.containers.SolutionCenterFilterPanel.days90',
    defaultMessage: 'Since 90 Days Ago',
  },
  accountFilterTitle: {
    id: 'app.containers.SolutionCenterFilterPanel.accountFilterTitle',
    defaultMessage: 'Account',
  },
  tagFilterTitle: {
    id: 'app.containers.SolutionCenterFilterPanel.tagFilterTitle',
    defaultMessage: 'Tag Set',
  },
})
