/**
 *
 * ListWidget
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import { Map } from 'immutable'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import TextLink from 'components/TextLink'
import Card, { TopTitle, CardBody } from 'components/Card'
import WidgetCard from 'components/WidgetCard'
import {
  getFields,
  getTableSavedSearchQuery,
  getSonraiSearchQuery,
  getSearchCard,
  hasSonraiSearch,
  getSonraiSearchData,
  getSelection,
  getWidgetResultsExplorerSavedSearchQuery,
} from 'query-builder'
import { getTypeFromSrn } from 'utils/graphDataUtils'
import { getSearchIdForSonraiSearches } from 'utils/sonraiUtils'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import { queryHasPivotFilter } from 'query-builder'
import EmptyWidgetResults from 'components/EmptyWidgetResults'
import { exists } from 'utils/sonraiUtils'

const styles = {
  title: {
    fontSize: '20px',
    fontWeight: '300',
    borderBottom: '1px solid #eee',
    cursor: 'pointer',
    marginRight: '0px',
  },
  containerStyle: {
    overflow: 'auto',
  },
  listContainer: {
    alignItems: 'start',
  },
  list: {
    margin: 0,
    padding: 0,
    width: '100%',
  },
  listItem: {
    listStyleType: 'none',
    padding: '0.5em 1em',
    fontSize: '14px',
  },
  listNumber: {
    color: '#888',
    fontSize: '1.2em',
  },
  noitems: {
    color: '#888',
    textAlign: 'center',
    marginTop: '1em',
  },
}

class ListWidget extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hoveredId: null,
    }
  }

  renderListItems = data => {
    if (!data || data.length === 0) {
      return <EmptyWidgetResults />
    }

    let numberOfItems =
      this.props.resultLayout &&
      exists(this.props.resultLayout.widgetOptions.items)
        ? this.props.resultLayout.widgetOptions.items
        : data.length

    let cutArr = data.slice(0, numberOfItems)
    return cutArr.map((item, index) => (
      <li
        key={index}
        onMouseEnter={this.setHover.bind(this, index)}
        onMouseLeave={this.setHover.bind(this, null)}
        style={styles.listItem}
        onClick={this.handleOnClick.bind(this, item.srn)}
      >
        <span style={styles.listNumber}> {index + 1}. </span>
        <span
          style={{
            transition: 'color 0.05s ease-out',
            ...(this.state.hoveredId === index && item.srn
              ? { color: this.props.theme.emphasis }
              : {}),
          }}
        >
          {item.label}
        </span>
      </li>
    ))
  }

  setHover = newId => {
    this.setState({
      hoveredId: newId,
    })
  }

  handleOnClick = srn => {
    if (srn && this.props.onClickNodeView) {
      this.props.onClickNodeView(srn, getTypeFromSrn(srn))
    }
  }

  getQuery = () => {
    if (hasSonraiSearch(this.props.options, 'list')) {
      return getSonraiSearchQuery(this.props.options.sonraiSearches.list)
    } else {
      return getTableSavedSearchQuery(
        this.props.getQueryBuilder,
        this.props.savedSearches,
        this.props.resultLayout.indexedSearches.list,
        { limit: this.props.resultLayout.widgetOptions.items }
      )
    }
  }

  getData = data => {
    if (!_.isEmpty(this.props.options.sonraiSearches.list)) {
      return this.getDataFromSonraiSearch(data)
    } else {
      const fields = getFields(
        this.props.savedSearches,
        this.props.resultLayout.indexedSearches.list
      ).toJS()
      const searchCard = getSearchCard(this.props.resultLayout, 'list')
      const selection = getSelection(fields, searchCard, 'items')
      const savedSearchResults = _.get(data, selection) || []

      const property = this.props.resultLayout.widgetOptions.properties.value

      return savedSearchResults.map(item => ({
        label: item[property],
        srn: item.srn,
      }))
    }
  }

  getDataFromSonraiSearch = data => {
    const searchData = getSonraiSearchData(data)
    if (searchData.items) {
      const property = this.props.resultLayout.widgetOptions.properties.value
      return searchData.items.map(item => ({
        label: item[property],
        srn: item.srn,
      }))
    }
  }

  getSearchName = () => {
    if (!this.props.savedSearches) {
      return
    }

    return hasSonraiSearch(this.props.options, 'list')
      ? this.props.options.sonraiSearches.list
      : this.props.savedSearches.getIn([
          this.props.resultLayout.indexedSearches.list,
          'name',
        ])
  }

  getExploreResultsQuery = () => {
    if (hasSonraiSearch(this.props.options, 'list')) {
      return getSonraiSearchQuery(this.props.options.sonraiSearches.list)
    } else {
      return getWidgetResultsExplorerSavedSearchQuery(
        this.props.getQueryBuilder,
        this.props.savedSearches,
        this.props.resultLayout.indexedSearches.list
      )
    }
  }

  handleOnClickSearch = () => {
    this.props.onClickSearch({
      savedSearchId: this.props.resultLayout.indexedSearches.list,
      sonraiSearchName: this.props.options.sonraiSearches.list,
      searchTitle: this.props.title,
    })
  }

  getSearchId = () => {
    const { options, sonraiSearches, resultLayout } = this.props
    let searchObj = Map({ uiSearch: null, advancedSearch: null })
    if (hasSonraiSearch(options, 'list')) {
      const searches = getSearchIdForSonraiSearches(options, sonraiSearches)
      searchObj = searchObj.set('advancedSearch', searches)
    } else {
      searchObj = searchObj.set('uiSearch', resultLayout.indexedSearches.list)
    }
    return searchObj
  }

  render() {
    if (this.props.data === undefined) {
      const queryConfig = this.getQuery()
      if (!queryConfig.gqlStatement) {
        return <WidgetCard loading={true} title={this.props.title} />
      }
      const searchId = this.getSearchId()
      const filtered = queryHasPivotFilter(queryConfig.gqlStatement)

      return (
        <Query
          query={gql`
            ${queryConfig.gqlStatement}
          `}
          notifyOnNetworkStatusChange
          pollInterval={300000}
          variables={queryConfig.variables}
          context={{
            queryName: `listWidget_${_.snakeCase(this.props.title)}`,
          }}
        >
          {({ error, data, refetch, networkStatus }) => {
            const values = this.getData(data)
            return (
              <WidgetCard
                searchId={searchId}
                disableToolbar={this.props.disableToolbar}
                allowDelete={this.props.allowDelete}
                allowUpdate={this.props.allowUpdate}
                onRemove={this.props.onRemove}
                onEdit={this.props.onEdit}
                toggleStatic={this.props.toggleStatic}
                static={this.props.static}
                refetch={refetch}
                networkStatus={networkStatus}
                title={this.props.title}
                filtered={filtered}
                error={error}
                containerStyle={styles.containerStyle}
                description={_.get(this.props.resultLayout, [
                  'widgetOptions',
                  'description',
                ])}
                createdBy={this.props.widget && this.props.widget.createdBy}
              >
                <TopTitle style={styles.title}>
                  <TextLink
                    title={this.getSearchName()}
                    onClick={this.handleOnClickSearch}
                  >
                    {this.props.title}
                  </TextLink>
                </TopTitle>
                <CardBody
                  style={
                    values && values.length > 0 ? styles.listContainer : {}
                  }
                >
                  <ol style={styles.list}>{this.renderListItems(values)}</ol>
                </CardBody>
              </WidgetCard>
            )
          }}
        </Query>
      )
    } else {
      const items =
        this.props.data.length > 0 && typeof this.props.data[0] === 'object'
          ? this.props.data
          : this.props.data.map(data => ({ label: data, srn: null }))
      return (
        <Card>
          <TopTitle style={styles.title}>
            <TextLink
              title={this.getSearchName()}
              onClick={this.handleOnClickSearch}
            >
              {this.props.title}
            </TextLink>
          </TopTitle>{' '}
          <CardBody
            style={this.props.data.length > 0 ? styles.listContainer : {}}
          >
            <ol style={styles.list}>{this.renderListItems(items)}</ol>
          </CardBody>
        </Card>
      )
    }
  }
}

ListWidget.propTypes = {
  allowDelete: PropTypes.bool,
  allowUpdate: PropTypes.bool,
  disableToolbar: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  theme: themeShape,
  toggleStatic: PropTypes.func,
  savedSearches: ImmutablePropTypes.map,
  static: PropTypes.bool,
  sonraiSearches: ImmutablePropTypes.iterable.isRequired,
  onClickNodeView: PropTypes.func,
  options: PropTypes.object,
  resultLayout: PropTypes.object,
  getQueryBuilder: PropTypes.func,
  data: PropTypes.array,
  onClickSearch: PropTypes.func,
  onEdit: PropTypes.func,
  widget: PropTypes.object,
}

export default themeable(ListWidget)
