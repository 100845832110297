/**
 *
 * RatioWidget
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Query } from 'react-apollo'
import _ from 'lodash'
import gql from 'graphql-tag'
import TextLink from 'components/TextLink'
import Card, { BottomTitle, CardBody } from 'components/Card'
import WidgetCard from 'components/WidgetCard'
import { queryHasPivotFilter } from 'query-builder'
import { getSearchIdForSonraiSearches } from 'utils/sonraiUtils'
import { Map } from 'immutable'
import {
  getFields,
  getSearchCard,
  getSelection,
  hasSonraiSearch,
  combineQueries,
  getBareSearchQueryString,
} from 'query-builder'

const styles = {
  numerator: {
    fontWeight: '300',
  },
  denominator: {
    color: '#555',
  },
  number: {
    textAlign: 'center',
  },
  percentNumber: {
    fontWeight: '300',
  },
  averageNumber: {
    fontWeight: '300',
  },
  percentSign: {
    fontSize: '80%',
    color: '#555',
  },
  searchLink: {
    fontWeight: '300',
    cursor: 'pointer',
    // marginTop: '-0.5em',
  },
}

class RatioWidget extends React.Component {
  getQuery = () => {
    const numQueryConfig = this.getQueryConfig('num')
    const denomQueryConfig = this.getQueryConfig('denom')

    return combineQueries('ratioQuery', numQueryConfig, denomQueryConfig)
  }

  getQueryConfig = key => {
    if (hasSonraiSearch(this.props.options, key)) {
      return {
        gqlStatement: `
          ${key}: ${getBareSearchQueryString(
          this.props.options.sonraiSearches[key]
        )}`,
      }
    } else {
      return this.getSavedQuery(key)
    }
  }

  getSavedQuery = searchIndex => {
    const { getQueryBuilder, savedSearches, resultLayout } = this.props

    const fields = getFields(
      savedSearches,
      resultLayout.indexedSearches[searchIndex]
    )
    if (fields.isEmpty()) {
      return {}
    }

    const queryBuilder = getQueryBuilder(fields)
    queryBuilder.countsOnly()

    const rootField = queryBuilder.fields.find(
      statement => !statement.get('parentId'),
      null,
      Map()
    )

    const variables = queryBuilder.getVariables()
    const queryString = queryBuilder.buildPivotableSourceForField(
      rootField.toJS(),
      undefined,
      undefined,
      variables
    )

    return {
      gqlStatement: `${searchIndex}: ${queryString}`,
      variables,
    }
  }

  getSelectionPath = (searchIndex, data) => {
    if (hasSonraiSearch(this.props.options, searchIndex)) {
      return this.selectFromSonraiSearchData(searchIndex, data)
    } else {
      return this.selectFromSavedSearchData(searchIndex)
    }
  }

  selectFromSonraiSearchData = (searchIndex, data) => {
    if (_.isEmpty(data)) {
      return []
    } else {
      let key = Object.keys(data[searchIndex].Query)[0]
      let str = `${searchIndex}.Query.${key}.count`

      return str
    }
  }

  selectFromSavedSearchData = searchIndex => {
    const fields = getFields(
      this.props.savedSearches,
      this.props.resultLayout.indexedSearches[searchIndex]
    ).toJS()

    const searchCard = getSearchCard(this.props.resultLayout, searchIndex)

    const path = getSelection(fields, searchCard, 'count')
    path[0] = searchIndex //Root select type becomes aliased to the searchIndex
    return path
  }

  getFontSizes = (numerator, denominator) => {
    const width = this.props.layout.w
    const numNumChars = numerator.toString().length
    const numDenomChars = denominator.toString().length + 1

    const numDigitsAdjusted = numNumChars + numDenomChars
    let numSize = 70
    let denomSize = 50

    let maxDigitsPerWidth = 0.5

    while (numDigitsAdjusted > maxDigitsPerWidth * width && numSize > 15) {
      maxDigitsPerWidth = maxDigitsPerWidth + 0.5
      numSize = numSize - 13
      denomSize = denomSize - 9
    }

    return {
      num: { fontSize: `${numSize > 12 ? numSize : 12}px` },
      denom: { fontSize: `${denomSize > 12 ? denomSize : 12}px` },
    }
  }

  getTitleFontSize = title => {
    const width = this.props.layout.w
    const numChars = title.length

    let fontSize = 16

    let textToWidthRatio = numChars / width

    if (textToWidthRatio > 5) {
      fontSize = 13
    }

    return {
      fontSize: `${fontSize}px`,
    }
  }

  hasOnclick = () => {
    return (
      !!this.props.resultLayout.indexedSearches.onclick ||
      !!this.props.options.sonraiSearches.sonraionclick
    )
  }

  getNumSearchName = () => {
    return hasSonraiSearch(this.props.options, 'num')
      ? this.props.options.sonraiSearches.num
      : this.props.savedSearches.getIn([
          this.props.resultLayout.indexedSearches.num,
          'name',
        ])
  }

  getDenomSearchName = () => {
    return hasSonraiSearch(this.props.options, 'denom')
      ? this.props.options.sonraiSearches.denom
      : this.props.savedSearches.getIn([
          this.props.resultLayout.indexedSearches.denom,
          'name',
        ])
  }

  getTitleSearchName = () => {
    if (this.hasOnclick()) {
      return hasSonraiSearch(this.props.options, 'sonraionclick')
        ? this.props.options.sonraiSearches.sonraionclick
        : this.props.savedSearches.getIn([
            this.props.resultLayout.indexedSearches.onclick,
            'name',
          ])
    }

    return hasSonraiSearch(this.props.options, 'num')
      ? this.props.options.sonraiSearches.num
      : this.props.savedSearches.getIn([
          this.props.resultLayout.indexedSearches.num,
          'name',
        ])
  }

  onClickSearch = type => {
    if (type) {
      this.props.onClickSearch({
        savedSearchId: this.props.resultLayout.indexedSearches[type],
        sonraiSearchName: this.props.options.sonraiSearches[type],
        searchTitle: this.props.title,
      })
    } else if (this.hasOnclick()) {
      this.props.onClickSearch({
        savedSearchId: this.props.resultLayout.indexedSearches.onclick,
        sonraiSearchName: this.props.options.sonraiSearches.sonraionclick,
        searchTitle: this.props.title,
      })
    } else {
      this.props.onClickSearch({
        savedSearchId: this.props.resultLayout.indexedSearches.num,
        sonraiSearchName: this.props.options.sonraiSearches.num,
        searchTitle: this.props.title,
      })
    }
  }

  getRatioNumber = (numerator, denominator) => {
    const fontSizes = this.getFontSizes(numerator, denominator)
    return (
      <div>
        <TextLink
          title={this.getNumSearchName()}
          style={{
            ...styles.number,
            ...styles.searchLink,
          }}
          onClick={() => this.onClickSearch('num')}
        >
          <span style={{ ...styles.numerator, ...fontSizes.num }}>
            {numerator}
          </span>
        </TextLink>
        <span style={{ ...styles.denominator, ...fontSizes.num }}>/</span>
        <TextLink
          title={this.getDenomSearchName()}
          style={{
            ...styles.number,
            ...styles.searchLink,
          }}
          onClick={() => this.onClickSearch('denom')}
        >
          <span style={{ ...styles.numerator, ...fontSizes.denom }}>
            {denominator}
          </span>
        </TextLink>
      </div>
    )
  }

  getFontSize = display => {
    const width = this.props.layout.w
    const numChars = display.length

    let maxDigitsPerWidth = 1
    let fontSize = 50

    while (numChars > maxDigitsPerWidth * width && fontSize > 20) {
      maxDigitsPerWidth++
      fontSize = fontSize - 4
    }

    return {
      fontSize: `${fontSize}px`,
    }
  }

  getPercentNumber = (numerator, denominator) => {
    let percent = ((numerator / denominator) * 100).toFixed(2)
    const fontSize = this.getFontSize(`${percent}%`)
    if (percent === 'NaN') {
      percent = 0
    }
    if (denominator < 1) {
      percent = 'N/A'
    }
    return (
      <div
        style={{
          ...styles.number,
          ...fontSize,
          ...(this.hasOnclick() ? styles.searchLink : {}),
        }}
        onClick={() => this.onClickSearch()}
      >
        <span
          title={`${numerator} / ${denominator}`}
          style={styles.percentNumber}
        >
          {percent}
        </span>
        <span style={styles.percentSign}>
          {percent !== 0 && percent !== 'N/A' && '%'}
        </span>
      </div>
    )
  }

  getAverageNumber = (numerator, denominator) => {
    let avg = (numerator / denominator).toFixed(2)
    const fontSize = this.getFontSize(`${avg}`)
    if (avg === 'NaN') {
      avg = 0
    }
    return (
      <div
        style={{
          ...styles.number,
          ...(this.hasOnclick() ? styles.searchLink : {}),
          ...fontSize,
        }}
        onClick={() => this.onClickSearch()}
      >
        <span style={styles.averageNumber}>{avg}</span>
      </div>
    )
  }

  getNumber = data => {
    if (_.isEmpty(data)) {
      return ''
    }

    const denomSelection = this.getSelectionPath('denom', data)
    const numSelection = this.getSelectionPath('num', data)

    const denominator = _.get(data, denomSelection) || 0
    const numerator = _.get(data, numSelection) || 0

    if (
      this.props.resultLayout.widgetOptions &&
      this.props.resultLayout.widgetOptions.displayType === 'percentage'
    ) {
      return this.getPercentNumber(numerator, denominator)
    } else if (
      this.props.resultLayout.widgetOptions &&
      this.props.resultLayout.widgetOptions.displayType === 'average'
    ) {
      return this.getAverageNumber(numerator, denominator)
    } else {
      return this.getRatioNumber(numerator, denominator)
    }
  }

  getSearchId = () => {
    const { options, sonraiSearches, resultLayout } = this.props
    let searchObj = Map({ uiSearch: null, advancedSearch: null })
    if (hasSonraiSearch(options, 'sonraionclick')) {
      const searches = getSearchIdForSonraiSearches(options, sonraiSearches)
      searchObj = searchObj.set('advancedSearch', searches)
    } else {
      searchObj = searchObj.set(
        'uiSearch',
        resultLayout.indexedSearches.onclick
      )
    }
    return searchObj
  }

  render() {
    if (this.props.data === undefined) {
      const searchId = this.getSearchId()
      const queryConfig = this.getQuery()

      if (!queryConfig.gqlStatement) {
        return (
          <WidgetCard
            searchId={searchId}
            loading={true}
            title={this.props.title}
            allowDelete={this.props.allowDelete}
            allowUpdate={this.props.allowUpdate}
            onRemove={this.props.onRemove}
            onEdit={this.props.onEdit}
          />
        )
      }

      const filtered = queryHasPivotFilter(queryConfig.gqlStatement)
      return (
        <Query
          query={gql`
            ${queryConfig.gqlStatement}
          `}
          variables={queryConfig.variables}
          notifyOnNetworkStatusChange
          pollInterval={300000}
          key={JSON.stringify(queryConfig)}
          context={{
            queryName: `ratioWidget_${_.snakeCase(this.props.title)}`,
          }}
        >
          {({ error, data, refetch, networkStatus }) => {
            return (
              <WidgetCard
                createdBy={this.props.widget && this.props.widget.createdBy}
                searchId={searchId}
                disableToolbar={this.props.disableToolbar}
                allowDelete={this.props.allowDelete}
                allowUpdate={this.props.allowUpdate}
                onEdit={this.props.onEdit}
                error={error}
                onRemove={this.props.onRemove}
                toggleStatic={this.props.toggleStatic}
                static={this.props.static}
                refetch={refetch}
                networkStatus={networkStatus}
                title={
                  <TextLink onClick={() => this.onClickSearch()}>
                    {this.props.title}
                  </TextLink>
                }
                filtered={filtered}
                description={_.get(this.props.resultLayout, [
                  'widgetOptions',
                  'description',
                ])}
              >
                <CardBody style={{ overflow: 'hidden', width: 'auto' }}>
                  <div style={{ overflowX: 'auto' }}>
                    {this.getNumber(data)}
                  </div>
                </CardBody>
                <BottomTitle style={this.getTitleFontSize(this.props.title)}>
                  <TextLink
                    title={this.getTitleSearchName()}
                    onClick={() => this.onClickSearch()}
                  >
                    {this.props.title}
                  </TextLink>
                </BottomTitle>
              </WidgetCard>
            )
          }}
        </Query>
      )
    } else {
      return (
        <Card>
          <CardBody style={{ overflow: 'hidden', width: 'auto' }}>
            <div style={{ overflowX: 'auto' }}>
              {this.getNumber(this.props.data)}
            </div>
          </CardBody>
          <BottomTitle style={this.getTitleFontSize(this.props.title)}>
            <TextLink>{this.props.title}</TextLink>
          </BottomTitle>
        </Card>
      )
    }
  }
}

RatioWidget.defaultProps = {
  layout: {
    w: 10,
  },
}

RatioWidget.propTypes = {
  allowDelete: PropTypes.bool,
  allowUpdate: PropTypes.bool,
  disableToolbar: PropTypes.bool,
  data: PropTypes.object,
  getQueryBuilder: PropTypes.func.isRequired,
  layout: PropTypes.shape({
    w: PropTypes.number,
  }),
  onRemove: PropTypes.func.isRequired,
  resultLayout: PropTypes.object.isRequired,
  savedSearches: ImmutablePropTypes.map.isRequired,
  options: PropTypes.shape({
    sonraiSearches: PropTypes.objectOf(PropTypes.string),
  }),
  static: PropTypes.bool.isRequired,
  title: PropTypes.string,
  toggleStatic: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  onClickSearch: PropTypes.func,
  widget: PropTypes.object,
  sonraiSearches: ImmutablePropTypes.iterable,
}

export default RatioWidget
