/**
 *
 * SparkWidget
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import Radium from 'radium'
import { FormattedMessage } from 'react-intl'
import { Query } from 'react-apollo'
import { Map } from 'immutable'
import messages from './messages'
import { exists } from 'utils/sonraiUtils'
import Chart from 'components/Chart'
import Card, { TopTitle, CardBody } from 'components/Card'
import _ from 'lodash'
import gql from 'graphql-tag'
import WidgetCard from 'components/WidgetCard'
import moment from 'moment'
import TextLink from 'components/TextLink'
const styles = {
  header: { fontSize: '22px', fontWeight: '300' },
  mainContain: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  subtitle: {
    alignSelf: 'flex-start',
    fontSize: '1.1rem',
    marginLeft: '0.25rem',
    color: 'rgb(125, 120, 120)',
  },
  chartContain: { height: '90%', width: '100%', padding: '0.5rem' },
}

class SparkWidget extends React.Component {
  getData = data => {
    if (exists(data.ControlFrameworkEvalLogs)) {
      const logs = data.ControlFrameworkEvalLogs.items.filter(
        x =>
          x.controlFrameworkId ==
          this.props.resultLayout.widgetOptions.controlFramework.value
      )
      if (!_.isEmpty(logs)) {
        const x = logs.map(log => moment.unix(log.time).format())
        const y = logs.map(log => log.percentPass)
        return { x, y }
      } else {
        return {}
      }
    }
  }

  getId = data => {
    if (exists(data.ControlFrameworkEvalLogs)) {
      if (!_.isEmpty(data.ControlFrameworkEvalLogs.items)) {
        return this.props.resultLayout.widgetOptions.controlFramework.value
      }
    }
  }

  handleClick = id => {
    this.props.onClickControlFramework(id)
  }

  renderChart = (logs, id) => {
    if (_.isEmpty(logs)) {
      return (
        <div>
          <FormattedMessage {...messages.noHistory} />
        </div>
      )
    } else {
      return (
        <div style={styles.mainContain}>
          <div style={styles.subtitle}>
            <TextLink onClick={() => this.handleClick(id)}>
              <FormattedMessage {...messages.total} />
              {logs.x.length} <FormattedMessage {...messages.logs} />
            </TextLink>
          </div>
          <div style={styles.chartContain}>
            <Chart
              type="spark"
              yTitle="Compliance Percent"
              yValues={
                !_.isEmpty(logs.y) ? logs.y.map(num => _.round(num, 1)) : logs.y
              }
              xLabels={logs.x}
            />
          </div>
        </div>
      )
    }
  }

  getQuery = () => {
    const { widgetOptions } = this.props.resultLayout
    if (exists(widgetOptions)) {
      if (widgetOptions.controlFramework) {
        const query = {
          doc: gql`
            query controlFrameLogsOverTime {
              ControlFrameworkEvalLogs(where: { 
                controlFrameworkId: "${widgetOptions.controlFramework.value}", 
                  from: "${moment()
                    .subtract(widgetOptions.sparkTimeFrame.value, 'days')
                    .format('YYYY-MM-DD')}"
              }) {
                items {
                  id
                  controlFrameworkId
                  percentPass
                  time
                }
              }
            }
          `,
        }
        return query
      }
    }
  }

  renderHeader = id => {
    if (exists(id)) {
      return (
        <TextLink onClick={() => this.handleClick(id)}>
          <div style={styles.header}>{this.props.title}</div>
        </TextLink>
      )
    } else {
      return (
        <div>
          <div style={styles.header}>{this.props.title}</div>
        </div>
      )
    }
  }

  getSearchId = () => {
    //Because it doesn't support saved or  sonrai searches - just control frameworks
    return Map({
      uiSearch: null,
      advancedSearch: null,
    })
  }

  render() {
    if (this.props.data === undefined) {
      const searchId = this.getSearchId()
      const query = this.getQuery()
      if (!query.doc) {
        return (
          <WidgetCard
            searchId={searchId}
            loading={true}
            title={this.props.title}
            allowDelete={this.props.allowDelete}
            allowUpdate={this.props.allowUpdate}
            onRemove={this.props.onRemove}
            onEdit={this.props.onEdit}
          />
        )
      }

      return (
        <Query
          query={query.doc}
          notifyOnNetworkStatusChange
          pollInterval={300000}
          key={JSON.stringify(query.doc)}
          context={{
            queryName: `sparkWidget${_.snakeCase(this.props.title)}`,
          }}
        >
          {({ loading, error, data, networkStatus, refetch }) => {
            const logs = loading ? null : this.getData(data)
            const id = loading ? null : this.getId(data)

            return (
              <WidgetCard
                createdBy={this.props.widget && this.props.widget.createdBy}
                searchId={searchId}
                loading={loading}
                disableToolbar={this.props.disableToolbar}
                allowDelete={this.props.allowDelete}
                allowUpdate={this.props.allowUpdate}
                onEdit={this.props.onEdit}
                error={error}
                refetch={refetch}
                onRemove={this.props.onRemove}
                toggleStatic={this.props.toggleStatic}
                static={this.props.static}
                networkStatus={networkStatus}
                title={
                  <TextLink onClick={() => this.handleClick(id)}>
                    {this.props.title}
                  </TextLink>
                }
                description={_.get(this.props.resultLayout, [
                  'widgetOptions',
                  'description',
                ])}
              >
                <TopTitle>{this.renderHeader(id)}</TopTitle>
                <CardBody>{this.renderChart(logs, id)}</CardBody>
              </WidgetCard>
            )
          }}
        </Query>
      )
    } else {
      return (
        <Card>
          {this.props.title && <TopTitle>{this.renderHeader()}</TopTitle>}
          <CardBody>{this.renderChart(this.props.data)}</CardBody>
        </Card>
      )
    }
  }
}

SparkWidget = Radium(SparkWidget) // eslint-disable-line

SparkWidget.propTypes = {
  allowDelete: PropTypes.bool,
  allowUpdate: PropTypes.bool,
  disableToolbar: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  toggleStatic: PropTypes.func,
  static: PropTypes.bool,
  resultLayout: PropTypes.object,
  data: PropTypes.object,
  onEdit: PropTypes.func,
  onClickControlFramework: PropTypes.func,
  widget: PropTypes.object,
}

export default SparkWidget
