import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import injectReducer from 'utils/injectReducer'
import GridLayout from 'react-grid-layout'
import _ from 'lodash'
import qs from 'query-string'
import { push } from 'connected-react-router'
import { List, Map } from 'immutable'

import CenterContent from 'components/CenterContent'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Icon from 'components/Icon'
import BorderlessButton from 'components/BorderlessButton'
import BorderedCard from 'components/BorderedCard'
import SizeMe from 'components/SizeMe'
import { getNodeViewPushParams } from 'utils/sonraiUtils'
import {
  setWidgetOptions,
  removeSCWidget,
  toggleSCWidgetStatic,
  updateWidget,
  setShowCreateWidgetModal,
} from 'containers/SolutionCenter/actions'
import { selectQueryTypes, selectPivot } from 'containers/SonraiData/selectors'
import { QueryBuilder } from 'query-builder'
import WidgetModal from 'containers/WidgetModal'
import permissionsChecker from 'containers/PermissionChecker'

import reducer from './reducer'
import { selectLayout, selectSavingWidget } from './selectors'
import Widget from './Widget'

const SizedGridLayout = SizeMe(GridLayout)

export class SolutionCenterWidgets extends React.Component {
  state = {
    showWidgetModal: false,
    editingWidgetId: null,
    renderLocks: Map(),
  }

  componentDidUpdate(oldProps) {
    if (oldProps.savingWidget && !this.props.savingWidget) {
      this.setState({
        showWidgetModal: false,
        editingWidgetId: null,
      })
    }
  }

  setEditingWidget = widgetId => {
    this.setState({
      showWidgetModal: true,
      editingWidgetId: widgetId,
    })
  }

  toggleEditWidgetModal = () => {
    this.setState(currentState => ({
      showWidgetModal: !currentState.showWidgetModal,
      editingWidgetId: null,
    }))
  }

  setWidgetOptions = (widgetId, options) => {
    this.props.setWidgetOptions(this.props.card.get('srn'), widgetId, options)
  }

  onClickSearch = params => {
    const pathname = '/App/WidgetResultExplorer/'
    this.props.push({
      pathname: pathname,
      state: params,
    })
  }

  onClickAlert = id => {
    this.props.push({
      pathname: '/App/Alert',
      search: qs.stringify({
        alertId: id,
      }),
    })
  }

  onClickControlFramework = srn => {
    this.props.push({
      pathname: '/App/ControlCenter/ControlGroup',
      search: qs.stringify({
        controlGroupId: srn,
      }),
    })
  }

  removeWidget = widgetId => {
    this.props.removeSCWidget(this.props.card.get('srn'), widgetId)
  }

  toggleStatic = widgetId => {
    this.props.toggleSCWidgetStatic(this.props.card.get('srn'), widgetId)
  }

  onClickNodeView = (nodeId, type) => {
    this.props.push(getNodeViewPushParams(nodeId, type))
  }

  handleLayoutChange = newLayout => {
    newLayout.forEach(widgetLayout => {
      const oldWidgetLayout =
        _.find(this.props.layout, layout => layout.i === widgetLayout.i) || {}

      const oldProps = {
        h: oldWidgetLayout.h,
        w: oldWidgetLayout.w,
        x: oldWidgetLayout.x,
        y: oldWidgetLayout.y,
      }

      const newProps = {
        h: widgetLayout.h,
        w: widgetLayout.w,
        x: widgetLayout.x,
        y: widgetLayout.y,
      }

      if (!_.isEqual(oldProps, newProps)) {
        const widget = this.props.card
          .get('widgets')
          .toJS()
          .find(widget => widget.sid === widgetLayout.i)

        let updatedWidget = {
          ...widget,
        }

        updatedWidget.widgetSize = [widgetLayout.w, widgetLayout.h]
        updatedWidget.widgetLocation = [widgetLayout.x, widgetLayout.y]
        this.props.updateWidget(this.props.card.get('srn'), updatedWidget)
      }
    })
  }

  getQueryBuilder = fields => {
    const queryBuilder = new QueryBuilder({
      query: fields,
      types: this.props.queryTypes,
      pivot: this.props.pivot,
    })

    return queryBuilder
  }

  lockRender = (layout, oldItem) => {
    this.setState(old => {
      return { renderLocks: old.renderLocks.set(oldItem.i, false) }
    })
  }

  unlockRender = (layout, oldItem) => {
    this.setState(old => {
      return { renderLocks: old.renderLocks.set(oldItem.i, true) }
    })
  }

  render() {
    const canUpdateSolutionCard = this.props.userHasPermission({
      permissionName: 'edit.solutioncards',
      resourceId: this.props.card.get('resourceId'),
    })

    const canViewData = this.props.userHasPermission({
      permissionName: 'view.data',
    })

    if (
      this.props.card.get('widgets', List()).isEmpty() &&
      canUpdateSolutionCard
    ) {
      return (
        <div
          style={{
            width: '205px',
            height: '215px',
            margin: '0.75rem',
          }}
        >
          <BorderedCard
            style={{
              backgroundColor: this.props.theme.neutralLight,
              height: '100%',
              width: '100%',
            }}
          >
            <CenterContent style={{ height: '100%', width: '100%' }}>
              <BorderlessButton
                onClick={() => this.props.setShowCreateWidgetModal(true)}
                style={{ width: '100%', height: '100%' }}
              >
                <div
                  style={{
                    marginBottom: '10px',
                  }}
                >
                  <Icon
                    fa
                    name="circlePlus"
                    color={this.props.theme.neutralMedium}
                    style={{ fontSize: '60px' }}
                  />
                </div>
                Create new Widget
              </BorderlessButton>
            </CenterContent>
          </BorderedCard>
        </div>
      )
    }

    const editingWidget = this.props.card
      .get('widgets', List())
      .find(
        widget => widget.get('srn') === this.state.editingWidgetId,
        null,
        Map()
      )
    return (
      <Fragment>
        {/*Setting useCSSTransforms makes widget dropdowns not be covered up by other widgets*/}
        <SizedGridLayout
          className="layout"
          cols={48}
          rowHeight={24}
          margin={[12, 12]}
          onLayoutChange={this.handleLayoutChange}
          draggableHandle=".drag-handle"
          layout={this.props.layout}
          useCSSTransforms={false}
          key={this.props.card.get('srn')}
          onResizeStart={this.lockRender}
          onResizeStop={this.unlockRender}
        >
          {this.props.card.get('widgets', List()).map((widget, index) => {
            const canEdit = this.props.userHasPermission({
              permissionName: 'edit.solutioncards',
              resourceId: widget.get('resourceId'),
            })

            return (
              <div key={widget.get('sid')} data-grid={{ static: !canEdit }}>
                <Widget
                  onClickControlFramework={this.onClickControlFramework}
                  onClickAlert={this.onClickAlert}
                  getQueryBuilder={this.getQueryBuilder}
                  onEdit={this.setEditingWidget}
                  removeWidget={this.removeWidget}
                  toggleStatic={this.toggleStatic}
                  onClickSearch={this.onClickSearch}
                  onClickNodeView={this.onClickNodeView}
                  setWidgetOptions={this.setWidgetOptions}
                  layout={this.props.layout[index]}
                  widget={widget}
                  canRender={this.state.renderLocks.get(widget.get('sid'))}
                  canViewData={canViewData}
                />
              </div>
            )
          })}
        </SizedGridLayout>
        {canUpdateSolutionCard && (
          <Fragment>
            <WidgetModal
              isOpen={this.state.showWidgetModal}
              onClose={this.toggleEditWidgetModal}
              layout={this.props.layout}
              getQueryBuilder={this.getQueryBuilder}
              card={this.props.card.toJS()}
              saving={this.props.savingWidget}
              editingWidget={editingWidget}
            />
          </Fragment>
        )}
      </Fragment>
    )
  }
}

SolutionCenterWidgets.propTypes = {
  card: ImmutablePropTypes.contains({
    sid: PropTypes.string.isRequired,
    srn: PropTypes.string.isRequired,
    widgets: ImmutablePropTypes.listOf(
      ImmutablePropTypes.contains({
        sid: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        type: PropTypes.oneOf([
          'bigCount',
          'table',
          'pieChart',
          'lineChart',
          'ratio',
          'barChart',
          'list',
          'gauge',
          'map',
          'regions',
          'advMap',
          'compliance',
          'spark',
          'alert',
        ]).isRequired,
        options: ImmutablePropTypes.map,
        query: ImmutablePropTypes.map,
        selection: PropTypes.string,
        formatter: PropTypes.func,
        widgetSize: ImmutablePropTypes.list.isRequired,
        widgetLocation: ImmutablePropTypes.list.isRequired,
        static: PropTypes.bool.isRequired,
      })
    ),
  }).isRequired,
  layout: PropTypes.array.isRequired,
  pivot: ImmutablePropTypes.map.isRequired,
  push: PropTypes.func.isRequired,
  queryTypes: ImmutablePropTypes.iterable.isRequired,
  removeSCWidget: PropTypes.func.isRequired,
  savingWidget: PropTypes.bool,
  setShowCreateWidgetModal: PropTypes.func.isRequired,
  setWidgetOptions: PropTypes.func.isRequired,
  theme: themeShape,
  toggleSCWidgetStatic: PropTypes.func.isRequired,
  updateWidget: PropTypes.func.isRequired,
  userHasPermission: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  layout: selectLayout,
  pivot: selectPivot,
  queryTypes: selectQueryTypes,
  savingWidget: selectSavingWidget,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
      removeSCWidget,
      setWidgetOptions,
      toggleSCWidgetStatic,
      updateWidget,
      setShowCreateWidgetModal,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withReducer = injectReducer({ key: 'SolutionCenterWidgets', reducer })

export default compose(
  withReducer,
  withConnect,
  permissionsChecker,
  themeable
)(SolutionCenterWidgets)
