import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import { FormattedMessage } from 'react-intl'
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap'

import { selectUserProfile } from 'containers/UserProfileData/selectors'
import Button from 'components/Button'
import TextLink from 'components/TextLink'
import { selectCards } from 'containers/SolutionCenter/selectors'
import { unhideSolutionCards } from 'containers/UserProfileData/actions'

import messages from './messages.js'

export class ModalHiddenSCManager extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      reVisibleCards: [],
    }
  }

  toggleCard = srn => {
    let { reVisibleCards } = this.state
    if (reVisibleCards.includes(srn)) {
      let arr = [...reVisibleCards]
      var index = arr.indexOf(srn)
      arr.splice(index, 1)
      this.setState({ reVisibleCards: arr })
    } else {
      this.setState(oldState => ({
        reVisibleCards: [...oldState.reVisibleCards, srn],
      }))
    }
  }

  handleUnHideSCCards = () => {
    const srns = this.state.reVisibleCards

    if (srns.length > 0) {
      this.props.unhideSolutionCards(srns)
    }

    this.props.toggle()
    this.setState({ reVisibleCards: [] })
  }

  render() {
    const hiddenCardIds = this.props.userProfile.get(
      'hiddenSolutionCards',
      List()
    )

    const hiddenCards = this.props.solutionCards
      .filter(solutionCard => hiddenCardIds.includes(solutionCard.get('srn')))
      .toJS()

    return (
      <Modal isOpen={this.props.isOpen}>
        <ModalHeader toggle={this.props.toggle}>
          Edit Hidden Solution Cards
        </ModalHeader>
        <ModalBody>
          <Table hover={true}>
            <thead>
              <th>Card Name</th>
              <th>Widgets</th>
              <th>Status</th>
              <th />
            </thead>
            <tbody>
              {hiddenCards.map(card => (
                <tr key={card.srn}>
                  <td>{card.name}</td>
                  <td>{card.widgets && card.widgets.length} Widgets</td>
                  <td>
                    {this.state.reVisibleCards.includes(card.srn)
                      ? 'Visible'
                      : 'Hidden'}
                  </td>
                  <td>
                    <TextLink
                      color="primary"
                      onClick={() => this.toggleCard(card.srn)}
                    >
                      {this.state.reVisibleCards.includes(card.srn)
                        ? 'Hide'
                        : 'Show'}
                    </TextLink>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <TextLink color="primary" onClick={this.props.toggle}>
            <FormattedMessage {...messages.cancelScEditButton} />
          </TextLink>

          <Button
            color="primary"
            disabled={this.state.reVisibleCards.length === 0}
            onClick={this.handleUnHideSCCards}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

ModalHiddenSCManager.propTypes = {
  isOpen: PropTypes.bool,
  solutionCards: ImmutablePropTypes.list.isRequired,
  toggle: PropTypes.func,
  unhideSolutionCards: PropTypes.func.isRequired,
  userProfile: ImmutablePropTypes.map.isRequired,
}

const mapStateToProps = createStructuredSelector({
  userProfile: selectUserProfile,
  solutionCards: selectCards,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      unhideSolutionCards,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

export default compose(withConnect)(ModalHiddenSCManager)
