import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { injectIntl } from 'react-intl'

import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'
import { saveLayout } from 'containers/SolutionCenter/actions'
import {
  selectSelectedCard,
  selectSavingLayout,
  selectHasUnsavedChanges,
} from 'containers/SolutionCenterCards/selectors'
import permissionChecker from 'containers/PermissionChecker'
import { selectPivot } from 'containers/SonraiData/selectors'
import IconLayer from 'components/IconLayer'
import IconCount from 'components/IconCount'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

import ModalRenameSC from './ModalRenameSC'
import ModalConfirmDelete from './ModalConfirmDelete'
import ModalHiddenSCManager from './ModalHiddenSCManager'
import ModalCopySC from './ModalCopySC'
import messages from './messages'
import CardActionsMenu from './CardActionsMenu'

export class SolutionCenterCardActions extends React.Component {
  styles = {
    toolbarButton: {
      padding: '0 0.5em',
    },
    saveButton: {
      boxShadow: ' 0 0 0 rgba(204,169,44, 0.4)',
      animation: 'pulse 2s infinite',
    },
    cardActions: {
      display: 'flex',
    },
  }

  state = {
    editingScNameId: null,
    deletingScId: null,
    showHiddenScManagerModal: false,
    copyingScModalOpen: false,
  }

  toggleShowDeleteConfirmationModal = () => {
    this.setState({
      deletingScId: null,
    })
  }

  showConfirmDelete = cardId => {
    this.setState({
      deletingScId: cardId,
    })
  }

  toggleHiddenSCManagerModal = () => {
    this.setState(currentState => ({
      showHiddenScManagerModal: !currentState.showHiddenScManagerModal,
    }))
  }

  toggleRenameScModal = () => {
    this.setState({
      editingScNameId: null,
    })
  }

  renameSolutionCard = cardId => {
    this.setState({
      editingScNameId: cardId,
    })
  }

  copySolutionCard = () => {
    this.setState({
      copyingScModalOpen: true,
    })
  }

  saveLayout = () => {
    this.props.saveLayout(this.props.selectedSolutionCard)
  }

  renderSaveButton = () => {
    if (this.props.savingLayout) {
      return (
        <BorderlessButton color={'primary'} style={this.styles.toolbarButton}>
          <Icon spin fa name="spinner" transform="grow-2" />
        </BorderlessButton>
      )
    }

    const canUpdateCurrentCard = this.props.userHasPermission({
      permissionName: 'edit.solutioncards',
      resourceId: this.props.selectedSolutionCard.get('resourceId'),
    })

    if (!this.props.hasUnsavedChanges || !canUpdateCurrentCard) {
      return null
    }

    return (
      <BorderlessButton
        style={this.styles.toolbarButton}
        color={'primary'}
        onClick={this.saveLayout}
        title={this.props.intl.formatMessage(messages.saveLayoutButton)}
        className="flip-in-ver-right-delayed"
      >
        <Icon
          style={this.styles.saveButton}
          fa
          name="save"
          transform="grow-2"
        />
      </BorderlessButton>
    )
  }

  toggleCopyingScModal = () => {
    this.setState(old => {
      return {
        copyingScModalOpen: !old.copyingScModalOpen,
      }
    })
  }

  render() {
    return (
      <div style={this.styles.cardActions}>
        <ModalRenameSC
          toggle={this.toggleRenameScModal}
          isOpen={!!this.state.editingScNameId}
          editCardId={this.state.editingScNameId}
        />
        <ModalCopySC
          toggle={this.toggleCopyingScModal}
          isOpen={this.state.copyingScModalOpen}
        />
        <ModalHiddenSCManager
          toggle={this.toggleHiddenSCManagerModal}
          isOpen={this.state.showHiddenScManagerModal}
        />
        <ModalConfirmDelete
          isOpen={!!this.state.deletingScId}
          toggle={this.toggleShowDeleteConfirmationModal}
          cardId={this.state.deletingScId}
        />
        {this.renderSaveButton()}
        <BorderlessButton
          className="flip-in-ver-right-delayed"
          style={this.styles.toolbarButton}
          onClick={this.props.toggleFiltersPanel}
          title="Toggle Filters Panel"
        >
          {!this.props.pivot.isEmpty() ? (
            <IconLayer>
              <Icon
                fa
                name="filter"
                color={this.props.theme.primary}
                style={{ fontSize: '24px' }}
                transform="down-2"
              />
              <IconCount
                text={this.props.pivot.size}
                color={this.props.theme.emphasis}
              />
            </IconLayer>
          ) : (
            <Icon fa name="filter" />
          )}
        </BorderlessButton>
        <CardActionsMenu
          renameSolutionCard={this.renameSolutionCard}
          copySolutionCard={this.copySolutionCard}
          deleteSolutionCard={this.showConfirmDelete}
          toggleHiddenSCManagerModal={this.toggleHiddenSCManagerModal}
        />
      </div>
    )
  }
}

SolutionCenterCardActions.propTypes = {
  hasUnsavedChanges: PropTypes.bool,
  pivot: ImmutablePropTypes.map.isRequired,
  savingLayout: PropTypes.bool,
  saveLayout: PropTypes.func.isRequired,
  selectedSolutionCard: ImmutablePropTypes.map.isRequired,
  theme: themeShape,
  toggleFiltersPanel: PropTypes.func,
  userHasPermission: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  hasUnsavedChanges: selectHasUnsavedChanges,
  pivot: selectPivot,
  savingLayout: selectSavingLayout,
  selectedSolutionCard: selectSelectedCard,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveLayout,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(
  withConnect,
  injectIntl,
  permissionChecker,
  themeable
)(SolutionCenterCardActions)
