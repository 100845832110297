/*
 * AlertWidget Messages
 *
 * This contains all the text for the AlertWidget component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  header: {
    id: 'app.components.AlertWidget.header',
    defaultMessage: 'This is the AlertWidget component !',
  },
  findings: {
    id: 'app.components.AlertWidget.findings',
    defaultMessage: 'Findings',
  },
  noData: {
    id: 'app.components.AlertWidget.noData',
    defaultMessage: 'No Data to show',
  },
})
