import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'
import { Map } from 'immutable'
import moment from 'moment'
import _ from 'lodash'

import AlertWidget from 'components/AlertWidget'
import FormLabel from 'components/FormLabel'
import SelectBar from 'components/SelectBar'
import { guidGen } from 'utils/sonraiUtils'
import { CONTROL_FRAMEWORKS } from 'appConstants'
import PreviewContainer from './PreviewContainer'

const styles = {
  wrapperWithPreview: {
    display: 'grid',
    gridTemplateColumns: '45% 1fr',
    gridColumnGap: '2em',
  },
  alertPreview: { minWidth: '225px', height: '350px', maxHeight: '350px' },
}

const placeholderData = [
  {
    id: guidGen(),
    key: guidGen(),
    name: 'Placeholder',
    count: 4,
    time: moment().fromNow(),
    level: Math.floor(Math.random() * 3) + 1,
  },
  {
    id: guidGen(),
    key: guidGen(),
    name: 'Placeholder',
    count: Math.floor(Math.random() * 10) + 1,
    time: moment().fromNow(),
    level: Math.floor(Math.random() * 3) + 1,
  },
  {
    id: guidGen(),
    key: guidGen(),
    name: 'Placeholder',
    count: Math.floor(Math.random() * 10) + 1,
    time: moment().fromNow(),
    level: Math.floor(Math.random() * 3) + 1,
  },
].sort((a, b) => b.level - a.level)

class AlertWidgetConfig extends React.Component {
  componentDidUpdate() {
    this.updateValidity()
  }

  updateValidity = () => {
    const hasControlFrameWork = !!this.props.widgetOptions.alert
    const hasTitle = this.props.widgetTitle

    const valid = hasControlFrameWork && hasTitle
    this.props.setValidity(valid)
  }

  handleSelectControlFrameWork = val => {
    this.props.setWidgetOptions({
      alert: val,
    })

    this.handleSuggestedTitle(this.getSuggestedTitle(val))
  }

  setTitle = e => {
    this.props.setWidgetTitle(e.target.value)
  }

  setDescription = e => {
    this.props.setWidgetOptions({
      description: e.target.value,
    })
  }

  handleSuggestedTitle = title => this.props.setWidgetTitle(title)

  getSuggestedTitle = selection => {
    if (selection !== null) {
      const message =
        selection.label === CONTROL_FRAMEWORKS.ALL_CONTROL_FRAMEWORKS
          ? 'All Alerts'
          : `Alerts for ${selection.label}`
      return message
    }
  }

  render() {
    const enabledControlFrameworks = this.props.controlGroupOptions
      .filter(cf => cf.get('enabled'))
      .toList()
      .map(cf =>
        Map({
          value: cf.get('srn'),
          label: cf.get('title'),
        })
      )
      .sortBy(cf => cf.get('label').toLowerCase())

    const options =
      enabledControlFrameworks.size > 1
        ? [
            {
              value: CONTROL_FRAMEWORKS.ALL_CONTROL_FRAMEWORKS,
              label: 'All Control Frameworks',
            },
            ...enabledControlFrameworks.toJS(),
          ]
        : enabledControlFrameworks.toJS()

    return (
      <div style={this.props.previewWidget ? styles.wrapperWithPreview : {}}>
        {this.props.previewWidget && (
          <PreviewContainer>
            <div style={styles.alertPreview}>
              <AlertWidget
                data={placeholderData}
                title={
                  this.props.widgetTitle ? (
                    this.props.widgetTitle
                  ) : (
                    <span style={{ fontStyle: 'italic' }}>Widget Title</span>
                  )
                }
              />
            </div>
          </PreviewContainer>
        )}
        <div>
          <FormLabel
            label="Control Framework"
            for="controlFramework"
            required={true}
          />

          <SelectBar
            autoSort={false}
            isClearable
            value={_.get(this.props.widgetOptions.alert, 'value')}
            options={options}
            onChange={this.handleSelectControlFrameWork}
          />

          <FormLabel label="Title" for="title" required={true} />
          <Input
            type="text"
            name="widgetTitle"
            id="widgetTitle"
            placeholder="Title..."
            value={this.props.widgetTitle || ''}
            onChange={this.setTitle}
          />

          <FormLabel label="Description" for="widgetDescription" />
          <Input
            id="widgetDescription"
            type="textarea"
            name="widgetDescription"
            onChange={this.setDescription}
            value={this.props.widgetOptions.description || ''}
          />
        </div>
      </div>
    )
  }
}

AlertWidgetConfig.propTypes = {
  previewWidget: PropTypes.bool,
  setWidgetOptions: PropTypes.func,
  setWidgetTitle: PropTypes.func,
  setValidity: PropTypes.func,
  widgetOptions: PropTypes.shape({
    alert: PropTypes.string,
    description: PropTypes.string,
  }),

  widgetTitle: PropTypes.string,
  controlGroupOptions: PropTypes.object,
}

export default AlertWidgetConfig
