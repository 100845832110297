/*
 * ComplianceWidget Messages
 *
 * This contains all the text for the ComplianceWidget component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  header: {
    id: 'app.components.ComplianceWidget.header',
    defaultMessage: 'This is the ComplianceWidget component !',
  },
  noData: {
    id: 'app.components.ComplianceWidget.noData',
    defaultMessage: 'No Data to show',
  },
})
