import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'
import { List } from 'immutable'
import CombinedSearches from 'components/CombinedSearches'
import FormLabel from 'components/FormLabel'

export class TableConfig extends React.Component {
  componentDidUpdate() {
    this.updateValidity()
  }

  updateValidity = () => {
    const hasSonraiSearch = !!this.props.widgetSonraiSearches.table
    const hasSavedSearch = !!this.props.widgetSavedSearches.table
    const hasTitle = this.props.widgetTitle

    const valid = (hasSonraiSearch || hasSavedSearch) && hasTitle
    this.props.setValidity(valid)
  }

  setTitle = e => {
    this.props.setWidgetTitle(e.target.value)
  }

  setDescription = e => {
    const string = e.target.value

    this.props.setWidgetOptions({
      description: string,
    })
  }

  setSubTitle = e => {
    this.props.setWidgetSubTitle(e.target.value)
  }

  setSavedSearch = selectedOption => {
    this.props.setWidgetSavedSearch(
      selectedOption ? selectedOption.value : null,
      'table'
    )
  }

  setSonraiSearch = selectedOption => {
    this.props.setWidgetSonraiSearch(
      selectedOption ? selectedOption.value : null,
      'table'
    )
  }

  setSearchField = selectedOption => {
    this.props.setWidgetSearchField(
      selectedOption ? selectedOption.value : null,
      'table'
    )
  }

  render() {
    const sonraiSearchName = this.props.widgetSonraiSearches['table']
    const savedSearchId = this.props.widgetSavedSearches['table']
    const savedSearchField = this.props.widgetSearchCards['table']
    const searchFieldsList = this.props.searchCards.get(
      this.props.widgetSavedSearches['table'],
      List()
    )

    return (
      <div>
        <FormLabel label="Title" for="widgetTitle" required={true} />
        <Input
          type="text"
          name="widgetTitle"
          id="widgetTitle"
          placeholder="Number of Identities"
          value={this.props.widgetTitle || ''}
          onChange={this.setTitle}
        />

        <FormLabel label="Sub Title" for="widgetSubTitle" />
        <Input
          type="text"
          name="widgetSubTitle"
          id="widgetSubTitle"
          placeholder="By Group"
          value={this.props.widgetSubTitle || ''}
          onChange={this.setSubTitle}
        />

        <FormLabel label="Description" for="widgetDescription" />
        <Input
          id="widgetDescription"
          type="textarea"
          name="widgetDescription"
          onChange={this.setDescription}
          value={this.props.widgetOptions.description || ''}
        />

        <FormLabel label="Search" for="widgetSearch" required={true} />
        <CombinedSearches
          name="widgetSearch"
          savedSonraiSearches={this.props.savedSonraiSearches}
          setSonraiSearch={this.setSonraiSearch}
          selectedSonraiValue={sonraiSearchName}
          savedSearches={this.props.savedSearches}
          selectedSearchId={savedSearchId}
          selectSavedSearch={this.setSavedSearch}
          savedSearchDisabled={!!sonraiSearchName}
          selectedFieldId={savedSearchField ? savedSearchField.id : null}
          searchCards={searchFieldsList}
          searchFieldDisabled={searchFieldsList.isEmpty()}
          selectSearchField={this.setSearchField}
          searchFieldLabel="Optionally select a subset of the search results"
          searchFieldRequired={false}
        />
      </div>
    )
  }
}

TableConfig.propTypes = {
  savedSearches: ImmutablePropTypes.iterableOf(
    ImmutablePropTypes.contains({
      sid: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  savedSonraiSearches: ImmutablePropTypes.iterableOf(
    ImmutablePropTypes.contains({
      sid: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  searchCards: ImmutablePropTypes.mapOf(
    PropTypes.oneOfType([
      ImmutablePropTypes.contains({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
      PropTypes.array,
    ])
  ),
  setWidgetOptions: PropTypes.func,
  setWidgetSavedSearch: PropTypes.func,
  setWidgetSonraiSearch: PropTypes.func,
  setWidgetSearchField: PropTypes.func,
  setWidgetSubTitle: PropTypes.func,
  setWidgetTitle: PropTypes.func,
  setValidity: PropTypes.func,
  widgetOptions: PropTypes.shape({
    description: PropTypes.string,
  }),
  widgetSavedSearches: PropTypes.object,
  widgetSonraiSearches: PropTypes.object,
  widgetSearchCards: PropTypes.object,
  widgetSubTitle: PropTypes.string,
  widgetTitle: PropTypes.string,
}

export default TableConfig
