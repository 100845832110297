import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap'

import { TextLink } from 'components/TextLink'
import Button from 'components/Button'
import { deleteSolutionCard } from 'containers/SolutionCenter/actions'

export class ModalConfirmDelete extends React.Component {
  handleDeleteSc = () => {
    this.props.deleteSolutionCard(this.props.cardId)
    this.props.toggle()
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader>
          Are You Sure You Want To Delete This Solution Card?
        </ModalHeader>

        <ModalBody>Deleting a Solution Card cannot be undone</ModalBody>
        <ModalFooter>
          <TextLink color="primary" onClick={this.props.toggle}>
            Cancel
          </TextLink>
          <Button color="primary" onClick={this.handleDeleteSc}>
            Continue
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

ModalConfirmDelete.propTypes = {
  deleteSolutionCard: PropTypes.func.isRequired,
  cardId: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteSolutionCard,
    },
    dispatch
  )
}

const withConnect = connect(
  undefined,
  mapDispatchToProps
)

export default compose(withConnect)(ModalConfirmDelete)
