import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import _ from 'lodash'
import { Map, List } from 'immutable'

import { TopTitle, CardBody } from 'components/Card'
import WidgetCard from 'components/WidgetCard'
import { getCoordConf } from 'utils/widgetUtils'
import { getSearchIdForSonraiSearches } from 'utils/sonraiUtils'
import TextLink from 'components/TextLink'

import {
  getSonraiSearchQuery,
  getSonraiSearchData,
  hasSonraiSearch,
  queryHasPivotFilter,
  getFields,
  getSelection,
} from 'query-builder'
import ArcMap from './ArcMap'

export class ArcMapWidget extends React.Component {
  styles = {
    title: {
      fontSize: '22px',
      fontWeight: '300',
    },
  }

  getText = locations => {
    if (_.isEmpty(locations)) {
      return []
    }

    const points = []
    const locs = new Set() //eslint-disable-line no-restricted-globals
    locations.items.forEach(item => {
      const name = _.get(item, ['isInRegion', 'items', 0, 'name'])
      locs.add(name)
    })
    locs.forEach(name => {
      const { coordinates } = getCoordConf(name)
      const nme = name || 'null'
      if (
        coordinates &&
        coordinates[0] !== null &&
        coordinates[1] !== null &&
        nme !== null
      ) {
        points.push({ position: coordinates, name: nme })
      }
    })

    return points
  }

  getArcs = locations => {
    if (_.isEmpty(locations)) {
      return []
    }

    const arcs = []

    locations.items.forEach(item => {
      const name = item.region
      const { coordinates } = getCoordConf(name)
      if (coordinates !== null && coordinates.indexOf(null) === -1) {
        const attachedLocations = _.get(item, ['performedAt', 'items'])
        attachedLocations.forEach(loc => {
          arcs.push({
            from: {
              name: loc.srn,
              coordinates: [loc.longitude, loc.latitude],
            },
            to: {
              name,
              coordinates,
            },
          })
        })
      }
    })

    return arcs
  }

  getTableSavedSearchQuery = () => {
    let fields = getFields(
      this.props.savedSearches,
      this.props.resultLayout.indexedSearches.advmapsearch
    )

    if (fields.isEmpty()) {
      return {}
    }

    const rootField = fields.find(
      statement => !statement.get('parentId'),
      null,
      Map()
    )

    const queryBuilder = this.props.getQueryBuilder(fields)
    queryBuilder.enableFlattenMode()
    queryBuilder.skipCounts()

    //Force-add the required columns if they are not present
    queryBuilder.fields = queryBuilder.fields.map(field => {
      const nodeType = field.getIn(['definition', 'type', 'name'])
      if (nodeType === 'LocationEdgeRelation') {
        if (!field.get('displayFields', List()).includes('latitude')) {
          field = field.update('displayFields', List(), propList =>
            propList.push('latitude')
          )
        }

        if (!field.get('displayFields', List()).includes('longitude')) {
          field = field.update('displayFields', List(), propList =>
            propList.push('longitude')
          )
        }
      } else if (nodeType === 'ActionEdgeRelation') {
        if (!field.get('displayFields', List()).includes('region')) {
          field = field.update('displayFields', List(), propList =>
            propList.push('region')
          )
        }
      }

      return field
    })

    const query = queryBuilder.buildPivotableSource(
      rootField.get('id'),
      'arcmapQuery',
      { limit: 500 }
    )

    return {
      ...query,
      flatten: true,
    }
  }

  getQuery = () => {
    if (hasSonraiSearch(this.props.options, 'advmapsearch')) {
      return getSonraiSearchQuery(
        this.props.options.sonraiSearches.advmapsearch
      )
    } else {
      return this.getTableSavedSearchQuery()
    }
  }

  getSearchName = () => {
    return hasSonraiSearch(this.props.options, 'advmapsearch')
      ? this.props.options.sonraiSearches.advmapsearch
      : this.props.savedSearches.getIn([
          this.props.resultLayout.indexedSearches.advmapsearch,
          'name',
        ])
  }

  onClickSearch = () => {
    this.props.onClickSearch({
      savedSearchId: this.props.resultLayout.indexedSearches.advmapsearch,
      sonraiSearchName: this.props.options.sonraiSearches.advmapsearch,
      searchTitle: this.props.title,
    })
  }

  getData = data => {
    if (!_.isEmpty(this.props.options.sonraiSearches.advmapsearch)) {
      return getSonraiSearchData(data)
    } else {
      return this.getSavedSearchData(data)
    }
  }

  getSavedSearchData = data => {
    const fields = getFields(
      this.props.savedSearches,
      this.props.resultLayout.indexedSearches.advmapsearch
    )

    const hasPerformedAts = fields
      .filter(
        field => field.getIn(['definition', 'name'], '') === 'performedAt'
      )
      .map(field => field.get('parentId'))
      .toList()

    const actions = hasPerformedAts
      .map(id => fields.get(id))
      .filter(field => field.getIn(['definition', 'name'], '') === 'Actions')

    if (actions.isEmpty()) {
      return []
    }

    const selection = getSelection(
      fields.toJS(),
      actions.first().toJS(),
      undefined,
      'items'
    )

    if (!selection) {
      return []
    }

    return _.get(data, [...selection], [])
  }

  getSearchId = () => {
    const { options } = this.props
    let searchObj = Map({ uiSearch: null, advancedSearch: null })
    if (hasSonraiSearch(options, 'advmapsearch')) {
      const searches = getSearchIdForSonraiSearches(
        options,
        this.props.sonraiSearches
      )
      searchObj = searchObj.set('advancedSearch', searches)
    } else {
      searchObj = searchObj.set(
        'uiSearch',
        this.props.resultLayout.indexedSearches.advmapsearch
      )
    }

    return searchObj
  }

  render() {
    if (this.props.data === undefined) {
      const query = this.getQuery()
      const searchId = this.getSearchId()
      if (!query.gqlStatement) {
        return <WidgetCard loading={true} title={this.props.title} />
      }

      const filtered = queryHasPivotFilter(query.gqlStatement)

      return (
        <Query
          query={gql`
            ${query.gqlStatement}
          `}
          variables={query.variables}
          notifyOnNetworkStatusChange
          pollInterval={300000}
          key={JSON.stringify(query)}
          context={{
            queryName: `arcMapWidget_${_.snakeCase(this.props.title)}`,
          }}
        >
          {({ error, data, refetch, networkStatus }) => {
            const results = this.getData(data)
            return (
              <WidgetCard
                createdBy={this.props.widget && this.props.widget.createdBy}
                searchId={searchId}
                allowDelete={this.props.allowDelete}
                allowUpdate={this.props.allowUpdate}
                small
                onRemove={this.props.onRemove}
                onEdit={this.props.onEdit}
                toggleStatic={this.props.toggleStatic}
                static={this.props.static}
                refetch={refetch}
                networkStatus={networkStatus}
                title={this.props.title}
                filtered={filtered}
                loading={networkStatus === 1}
                error={error}
                description={_.get(this.props.resultLayout, [
                  'widgetOptions',
                  'description',
                ])}
              >
                <TopTitle style={this.styles.title}>
                  <TextLink
                    title={this.getSearchName()}
                    onClick={this.onClickSearch}
                  >
                    {this.props.title}
                  </TextLink>
                </TopTitle>
                <CardBody
                  style={{
                    display: 'grid',
                    gridTemplateRows: '1fr',
                    height: 'auto',
                    overflow: 'hidden',
                    justifyContent: 'inherit',
                    alignItems: 'inherit',
                  }}
                >
                  <ArcMap
                    arcs={this.getArcs(results)}
                    text={this.getText(results)}
                  />
                </CardBody>
              </WidgetCard>
            )
          }}
        </Query>
      )
    } else {
      const { arcs, text } = this.props.data
      return (
        <WidgetCard
          allowDelete={this.props.allowDelete}
          allowUpdate={this.props.allowUpdate}
          small
          onRemove={this.props.onRemove}
          onEdit={this.props.onEdit}
          toggleStatic={this.props.toggleStatic}
          static={this.props.static}
          title={this.props.title}
          createdBy={this.props.widget && this.props.widget.createdBy}
        >
          {' '}
          <TopTitle
            style={{
              ...this.styles.title,
              ...{ display: 'flex', alignItems: 'center' },
            }}
          >
            <TextLink>{this.props.title}</TextLink>
          </TopTitle>
          <CardBody
            style={{
              display: 'grid',
              gridTemplateRows: '1fr',
              height: 'auto',
              overflow: 'hidden',
              justifyContent: 'inherit',
              alignItems: 'inherit',
            }}
          >
            <ArcMap arcs={arcs} text={text} />
          </CardBody>
        </WidgetCard>
      )
    }
  }
}

ArcMapWidget.propTypes = {
  allowDelete: PropTypes.bool,
  allowUpdate: PropTypes.bool,
  getQueryBuilder: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onClickSearch: PropTypes.func,
  onRemove: PropTypes.func,
  toggleStatic: PropTypes.func,
  static: PropTypes.bool,
  options: PropTypes.object,
  resultLayout: PropTypes.object,
  savedSearches: ImmutablePropTypes.map.isRequired,
  sonraiSearches: ImmutablePropTypes.iterable.isRequired,
  onEdit: PropTypes.func,
  data: PropTypes.object,
  widget: PropTypes.object,
}

export default ArcMapWidget
