import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import qs from 'query-string'
import { push } from 'connected-react-router'
import { withRouter } from 'react-router'

import injectReducer from 'utils/injectReducer'
import WithPermission from 'containers/PermissionChecker/WithPermission'
import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'
import IHelp from 'containers/IHelp'

import reducer from './reducer'
import SolutionCenterTabs from './SolutionCenterTabs'
import ModalAddSolutionCard from './ModalAddSolutionCard'
import { TOOLBAR_HEIGHT } from './constants'

export class SolutionCenterCards extends React.Component {
  styles = {
    toolbar: {
      display: 'grid',
      background: '#FFFFFF',
      borderBottom: '1px solid #C0C0C0',
      fontSize: '30px',
      height: `${TOOLBAR_HEIGHT}px`,
      gridTemplateColumns: `1fr auto`,
      gridTemplateAreas: '"tabs actions"',
    },
    tabs: {
      gridArea: 'tabs',
    },
    actions: {
      gridArea: 'actions',
    },
    toolbarButton: {
      padding: '0 0.5em',
    },
  }

  state = {
    showAddSCModal: false,
  }

  toggleAddSCModal = () => {
    this.setState(currentState => ({
      showAddSCModal: !currentState.showAddSCModal,
    }))
  }

  setSolutionCard = selectedSolutionCardId => {
    const { push } = this.props

    push({
      search: qs.stringify({
        tabId: selectedSolutionCardId,
      }),
    })
  }

  render() {
    return (
      <Fragment>
        <ModalAddSolutionCard
          isOpen={this.state.showAddSCModal}
          toggle={this.toggleAddSCModal}
        />
        <div className="slide-in-top-delayed" style={this.styles.toolbar}>
          <div style={this.styles.tabs}>
            <div
              style={{
                position: 'relative',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '0px',
                  left: '0px',
                  right: '0px',
                  bottom: '0px',
                }}
              >
                <SolutionCenterTabs setSolutionCard={this.setSolutionCard} />
              </div>
            </div>
          </div>

          <div style={this.styles.actions}>
            <WithPermission
              permissionName={'edit.solutioncards'}
              resourceId={({ org }) => `/org/${org}/`}
            >
              <BorderlessButton
                onClick={this.toggleAddSCModal}
                title="Add Solution Card"
                color="primary"
                style={this.styles.toolbarButton}
              >
                <Icon fa name="plus" /> Add Card
              </BorderlessButton>
              <span style={this.styles.toolbarButton}>
                <IHelp
                  id="solutionCenterToolbarGeneral"
                  help
                  position="left"
                  helpKey="solutionCenterToolbarGeneral"
                />
              </span>
            </WithPermission>
          </div>
          <div />
        </div>
      </Fragment>
    )
  }
}

SolutionCenterCards.propTypes = {
  push: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: 'solutionCenterCards', reducer })

export default compose(
  withReducer,
  withConnect,
  withRouter
)(SolutionCenterCards)
