import React from 'react'
import PropTypes from 'prop-types'
import { Label } from 'reactstrap'

import { exists } from 'utils/sonraiUtils'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

export class Filter extends React.Component {
  constructor(props) {
    super(props)

    this.styles = {
      container: {
        padding: '0 0.3em',
        margin: '1em 0',
      },
      selected: {
        borderLeft: `2px solid ${props.theme.secondary}`,
      },
      unSelected: {
        borderLeft: `2px solid ${props.theme.light}`,
      },
    }
  }

  render() {
    return (
      <div
        style={{
          ...(exists(this.props.selectedValue)
            ? this.styles.selected
            : this.styles.unSelected),
          ...this.styles.container,
        }}
      >
        <Label>{this.props.label}</Label>
        {this.props.children}
      </div>
    )
  }
}

Filter.propTypes = {
  children: PropTypes.node,
  selectedValue: PropTypes.any,
  label: PropTypes.node,
  theme: themeShape,
}

export default themeable(Filter)
