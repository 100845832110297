import { createSelector } from 'reselect'
import { List, Map } from 'immutable'

import { selectSavedSearches } from 'containers/SonraiData/selectors'

export const selectWidgetModalDomain = state => state.get('WidgetModal')

export const selectWidgetType = createSelector(
  selectWidgetModalDomain,
  state => state.get('widgetType')
)

export const selectWidgetTitle = createSelector(
  selectWidgetModalDomain,
  state => state.get('widgetTitle')
)

export const selectWidgetSubTitle = createSelector(
  selectWidgetModalDomain,
  state => state.get('widgetSubTitle')
)

export const selectWidgetSize = createSelector(
  selectWidgetModalDomain,
  state => state.get('widgetSize', List()).toJS()
)

export const selectWidgetSelector = createSelector(
  selectWidgetModalDomain,
  state => state.get('widgetSelector')
)

export const selectWidgetResultLayout = createSelector(
  selectWidgetModalDomain,
  state => state.get('widgetResultLayout', Map()).toJS()
)

export const selectWidgetPreviewData = createSelector(
  selectWidgetModalDomain,
  state => state.get('widgetPreviewData')
)

export const selectWidgetOptions = createSelector(
  selectWidgetModalDomain,
  state => state.get('widgetOptions', Map()).toJS()
)

export const selectSearchCardsBySearchId = createSelector(
  selectSavedSearches,
  savedSearches =>
    savedSearches.map(search =>
      search.getIn(['query', 'fields'], Map()).toList()
    )
)

export const selectPreviewWidget = createSelector(
  selectWidgetModalDomain,
  state => state.get('previewWidget')
)
