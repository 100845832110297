/*
 *
 * SolutionCenter reducer
 *
 */

import { fromJS, List } from 'immutable'
import { handleActions } from 'redux-actions'
import {
  LOAD_SOLUTION_CARDS,
  TOGGLE_SC_WIDGET_STATIC,
  UPDATE_WIDGET,
  UPDATE_WIDGET_SUCCESS,
  ADD_SOLUTION_CARD_SUCCESS,
  DELETE_SOLUTION_CARD_SUCCESS,
  ADD_WIDGET_SUCCESS,
  REMOVE_WIDGET_SUCCESS,
  SET_WIDGET_OPTIONS,
  UPDATE_SOLUTION_CARD_SUCCESS,
  SET_SHOW_CREATE_WIDGET_MODAL,
  COPY_SOLUTION_CARD,
  COPY_SOLUTION_CARD_SUCCESS,
} from './constants'

const initialState = fromJS({
  solutionCards: [],
  showCreateWidgetModal: false,
})

const solutionCenterReducer = handleActions(
  {
    [ADD_SOLUTION_CARD_SUCCESS]: (state, { payload }) =>
      state
        .update('solutionCards', cards => cards.push(fromJS(payload)))
        .set('showCreateWidgetModal', true),
    [ADD_WIDGET_SUCCESS]: (state, { payload }) => {
      const solutionCardIndex = state
        .get('solutionCards')
        .findIndex(card => card.get('srn') === payload.cardsrn)

      return state
        .updateIn(['solutionCards', solutionCardIndex, 'widgets'], widgets =>
          widgets.push(fromJS(payload.widget))
        )
        .set('showCreateWidgetModal', false)
    },
    [DELETE_SOLUTION_CARD_SUCCESS]: (state, { payload }) => {
      const solutionCardIndex = state
        .get('solutionCards')
        .findIndex(card => card.get('srn') === payload)

      return state.deleteIn(['solutionCards', solutionCardIndex])
    },
    [LOAD_SOLUTION_CARDS]: (state, { payload }) => {
      return state.set('solutionCards', fromJS(payload.cards))
    },
    [REMOVE_WIDGET_SUCCESS]: (state, { payload }) => {
      const solutionCardIndex = state
        .get('solutionCards')
        .findIndex(card => card.get('srn') === payload.cardId)
      const widgetIndex = state
        .getIn(['solutionCards', solutionCardIndex, 'widgets'], List())
        .findIndex(widget => widget.get('srn') === payload.widgetId)

      return state.deleteIn([
        'solutionCards',
        solutionCardIndex,
        'widgets',
        widgetIndex,
      ])
    },
    [SET_SHOW_CREATE_WIDGET_MODAL]: (state, { payload }) =>
      state.set('showCreateWidgetModal', payload),
    [TOGGLE_SC_WIDGET_STATIC]: (state, { payload }) => {
      const solutionCardIndex = state
        .get('solutionCards')
        .findIndex(card => card.get('srn') === payload.cardId)
      const widgetIndex = state
        .getIn(['solutionCards', solutionCardIndex, 'widgets'], List())
        .findIndex(widget => widget.get('srn') === payload.widgetId)

      return state.updateIn(
        ['solutionCards', solutionCardIndex, 'widgets', widgetIndex],
        widget => widget.set('static', !widget.get('static'))
      )
    },
    [UPDATE_SOLUTION_CARD_SUCCESS]: (state, { payload }) => {
      const solutionCardIndex = state
        .get('solutionCards')
        .findIndex(card => card.get('srn') === payload.srn)

      return state.mergeIn(['solutionCards', solutionCardIndex], payload)
    },
    [UPDATE_WIDGET]: (state, { payload }) => {
      const solutionCardIndex = state
        .get('solutionCards')
        .findIndex(card => card.get('srn') === payload.cardId)
      const widgetIndex = state
        .getIn(['solutionCards', solutionCardIndex, 'widgets'], List())
        .findIndex(widget => widget.get('srn') === payload.widget.srn)

      return state.setIn(
        ['solutionCards', solutionCardIndex, 'widgets', widgetIndex],
        fromJS(payload.widget)
      )
    },
    [UPDATE_WIDGET_SUCCESS]: (state, { payload }) => {
      const solutionCardIndex = state
        .get('solutionCards')
        .findIndex(card => card.get('srn') === payload.cardsrn)
      const widgetIndex = state
        .getIn(['solutionCards', solutionCardIndex, 'widgets'], List())
        .findIndex(widget => widget.get('srn') === payload.widget.srn)

      return state.mergeIn(
        ['solutionCards', solutionCardIndex, 'widgets', widgetIndex],
        fromJS(payload.widget)
      )
    },
    [SET_WIDGET_OPTIONS]: (state, { payload }) => {
      const solutionCardIndex = state
        .get('solutionCards')
        .findIndex(card => card.get('srn') === payload.cardId)
      const widgetIndex = state
        .getIn(['solutionCards', solutionCardIndex, 'widgets'], List())
        .findIndex(widget => widget.get('srn') === payload.widgetId)

      return state.mergeIn(
        ['solutionCards', solutionCardIndex, 'widgets', widgetIndex, 'options'],
        fromJS(payload.options)
      )
    },
    [COPY_SOLUTION_CARD]: state => state.set('copySolutionCardSaving', true),
    [COPY_SOLUTION_CARD_SUCCESS]: (state, { payload }) => {
      const solutionCardIndex = state
        .get('solutionCards')
        .findIndex(card => card.get('srn') === payload.card.srn)

      return state
        .update('solutionCards', cards => cards.push(fromJS(payload.card)))
        .setIn(
          ['solutionCards', solutionCardIndex, 'widgets'],
          fromJS(payload.widgets)
        )
        .set('copySolutionCardSaving', false)
    },
  },
  initialState
)

export default solutionCenterReducer
