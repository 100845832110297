import React from 'react'
import PropTypes from 'prop-types'
import { scaleLinear } from 'd3-scale'
import ContainerDimensions from 'react-container-dimensions'
import Button from 'components/Button'
import geoObj from 'assets/world-50m.json'
import Icon from 'components/Icon'
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
  Lines,
  Line,
} from 'react-simple-maps'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

class ArcMap extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      zoom: 1.5,
      activeMarker: null,
    }

    this.styles = {
      zoomButton: {
        color: 'white',
        padding: '0 6px',
      },
      arcStyle: {
        stroke: this.props.theme.primary,
        fill: this.props.theme.primary,
        opacity: 0.8,
        strokeWidth: 1,
      },
    }
  }

  handleZoomIn = () => {
    this.setState(currentState => ({
      zoom: currentState.zoom * 1.25,
    }))
  }

  handleZoomOut = () => {
    this.setState(currentState => ({
      zoom: currentState.zoom / 1.25,
    }))
  }

  renderGeography = (geographies, projection) => {
    const baseStyle = {
      fill: this.props.theme.neutralLight,
      stroke: this.props.theme.neutralMedium,
      strokeWidth: 0.5,
      outline: 'none',
    }

    return geographies
      .filter(geo => geo.properties.NAME !== 'Antarctica')
      .map((geography, i) => (
        <Geography
          key={`geography-${i}`}
          geography={geography}
          projection={projection}
          style={{
            default: baseStyle,
            hover: baseStyle,
            pressed: baseStyle,
          }}
        />
      ))
  }

  // This funtion returns a curve command that builds a quadratic curve.
  // And depending on the line's curveStyle property it curves in one direction or the other.
  buildCurves = (scale, start, end, line) => {
    const x0 = start[0]
    const x1 = end[0]
    const y0 = start[1]
    const y1 = end[1]

    const dy = y1 - y0

    const goesUp = dy > 0

    const curve = {
      pointOfControlUp: `${x1} ${y0}`,
      pointOfControlDown: `${x0} ${y1}`,
    }[goesUp ? 'pointOfControlUp' : 'pointOfControlDown']

    const thickness = scale(line.count) / 2

    const innerCurve = {
      pointOfControlUp: `${x1} ${y0 + thickness}`,
      pointOfControlDown: `${x0} ${y1 - thickness}`,
    }[goesUp ? 'pointOfControlUp' : 'pointOfControlDown']

    return `
      M ${x0 + thickness} ${y0 + thickness}
      Q ${curve} ${x1} ${y1}
      L ${x1} ${y1}
      Q ${innerCurve} ${x0} ${y0}
      L ${x0 + thickness} ${y0 + thickness}
    `
  }

  getGradient = (conf, index) => {
    const x0 = conf.from.coordinates[0]
    const x1 = conf.to.coordinates[0]
    const dx = x1 - x0

    return (
      <linearGradient
        id={`grad-${index}`}
        //gradientTransform={dy > 0 ? 'rotate(90)' : undefined}
      >
        <stop
          stopColor={
            dx > 0 ? this.props.theme.primary : this.props.theme.emphasis
          }
        />
        <stop
          offset="100%"
          stopColor={
            dx > 0 ? this.props.theme.emphasis : this.props.theme.primary
          }
        />
      </linearGradient>
    )
  }

  getArcs = () => {
    const arcsWithCount = {}
    this.props.arcs.forEach(config => {
      const from = config.from.coordinates.join('-')
      const to = config.to.coordinates.join('-')
      if (from === '-' || to === '-') {
        return
      }

      const key = `${from},${to}`

      if (!arcsWithCount[key]) {
        arcsWithCount[key] = { ...config, count: 1 }
      } else {
        arcsWithCount[key].count = arcsWithCount[key].count + 1
      }
    })

    return Object.values(arcsWithCount)
  }

  render() {
    const arcs = this.props.combine ? this.getArcs() : this.props.arcs
    let scale

    if (this.props.combine && arcs.length > 0) {
      const counts = arcs.sort((a, b) => b.count - a.count)
      const maxLocation = counts[0]

      scale = scaleLinear()
        .domain([0, maxLocation.count])
        .range([1, 10])

      scale.clamp(true)
    }

    return (
      <div
        style={{
          height: '100%',
          display: 'grid',
          gridTemplateRows: '1fr auto',
        }}
      >
        <div>
          <ContainerDimensions>
            <ComposableMap
              onClick={this.onClick}
              defs={arcs.map((conf, index) => this.getGradient(conf, index))}
            >
              <ZoomableGroup zoom={this.state.zoom} center={[0, 30]}>
                <Geographies geography={geoObj}>
                  {this.renderGeography}
                </Geographies>

                <Lines>
                  {arcs.map((conf, index) => (
                    <Line
                      key={index}
                      preserveMarkerAspect={false}
                      line={{
                        coordinates: {
                          start: conf.from.coordinates,
                          end: conf.to.coordinates,
                        },
                        count: conf.count,
                      }}
                      style={{
                        default: {
                          ...this.styles.arcStyle,
                          stroke: `url(#grad-${index})`,
                          fill: `url(#grad-${index})`,
                        },
                        hover: {
                          ...this.styles.arcStyle,
                        },
                        pressed: {
                          ...this.styles.arcStyle,
                        },
                      }}
                      buildPath={this.buildCurves.bind(this, scale)}
                    />
                  ))}
                </Lines>
              </ZoomableGroup>
            </ComposableMap>
          </ContainerDimensions>
        </div>
        <div>
          <Button
            style={{
              ...this.styles.zoomButton,
              marginRight: '0.5rem',
            }}
            onClick={this.handleZoomIn}
            color="primary"
          >
            <Icon fa name="plus" size="sm" />
          </Button>
          <Button
            style={this.styles.zoomButton}
            onClick={this.handleZoomOut}
            color="primary"
          >
            <Icon fa name="minus" size="sm" />
          </Button>
        </div>
      </div>
    )
  }
}

ArcMap.defaultProps = {
  arcs: [],
  combine: true,
  onClickRegion: () => {},
}

ArcMap.propTypes = {
  arcs: PropTypes.array,
  combine: PropTypes.bool,
  theme: themeShape,
}

export default themeable(ArcMap)
