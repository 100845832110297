import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'
import moment from 'moment'

import {
  HANDLE_SC_ADD,
  ADD_SOLUTION_CARD_SUCCESS,
  SAVE_LAYOUT,
  SAVE_LAYOUT_SUCCESS,
  TOGGLE_SC_WIDGET_STATIC,
  UPDATE_SOLUTION_CARD,
  UPDATE_SOLUTION_CARD_SUCCESS,
  SET_WIDGET_OPTIONS,
  UPDATE_WIDGET,
} from 'containers/SolutionCenter/constants'

const initialState = fromJS({
  savingLayout: false,
  addingSolutionCard: false,
  hasUnsavedChanges: false,
  lastSaved: null,
  savingScEdits: false,
})

const solutionCenterCardsReducer = handleActions(
  {
    [ADD_SOLUTION_CARD_SUCCESS]: state =>
      state.set('addingSolutionCard', false),
    [HANDLE_SC_ADD]: state => state.set('addingSolutionCard', true),
    [SAVE_LAYOUT]: state => state.set('savingLayout', true),
    [SAVE_LAYOUT_SUCCESS]: state =>
      state
        .set('savingLayout', false)
        .set('hasUnsavedChanges', false)
        .set('lastSavedLayout', moment()),
    [SET_WIDGET_OPTIONS]: state => state.set('hasUnsavedChanges', true),
    [TOGGLE_SC_WIDGET_STATIC]: state => state.set('hasUnsavedChanges', true),
    [UPDATE_SOLUTION_CARD]: state => state.set('savingScEdits', true),
    [UPDATE_SOLUTION_CARD_SUCCESS]: state => state.set('savingScEdits', false),
    [UPDATE_WIDGET]: state => state.set('hasUnsavedChanges', true),
  },
  initialState
)

export default solutionCenterCardsReducer
