import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { Map } from 'immutable'
import { injectIntl } from 'react-intl'
import { selectShowCreateWidgetModal } from './selectors'
import { setShowCreateWidgetModal } from './actions'
import {
  selectSavingWidget,
  selectLayout,
} from 'containers/SolutionCenterWidgets/selectors'
import { selectQueryTypes, selectPivot } from 'containers/SonraiData/selectors'
import Icon from 'components/Icon'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import WidgetModal from 'containers/WidgetModal'
import Button from 'components/Button'
import { QueryBuilder } from 'query-builder'

const styles = {
  button: {
    margin: '0.15rem 0rem 0rem 0.15rem',
    padding: '0.5rem',
    fontSize: '0.8rem',
  },
}

export class SolutionCenterToolbar extends React.Component {
  toggleAddWidgetModal = () => {
    this.props.setShowCreateWidgetModal(!this.props.showCreateWidgetModal)
  }

  getQueryBuilder = fields => {
    const queryBuilder = new QueryBuilder({
      query: fields,
      types: this.props.queryTypes,
      pivot: this.props.pivot,
    })

    return queryBuilder
  }

  render() {
    return (
      <Fragment>
        <Button
          style={styles.button}
          outline
          color="primary"
          onClick={this.toggleAddWidgetModal}
        >
          <Icon
            fa
            name="plus"
            size="sm"
            style={{ margin: `0rem 0.25rem 0rem 0rem` }}
          />
          <FormattedMessage {...messages.newWidget} />
        </Button>
        <WidgetModal
          isOpen={this.props.showCreateWidgetModal}
          onClose={this.toggleAddWidgetModal}
          layout={this.props.layout}
          getQueryBuilder={this.getQueryBuilder}
          card={this.props.card.toJS()}
          saving={this.props.savingWidget}
          editingWidget={Map()}
        />
      </Fragment>
    )
  }
}

SolutionCenterToolbar.propTypes = {
  card: ImmutablePropTypes.contains({
    sid: PropTypes.string.isRequired,
    srn: PropTypes.string.isRequired,
    widgets: ImmutablePropTypes.listOf(
      ImmutablePropTypes.contains({
        sid: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        type: PropTypes.oneOf([
          'bigCount',
          'table',
          'pieChart',
          'lineChart',
          'ratio',
          'barChart',
          'list',
          'gauge',
          'map',
          'regions',
          'advMap',
          'compliance',
          'spark',
          'alert',
        ]).isRequired,
        options: ImmutablePropTypes.map,
        query: ImmutablePropTypes.map,
        selection: PropTypes.string,
        formatter: PropTypes.func,
        widgetSize: ImmutablePropTypes.list.isRequired,
        widgetLocation: ImmutablePropTypes.list.isRequired,
        static: PropTypes.bool.isRequired,
      })
    ),
  }).isRequired,
  savingWidget: PropTypes.bool,
  setShowCreateWidgetModal: PropTypes.func,
  showCreateWidgetModal: PropTypes.bool,
  layout: PropTypes.array.isRequired,
  pivot: ImmutablePropTypes.map.isRequired,
  queryTypes: ImmutablePropTypes.iterable.isRequired,
}

const mapStateToProps = createStructuredSelector({
  savingWidget: selectSavingWidget,
  layout: selectLayout,
  pivot: selectPivot,
  queryTypes: selectQueryTypes,
  showCreateWidgetModal: selectShowCreateWidgetModal,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setShowCreateWidgetModal }, dispatch)
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

export default compose(
  withConnect,
  injectIntl
)(SolutionCenterToolbar)
