import { all, put, takeLatest, take, race, call } from 'redux-saga/effects'
import { Map, List } from 'immutable'
import { fetchSavedSearches } from 'containers/SonraiData/sagas'
import { push } from 'connected-react-router'
import qs from 'query-string'
import omit from 'lodash/omit'
import { delay } from 'redux-saga'
import {
  loadSolutionCards,
  handleSCAdd,
  saveLayoutSuccess,
  addSolutionCardSuccess,
  deleteSolutionCardSuccess,
  addWidgetSuccess,
  removeWidgetSuccess,
  updateWidgetSuccess,
  updateSolutionCardSuccess,
  copySolutionCardSuccess,
} from './actions'
import {
  GET_SOLUTION_CARDS,
  UPDATE_EDIT_WIDGET,
  HANDLE_SC_ADD,
  DELETE_SOLUTION_CARD,
  ADD_SC_WIDGET,
  REMOVE_SC_WIDGET,
  SAVE_LAYOUT,
  UPDATE_SOLUTION_CARD,
  START_UPDATE_SC_POLL,
  STOP_UPDATE_SC_POLL,
  COPY_SOLUTION_CARD,
} from './constants'
import { FETCH_SOLUTION_CARDS_QUERY } from './queries'
import { getClient } from 'apolloClient'
import gql from 'graphql-tag'

function* getSolnCards() {
  try {
    const client = getClient()
    const result = yield client.query({
      query: gql`
        ${FETCH_SOLUTION_CARDS_QUERY}
      `,
    })

    if (result.data.SolutionCards.items === null) {
      throw new Error('Bad formatting of response from server: items is null')
    }

    let cards = result.data.SolutionCards.items.map(sysCard => {
      return {
        __typename: sysCard.__typename,
        sid: sysCard.sid,
        srn: sysCard.srn,
        name: sysCard.name,
        resourceId: sysCard.resourceId,
        createdDate: sysCard.createdDate,
        createdBy: sysCard.createdBy,
        widgets: sysCard.contains.items.map(widget => {
          if (widget.contains.items.length > 0) {
            return {
              ...widget,
              query: widget.contains.items[0], // TODO: Handle multiple widget queries
            }
          } else {
            return widget
          }
        }),
      }
    })

    if (cards.length === 0) {
      yield put(handleSCAdd('Dashboard 1'))
    } else {
      yield put(loadSolutionCards(cards))
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error loading solution cards', e)
  }
}

function* addSolutionCard(action) {
  try {
    const client = getClient()
    const response = yield client.mutate({
      mutation: gql`
    mutation createSolutionCard {
      CreateSolutioncard(value: {
        name: "${action.payload.name}"
      }) {
        name
        sid
        srn
        lastModified
        createdDate
        resourceId
      }
    }
    `,
      forceFetch: true,
      fetchPolicy: 'no-cache',
    })

    const newCard = response.data.CreateSolutioncard
    newCard.widgets = []

    yield put(
      push({
        search: qs.stringify({ tabId: newCard.srn }),
      })
    )

    yield put(addSolutionCardSuccess(newCard))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error adding solution card', e)
  }
}

function* deleteSolutionCard(action) {
  try {
    const client = getClient()
    yield client.mutate({
      mutation: gql`
      mutation deleteSolutionCard {
        DeleteSolutioncard(srn: "${action.payload.srn}")
      }
    `,
    })
    yield put(deleteSolutionCardSuccess(action.payload.srn))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error deleting solution card', e)
  }
}

function* addWidget(action) {
  try {
    let widg = Object.assign({}, action.payload.widget, {
      containedBy: {
        add: [action.payload.card.srn],
      },
    })

    delete widg.query
    const client = getClient()
    const result = yield client.mutate({
      mutation: gql`
        mutation addWidget($widget: WidgetCreator!) {
          CreateWidget(value: $widget) {
            sid
            srn
            label
            type
            icon
            title
            lastModified
            createdDate
            widgetSize
            subtitle
            selection
            resultLayout
            static
            options
            widgetLocation
            name
            resourceId
            contains {
              items {
                name
                sid
                srn
              }
            }
          }
        }
      `,
      variables: { widget: widg },
    })

    if (!result.data.CreateWidget) {
      throw new Error('received null in response to CreateWidget')
    }

    yield put(
      addWidgetSuccess({
        cardsrn: action.payload.card.srn,
        widget: result.data.CreateWidget,
      })
    )

    yield call(fetchSavedSearches, { poll: false })
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error adding widget to solution card', e)
  }
}

function* updateEditWidget(action) {
  const values = omit(action.payload.widget, [
    '__typename',
    'query',
    'sid',
    'srn',
    'lastModified',
    'createdDate',
    'contains',
    'name',
    'selection',
    'resourceId',
  ])

  const { srn } = action.payload.widget

  try {
    const client = getClient()
    const updateResult = yield client.mutate({
      mutation: gql`
      mutation updateWidget($widget: WidgetUpdater!) {
        UpdateWidget(srn: "${srn}", value: $widget) {
          title
          subtitle
          name
          resultLayout
          static
          type
          widgetLocation
          createdDate 
          sid
          srn
          lastModified
          widgetSize
          options
          selection
          resourceId
          contains {
            items {
              name
              sid
            }
          }
        }
      }
    `,
      variables: {
        widget: values,
      },
    })

    yield put(
      updateWidgetSuccess({
        cardsrn: action.payload.card.srn,
        widget: updateResult.data.UpdateWidget,
      })
    )
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error updating widget', e)
  }
}

function* removeWidget(action) {
  try {
    const client = getClient()
    yield client.mutate({
      mutation: gql`
    mutation delWidget{
      DeleteWidget(srn:"${action.payload.srn}")
    }
    `,
    })
    yield put(
      removeWidgetSuccess({
        cardId: action.payload.cardId,
        widgetId: action.payload.srn,
      })
    )
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error removing widget from solution card', e)
  }
}

function* saveLayout(action) {
  try {
    const widgets = action.payload.card.get('widgets')
    if (!widgets.isEmpty()) {
      const widgetsByIndex = Map(
        widgets.map((widget, index) => List([`widget${index}`, widget]))
      )

      const variables = widgetsByIndex
        .map(widget =>
          Map({
            widgetLocation: widget.get('widgetLocation'),
            static: widget.get('static'),
            widgetSize: widget.get('widgetSize'),
            options: widget.get('options'),
          })
        )
        .toJS()

      const variableNames = widgets.map(
        (widget, index) => `
      $widget${index}: WidgetUpdater!
    `
      )

      const queryStrings = widgets.map(
        (widget, index) => `
      widget${index}: UpdateWidget(
         srn: "${widget.get('srn')}",
         value: $widget${index}
      ) { sid srn }
    `
      )
      const client = getClient()
      yield client.mutate({
        mutation: gql`
      mutation updateWidgets(${variableNames.join(', ')}) {
        ${queryStrings.join(',')}
      }
    `,
        variables: variables,
      })
    }

    yield put(saveLayoutSuccess())
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error saving widget layout', e)
  }
}

function* updateSolutionCard(action) {
  try {
    const client = getClient()
    const updateResult = yield client.mutate({
      mutation: gql`
        mutation updateCard($card: SolutioncardUpdater!){
          UpdateSolutioncard(
            srn:"${action.payload.srn}"
            value: $card
          ) {
            srn,
            name,
            lastModified
          }
        }
      `,
      variables: {
        card: {
          name: action.payload.name,
        },
      },
    })

    yield put(updateSolutionCardSuccess(updateResult.data.UpdateSolutioncard))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error editing solution card', e)
  }
}

function* copySolutionCard(action) {
  const { title, widgets } = action.payload

  try {
    const client = getClient()
    const newSolutionCard = yield client.mutate({
      mutation: gql`
        mutation createSolutionCard {
          CreateSolutioncard(value: {
            name: "${title}"
          }) {
            name
            sid
            srn
            lastModified
            createdDate
            resourceId
          }
        }
      `,
    })

    if (!newSolutionCard.data) {
      throw new Error('No copied solution card returned')
    }

    const srn = newSolutionCard.data.CreateSolutioncard.srn
    const widgetsJS = widgets.toJS()

    const newWidgets = yield all(
      widgetsJS.map(widg => {
        const contains = []
        widg.contains.items.forEach(item => {
          contains.push(item.srn)
        })
        const coolWidg = {
          title: widg.title,
          subtitle: '',
          type: widg.type,
          resultLayout: widg.resultLayout,
          widgetSize: widg.widgetSize,
          widgetLocation: widg.widgetLocation,
          static: widg.static,
          contains: { add: contains },
          options: widg.options,
          containedBy: { add: [srn] },
        }

        return client.mutate({
          mutation: gql`
            mutation addWidget($widget: WidgetCreator!) {
              CreateWidget(value: $widget) {
                sid
                srn
                label
                type
                icon
                title
                lastModified
                createdDate
                widgetSize
                subtitle
                selection
                resultLayout
                static
                options
                widgetLocation
                name
                resourceId
                contains {
                  items {
                    name
                    sid
                    srn
                  }
                }
              }
            }
          `,
          variables: { widget: coolWidg },
        })
      })
    )

    const unpackedWidgets = []
    newWidgets.forEach(guy => {
      unpackedWidgets.push(guy.data.CreateWidget)
    })

    yield put(
      push({
        search: qs.stringify({
          tabId: newSolutionCard.data.CreateSolutioncard.srn,
        }),
      })
    )

    yield put(
      copySolutionCardSuccess({
        card: newSolutionCard.data.CreateSolutioncard,
        widgets: unpackedWidgets,
      })
    )
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error copying solution card', e)
  }
}

function* runSolutionCenterFetcher() {
  while (true) {
    yield call(getSolnCards)
    yield call(delay, 300000)
  }
}

function* mySaga() {
  yield all([
    takeLatest(GET_SOLUTION_CARDS, getSolnCards),
    takeLatest(HANDLE_SC_ADD, addSolutionCard),
    takeLatest(DELETE_SOLUTION_CARD, deleteSolutionCard),
    takeLatest(ADD_SC_WIDGET, addWidget),
    takeLatest(REMOVE_SC_WIDGET, removeWidget),
    takeLatest(SAVE_LAYOUT, saveLayout),
    takeLatest(UPDATE_EDIT_WIDGET, updateEditWidget),
    takeLatest(UPDATE_SOLUTION_CARD, updateSolutionCard),
    takeLatest(COPY_SOLUTION_CARD, copySolutionCard),
  ])

  while (true) {
    yield take(START_UPDATE_SC_POLL)
    yield race([call(runSolutionCenterFetcher), take(STOP_UPDATE_SC_POLL)])
  }
}

export default mySaga
