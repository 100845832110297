/**
 *
 * GaugeWidget
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Query } from 'react-apollo'
import _ from 'lodash'
import gql from 'graphql-tag'
import Gauge from './Gauge'
import uuid from 'uuid/v4'

import TextLink from 'components/TextLink'
import Card, { BottomTitle, CardBody } from 'components/Card'
import WidgetCard from 'components/WidgetCard'
import { queryHasPivotFilter } from 'query-builder'
import { getSearchIdForSonraiSearches } from 'utils/sonraiUtils'
import { Map } from 'immutable'
import {
  getFields,
  getSearchCard,
  getSelection,
  hasSonraiSearch,
  combineQueries,
  getBareSearchQueryString,
} from 'query-builder'

class GaugeWidget extends React.Component {
  constructor(props) {
    super(props)
    this.uuid = uuid()
  }

  getQuery = () => {
    const numQueryConfig = this.getQueryConfig('num')
    const denomQueryConfig = this.getQueryConfig('denom')

    return combineQueries('gaugeQuery', numQueryConfig, denomQueryConfig)
  }

  getQueryConfig = key => {
    if (hasSonraiSearch(this.props.options, key)) {
      return {
        gqlStatement: `
          ${key}: ${getBareSearchQueryString(
          this.props.options.sonraiSearches[key]
        )}
      `,
      }
    } else {
      return this.getSavedQuery(key)
    }
  }

  getSavedQuery = searchIndex => {
    const { getQueryBuilder, savedSearches, resultLayout } = this.props

    const fields = getFields(
      savedSearches,
      resultLayout.indexedSearches[searchIndex]
    )
    if (fields.isEmpty()) {
      return {}
    }

    const queryBuilder = getQueryBuilder(fields)
    queryBuilder.countsOnly()

    const rootField = queryBuilder.fields.find(
      statement => !statement.get('parentId'),
      null,
      Map()
    )

    const variables = queryBuilder.getVariables()
    const queryString = queryBuilder.buildPivotableSourceForField(
      rootField.toJS(),
      undefined,
      undefined,
      variables
    )

    return {
      gqlStatement: `${searchIndex}: ${queryString}`,
      variables,
    }
  }

  hasOnclick = () => {
    return (
      !!this.props.resultLayout.indexedSearches.onclick ||
      !!this.props.options.sonraiSearches.sonraionclick
    )
  }

  onClickSearch = () => {
    if (this.hasOnclick()) {
      this.props.onClickSearch({
        savedSearchId: this.props.resultLayout.indexedSearches.onclick,
        sonraiSearchName: this.props.options.sonraiSearches.sonraionclick,
        searchTitle: this.props.title,
      })
    }
  }

  getTitleSearchName = () => {
    if (this.hasOnclick()) {
      return hasSonraiSearch(this.props.options, 'sonraionclick')
        ? this.props.options.sonraiSearches.sonraionclick
        : this.props.savedSearches.getIn([
            this.props.resultLayout.indexedSearches.onclick,
            'name',
          ])
    }
  }

  getSelection = (searchIndex, data) => {
    if (hasSonraiSearch(this.props.options, searchIndex)) {
      return this.selectFromSonraiSearchData(searchIndex, data)
    } else {
      return this.selectFromSavedSearchData(searchIndex)
    }
  }

  selectFromSonraiSearchData = (searchIndex, data) => {
    if (
      _.isEmpty(data) ||
      _.isEmpty(data[searchIndex]) ||
      _.isEmpty(data[searchIndex].Query)
    ) {
      return []
    } else {
      let key = Object.keys(data[searchIndex].Query)[0]
      let str = `${searchIndex}.Query.${key}.count`

      return str
    }
  }

  selectFromSavedSearchData = searchIndex => {
    const fields = getFields(
      this.props.savedSearches,
      this.props.resultLayout.indexedSearches[searchIndex]
    ).toJS()

    const searchCard = getSearchCard(this.props.resultLayout, searchIndex)

    const path = getSelection(fields, searchCard, 'count')
    path[0] = searchIndex //Root select type becomes aliased to the searchIndex
    return path
  }

  getPercentValue = data => {
    const denomSelection = this.getSelection('denom', data)
    const numSelection = this.getSelection('num', data)

    const total = _.get(data, denomSelection) || 0
    const numerator = _.get(data, numSelection) || 0

    if (total === 0) {
      return 0
    }

    const value = (numerator / total) * 100

    return value > 100 ? 100 : value
  }

  getMarkers = () => {
    const { resultLayout } = this.props

    const widgetOptions = resultLayout.widgetOptions || {}
    const configuredMarkers = widgetOptions.slices || []

    const markers = configuredMarkers.map((slice, index) => {
      const previousVal = index > 0 ? configuredMarkers[index - 1].marker : 0
      return {
        start: previousVal,
        end: slice.marker,
        color: slice.color,
      }
    })

    // const gaugeLabels = configuredMarkers.map((slice, index) => {
    //   const previousVal = index > 0 ? configuredMarkers[index - 1].marker : 0
    //   return `${previousVal}% - ${slice.marker}%`
    // })

    return markers
  }

  getSearchId = () => {
    const { options, sonraiSearches, resultLayout } = this.props
    let searchObj = Map({ uiSearch: null, advancedSearch: null })
    if (hasSonraiSearch(options, 'sonraionclick')) {
      const searches = getSearchIdForSonraiSearches(options, sonraiSearches)
      searchObj = searchObj.set('advancedSearch', searches)
    } else {
      searchObj = searchObj.set(
        'uiSearch',
        resultLayout.indexedSearches.onclick
      )
    }
    return searchObj
  }

  render() {
    if (this.props.data === undefined) {
      const searchId = this.getSearchId()
      const queryConfig = this.getQuery()

      if (!queryConfig.gqlStatement) {
        return (
          <WidgetCard
            searchId={searchId}
            loading={true}
            title={this.props.title}
            allowDelete={this.props.allowDelete}
            allowUpdate={this.props.allowUpdate}
            onRemove={this.props.onRemove}
            onEdit={this.props.onEdit}
          />
        )
      }

      const filtered = queryHasPivotFilter(queryConfig.gqlStatement)
      return (
        <Query
          query={gql`
            ${queryConfig.gqlStatement}
          `}
          variables={queryConfig.variables}
          notifyOnNetworkStatusChange
          pollInterval={300000}
          key={JSON.stringify(queryConfig)}
          context={{
            queryName: `gaugeWidget_${_.snakeCase(this.props.title)}`,
          }}
        >
          {({ error, data, refetch, networkStatus }) => {
            const percentValue = this.getPercentValue(data)

            return (
              <WidgetCard
                createdBy={this.props.widget && this.props.widget.createdBy}
                searchId={searchId}
                disableToolbar={this.props.disableToolbar}
                allowDelete={this.props.allowDelete}
                allowUpdate={this.props.allowUpdate}
                onEdit={this.props.onEdit}
                onRemove={this.props.onRemove}
                toggleStatic={this.props.toggleStatic}
                static={this.props.static}
                refetch={refetch}
                networkStatus={networkStatus}
                title={
                  <TextLink
                    title={this.getTitleSearchName()}
                    onClick={this.onClickSearch}
                  >
                    {this.props.title}
                  </TextLink>
                }
                filtered={filtered}
                error={error}
                description={_.get(this.props.resultLayout, [
                  'widgetOptions',
                  'description',
                ])}
              >
                <CardBody
                  style={{
                    overflow: 'hidden',
                    height: 'auto',
                    alignItems: 'start',
                  }}
                >
                  <Gauge
                    key={this.uuid}
                    value={percentValue}
                    markers={this.getMarkers()}
                  />
                </CardBody>
                <BottomTitle>
                  <TextLink
                    title={this.getTitleSearchName()}
                    data-sonrai-id="gauge-title"
                    onClick={this.onClickSearch}
                  >
                    {this.props.title}
                  </TextLink>
                </BottomTitle>
              </WidgetCard>
            )
          }}
        </Query>
      )
    } else {
      return (
        <Card>
          <CardBody
            style={{
              overflow: 'hidden',
              height: 'auto',
              alignItems: 'start',
            }}
          >
            <Gauge
              key={this.uuid}
              value={this.props.data}
              markers={this.getMarkers()}
            />
          </CardBody>
          <BottomTitle>
            <TextLink className="gauge-title">{this.props.title}</TextLink>
          </BottomTitle>
        </Card>
      )
    }
  }
}

GaugeWidget.propTypes = {
  allowDelete: PropTypes.bool,
  allowUpdate: PropTypes.bool,
  data: PropTypes.number,
  disableToolbar: PropTypes.bool,
  getQueryBuilder: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  options: PropTypes.object,
  resultLayout: PropTypes.shape({
    indexedSearches: PropTypes.objectOf(PropTypes.string).isRequired,
    widgetOptions: PropTypes.shape({
      slices: PropTypes.arrayOf(
        PropTypes.shape({
          marker: PropTypes.number.isRequired,
          color: PropTypes.string,
        })
      ),
      checkBoxValue: PropTypes.string,
    }).isRequired,
  }).isRequired,
  savedSearches: ImmutablePropTypes.map.isRequired,
  static: PropTypes.bool,
  title: PropTypes.string,
  toggleStatic: PropTypes.func.isRequired,
  onClickSearch: PropTypes.func,
  onEdit: PropTypes.func,
  sonraiSearches: ImmutablePropTypes.iterable,
  widget: PropTypes.object,
}

export default GaugeWidget
