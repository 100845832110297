/*
 *
 * SolutionCenter constants
 *
 */

export const LOAD_SOLUTION_CARDS = 'app/SoltuionCenter/LOAD_SOLUTION_CARDS'
export const GET_SOLUTION_CARDS = 'app/SolutionCenter/GET_SOLUTION_CARDS'
export const ADD_SC_WIDGET = 'app/SolutionCenter/ADD_SC_WIDGET'
export const TOGGLE_SC_WIDGET_STATIC = 'app/SolutionCenter/TOGGLE_WIDGET_STATIC'
export const REMOVE_SC_WIDGET = 'app/SolutionCenter/REMOVE_SC_WIDGET'
export const HANDLE_TAB_EDIT = 'app/SolutionCenter/HANDLE_TAB_EDIT'
export const HANDLE_TAB_SEQ_CHANGE = 'app/SolutionCenter/HANDLE_TAB_SEQ_CHANGE'
export const HANDLE_SC_ADD = 'app/SolutionCenter/HANDLE_TAB_ADD'
export const DELETE_SOLUTION_CARD = 'app/SolutionCenter/DELETE_SOLUTION_CARD'
export const UPDATE_WIDGET = 'app/SolutionCenter/UPDATE_WIDGET'
export const UPDATE_WIDGET_SUCCESS = 'app/SolutionCenter/UPDATE_WIDGET_SUCCESS'
export const SET_WIDGET_OPTIONS = 'app/SolutionCenter/SET_WIDGET_OPTIONS'
export const SAVE_LAYOUT = 'app/SolutionCenter/SAVE_LAYOUT'
export const SAVE_LAYOUT_SUCCESS = 'app/SolutionCenter/SAVE_LAYOUT_SUCCESS'
export const REMOVE_WIDGET_SUCCESS = 'app/SolutionCenter/REMOVE_WIDGET_SUCCESS'
export const ADD_SOLUTION_CARD_SUCCESS =
  'app/SolutionCenter/ADD_SOLUTION_CARD_SUCCESS'
export const DELETE_SOLUTION_CARD_SUCCESS =
  'app/SolutionCenter/DELETE_SOLUTION_CARD_SUCCESS'
export const ADD_WIDGET_SUCCESS = 'app/SolutionCenter/ADD_WIDGET_SUCCESS'
export const UPDATE_EDIT_WIDGET = 'app/SolutionCenter/UPDATE_EDIT_WIDGET'
export const UPDATE_SOLUTION_CARD = 'app/SolutionCenter/UPDATE_SOLUTION_CARD'
export const UPDATE_SOLUTION_CARD_SUCCESS =
  'app/SolutionCenter/UPDATE_SOLUTION_CARD_SUCCESS'
export const START_UPDATE_SC_POLL = 'app/SolutionCenter/START_UPDATE_SC_POLL'
export const STOP_UPDATE_SC_POLL = 'app/SolutionCenter/STOP_UPDATE_SC_POLL'
export const SET_SHOW_CREATE_WIDGET_MODAL =
  'app/SolutionCenter/SET_SHOW_CREATE_WIDGET_MODAL'
export const COPY_SOLUTION_CARD = 'app/SolutionCenter/COPY_SOLUTION_CARD'
export const COPY_SOLUTION_CARD_SUCCESS =
  'app/SolutionCenter/COPY_SOLUTION_CARD_SUCCESS'
