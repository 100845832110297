import React, { Component } from 'react'
import Icon from 'components/Icon'
import PropTypes from 'prop-types'
import { getAlertColors } from 'utils/widgetUtils'
import messages from './messages'
import { FormattedMessage } from 'react-intl'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import SonraiCountDisplay from 'components/SonraiCountDisplay'

class SingleAlert extends Component {
  constructor(props) {
    super(props)

    this.styles = {
      alertContainer: {
        minWidth: '100%',
        border: '1px solid whitesmoke',
        display: 'flex',
        borderRadius: 10,
        minHeight: '4.25rem',
        margin: '0.25rem 0rem',
      },
      numberContainer: {
        width: '25%',
        height: '100%',
        minHeight: '4.25rem',
        minWidth: '5rem',
        padding: '0.25rem 1rem',
        display: 'flex',
        alignItems: 'center',
        borderBottomLeftRadius: 10,
        borderTopLeftRadius: 10,
        flexDirection: 'column',
        backgroundColor: getAlertColors(props.level, props.theme)
          .backgroundColor,
      },
      alertCount: {
        fontSize: '1.85rem',
        fontWeight: '500',
        color: getAlertColors(props.level, props.theme).color,
      },
      findings: {
        fontSize: '0.8rem',
        fontWeight: '400',
        color: getAlertColors(props.level, props.theme).color,
        lineHeight: 0,
      },
      secondaryContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minWidth: '270px',
      },
      policyName: {
        alignSelf: 'flex-start',
        overflow: 'hidden',
        padding: '0.8rem 0rem 0rem 0.7rem',
      },
      policyText: {
        fontSize: '0.8rem',
        margin: 0,
        width: '235px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      iconCont: {
        padding: '0rem 0.7rem 0.8rem 0.7rem',
        display: 'flex',
        alignSelf: 'flex-start',
        alignItems: 'center',
      },
      time: { fontSize: '0.65rem', marginLeft: '0.5rem' },
    }
  }
  render() {
    return (
      <div style={this.styles.alertContainer}>
        <div
          onClick={() => this.props.onClickAlert(this.props.id)}
          style={this.styles.numberContainer}
        >
          <div style={this.styles.alertCount}>
            <SonraiCountDisplay count={this.props.count} />
          </div>
          <div style={this.styles.findings}>
            <FormattedMessage {...messages.findings} />
          </div>
        </div>
        <div style={this.styles.secondaryContainer}>
          <div style={this.styles.policyName}>
            <p style={this.styles.policyText}> {this.props.name} </p>
          </div>
          <div style={this.styles.iconCont}>
            <Icon
              fa
              name="clock"
              color="#C8C8C8"
              style={{ fontSize: '1rem' }}
            />
            <div style={this.styles.time}>{this.props.time}</div>
          </div>
        </div>
      </div>
    )
  }
}

SingleAlert.propTypes = {
  id: PropTypes.string.isRequired,
  onClickAlert: PropTypes.func.isRequired,
  style: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  time: PropTypes.string.isRequired,
  theme: themeShape,
}

export default themeable(SingleAlert)
