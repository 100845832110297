import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ApexChart from 'react-apexcharts'
import _ from 'lodash'
import moment from 'moment'
import Scrollable from 'components/Scrollable'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import EmptyWidgetResults from 'components/EmptyWidgetResults'
import { colorForEntityString, stripTags } from 'utils/sonraiUtils'

const PIE_LEGEND_HEIGHT = 45

export class SizedChart extends React.Component {
  styles = {
    legendColor: {
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      marginTop: '4px',
    },
    legendEntry: {
      display: 'inline-grid',
      padding: '0 0.3em',
      fontSize: '0.9em',
      wordBreak: 'break-word',
      gridTemplateColumns: '10px 1fr',
      gridColumnGap: '0.5em',
    },
  }
  pointsAreTheSame = () => {
    return _.uniq(this.props.yValues).length === 1
  }

  getBarPieOptions = () => {
    const maxYValue = _.max(this.props.yValues)
    const numGridLines = maxYValue < 6 ? maxYValue + 1 : 6 //Default value is 6

    return {
      yaxis: {
        //If the yValues are all less than 6, apexgrid will, by default, duplicate them to get 6 gridlines
        tickAmount: numGridLines,
      },
      width: this.props.width,
      chart: {
        toolbar: {
          show: false,
        },
        animations: {
          enabled: !this.props.static,
        },
        events: {
          mounted: this.props.onChartReady,
          beforeMount: apexChartInstance => {
            this.apexChartInstance = apexChartInstance
          },
          dataPointSelection: (e, chart, options) => {
            if (
              (this.props.onClickPoint &&
                this.props.yLabels[options.dataPointIndex] !== 'null') ||
              this.props.yLabels[options.dataPointIndex] !== '-'
            ) {
              this.props.onClickPoint({
                values: this.props.yValues,
                labels: this.props.yLabels,
                index: options.dataPointIndex,
              })
            }
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: this.props.yLabels ? this.props.yLabels : [],
      },
      colors: this.props.colors || this.getPieBarChartColors(),
      labels: this.props.yLabels ? this.props.yLabels : [],
      legend: {
        show: false,
      },
      states: {
        active: {
          filter: 'lighten',
        },
      },
      plotOptions: {
        bar: {
          distributed: true,
        },
      },
    }
  }

  getRadialBarOptions = () => {
    return {
      chart: {
        type: 'radialBar',
        events: {
          mounted: this.props.onChartReady,
        },
        animations: {
          enabled: !this.props.static,
        },
      },
      colors: [this.props.theme.secondary],
      plotOptions: {
        radialBar: {
          offsetX: 0,
          offsetY: 0,
          size: undefined,
          startAngle: -135,
          endAngle: 135,
          track: {
            background: '#eaeef3',
            startAngle: -135,
            endAngle: 135,
            padding: 20,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              fontSize: '20px',
              offsetY: 7,
              show: true,
            },
          },
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          gradientToColors: [this.props.theme.secondary],
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: 'butt',
      },
    }
  }

  getSparkOptions = () => {
    return {
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        events: {
          mounted: this.props.onChartReady,
        },
        animations: {
          enabled: !this.props.static,
        },
      },
      markers: {
        size: this.props.xLabels.length <= 1 ? 5 : 0,
        hover: {
          size: this.props.xLabels.length <= 1 ? 6 : 5,
        },
      },
      grid: {
        clipMarkers: false,
        padding: {
          top: -25,
          bottom: -25,
          left: 20,
          right: 20,
        },
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: this.props.xLabels.length <= 1 ? true : false,
          },
        },
      },
      stroke: {
        width: 3,
        curve: 'smooth',
      },
      xaxis: {
        min:
          this.props.xLabels.length <= 1
            ? moment(this.props.xLabels[0]).subtract(1, 'd').format('LLL')
            : undefined,
        max:
          this.props.xLabels.length <= 1
            ? moment(this.props.xLabels[0]).add(1, 'd').format('LLL')
            : undefined,
        axisBorder: {
          show: this.props.yValues.length <= 1 ? true : false,
        },
        tooltip: {
          enabled: false,
        },
        axisTicks: {
          show: false,
        },
        type: this.props.xLabels.length <= 1 ? '' : 'datetime',
        categories: this.props.xLabels ? this.props.xLabels : [],
        labels: {
          offsetY: this.props.xLabels.length <= 1 ? -4 : -5,
        },
      },
      yaxis: {
        min: this.props.minY,
        max: this.props.yValues.length <= 1 ? 100 : this.props.maxY,
        show: this.props.yValues.length <= 1 ? true : false,
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: this.props.yValues.length <= 1 ? true : false,
        },
        labels: {
          show: false,
        },
      },
      colors: [this.props.theme.secondary, ...this.props.theme.chartColors],
      fill: {
        type: this.pointsAreTheSame() ? 'solid' : 'gradient', //If all the data values are the same, for some reason using 'gradient' will not draw a line at all
        gradient: {
          gradientToColors: [this.props.theme.emphasis],
          shade: 'light',
          type: 'vertical',
          stops: [0, 100, 100, 100],
        },
      },
      tooltip: {
        marker: {
          show: false,
        },
        fixed: {
          enabled: false,
        },
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: seriesName => seriesName,
          },
        },
      },
    }
  }

  getOptionsForType = () => {
    if (this.props.type === 'spark') {
      return this.getSparkOptions()
    }

    if (this.props.type === 'radialBar') {
      return this.getRadialBarOptions()
    }

    return this.getBarPieOptions()
  }

  getOptions = () => {
    const defaultOptions = this.getOptionsForType()

    return _.merge(defaultOptions, this.props.options)
  }

  getSeries = () => {
    if (this.props.type === 'spark') {
      return [
        {
          name: stripTags(this.props.yTitle),
          data: this.props.yValues,
        },
      ]
    }

    if (this.props.type === 'radialBar') {
      return this.props.yValues
    }

    if (this.props.type === 'bar') {
      return [
        {
          name: stripTags(this.props.yTitle),
          data: this.props.yValues,
        },
      ]
    }

    return this.props.yValues
  }

  getType = () => {
    if (this.props.type === 'spark') {
      return 'line'
    }

    return this.props.type
  }

  onHoverLegend = e => {
    let series = this.apexChartInstance.series
    series.toggleSeriesOnHover(e, e.target)
  }

  getPieBarChartColors = () => {
    return this.props.yLabels.map(label => {
      return colorForEntityString(label, this.props.typeColors)
    })
  }

  renderScrollableLegend = () => {
    const colors = this.props.colors || this.getPieBarChartColors()

    return (
      <Scrollable
        noShadow
        style={{ height: `${PIE_LEGEND_HEIGHT}px`, margin: '0 0.5em' }}
      >
        {this.props.yLabels.map((label, index) => (
          <div
            style={this.styles.legendEntry}
            key={label}
            rel={index + 1}
            onMouseMove={this.onHoverLegend}
            onMouseOut={this.onHoverLegend}
          >
            <div
              style={{
                ...this.styles.legendColor,
                backgroundColor: colors[index % colors.length],
                height: '10px',
              }}
            />
            {label}
          </div>
        ))}
      </Scrollable>
    )
  }

  hasCustomLegend = () => {
    return this.props.type === 'donut'
  }

  render() {
    const chartHeight = this.hasCustomLegend()
      ? this.props.height - PIE_LEGEND_HEIGHT
      : this.props.height

    return (
      <Fragment>
        <div
          style={{
            height: chartHeight,
            overflow: 'hidden',
            minHeight: chartHeight,
            maxHeight: chartHeight,
            ...this.props.chartWrapperStyle,
          }}
        >
          {this.props.yValues.length === 0 ? (
            <EmptyWidgetResults />
          ) : (
            <ApexChart
              options={this.getOptions()}
              series={this.getSeries()}
              type={this.getType()}
              height={chartHeight}
              width={this.props.width}
            />
          )}
        </div>
        {this.props.type === 'donut' && this.renderScrollableLegend()}
      </Fragment>
    )
  }
}

SizedChart.propTypes = {
  yTitle: '',
  yLabels: [],
  options: {},
  chartWrapperStyle: {},
}

SizedChart.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
  yTitle: PropTypes.string,
  yLabels: PropTypes.arrayOf(PropTypes.string),
  yValues: PropTypes.arrayOf(PropTypes.number),
  xLabels: PropTypes.arrayOf(PropTypes.string),
  onClickPoint: PropTypes.func,
  onChartReady: PropTypes.func,
  options: PropTypes.object,
  static: PropTypes.bool,
  theme: themeShape,
  type: PropTypes.oneOf(['bar', 'pie', 'donut', 'spark', 'radialBar'])
    .isRequired,
  typeColors: PropTypes.object,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  minY: PropTypes.number,
  maxY: PropTypes.number,
}

export default themeable(SizedChart)
