/*
 * WidgetModal Messages
 *
 * This contains all the text for the WidgetModal component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  header: {
    id: 'app.containers.WidgetModal.header',
    defaultMessage: 'Add Widget',
  },
  updateHeader: {
    id: 'app.containers.WidgetModal.updateHeader',
    defaultMessage: 'Edit Widget',
  },
  widgetPreview: {
    id: 'app.containers.WidgetModal.widgetPreview',
    defaultMessage: 'Widget Preview',
  },
})
