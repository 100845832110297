import React from 'react'
import PropTypes from 'prop-types'

import { WIDGET_TYPES } from 'appConstants'
import WidgetType, { COMPOUND_ICONS } from './WidgetType'

const styles = {
  pickerContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: '2em',
    gridRowGap: '2em',
  },
}

export const WidgetTypePicker = ({ selectType }) => {
  return (
    <div>
      <p>
        Widgets can display the results of saved searches in multiple different
        formats, or can display Control Framework status and summary
        information. Select a display type to configure a new widget
      </p>
      <div style={styles.pickerContainer}>
        <WidgetType
          label="Big Count"
          onClick={() => selectType(WIDGET_TYPES.BIG_COUNT)}
          compoundIcon={COMPOUND_ICONS.BIG_STAT}
          tooltipContent="The count of results returned by a saved search"
        />
        <WidgetType
          label="Table"
          icon="table"
          onClick={() => selectType(WIDGET_TYPES.TABLE)}
          tooltipContent="Display the results of a saved search as a table. You can save the table configuration, such as grouping and hidden columns."
        />
        <WidgetType
          label="Ratio"
          icon="divide"
          transformIcon="rotate-125"
          onClick={() => selectType(WIDGET_TYPES.RATIO)}
          tooltipContent="A comparison of the count of results returned by two saved searches. May be formatted as a percentage, a ratio, or an average."
        />
        <WidgetType
          label="Gauge"
          icon="tachometer"
          onClick={() => selectType(WIDGET_TYPES.GAUGE)}
          tooltipContent="Display the results of two saved search as a percentage on a gauge. You can configure the marked ranges on the gauge."
        />
        <WidgetType
          label="List"
          icon="list-ol"
          onClick={() => selectType(WIDGET_TYPES.LIST)}
          tooltipContent="A numbered list of the first results returned from a saved search. You can configure how many items to show and which field will be displayed"
        />
        <WidgetType
          label="Regions"
          icon="globe-americas"
          onClick={() => selectType(WIDGET_TYPES.REGIONS)}
          tooltipContent="A map of cloud region data returned from an advanced search. These searches must be specially formatted and named. "
        />
        <WidgetType
          label="Pie Chart"
          icon="chart-pie"
          onClick={() => selectType(WIDGET_TYPES.PIE_CHART)}
          tooltipContent="A pie chart that displays all the unique result values of the selected field in a saved search"
        />
        <WidgetType
          label="Bar Chart"
          icon="chart-bar"
          onClick={() => selectType(WIDGET_TYPES.BAR_CHART)}
          tooltipContent="A bar chart that displays all the unique result values of the selected field in a saved search"
        />
        <WidgetType
          label="Arc Map"
          icon="globe"
          onClick={() => selectType(WIDGET_TYPES.ADVMAP)}
          tooltipContent="An map of activity data returned from an advanced search. These searches must be specially formatted and named. "
        />

        {/* 
          ---------These are out for now------------
        <WidgetType
          label="Alerts"
          icon="alert"
          onClick={() => selectType(WIDGET_TYPES.ALERT)}
          tooltipContent="A list of the active alerts for a control framework"
        />
        <WidgetType
          label="Compliance over Time"
          icon="spark"
          onClick={() => selectType(WIDGET_TYPES.SPARK)}
          tooltipContent="A chart of the historical compliance level for a control framework"
        />
        <WidgetType
          label="Current Compliance"
          icon="compliance"
          onClick={() => selectType(WIDGET_TYPES.COMPLIANCE)}
          tooltipContent="A gauge showing the current compliance percentage of a control framework"
        /> 
          ---------These are out for now------------
        */}
        <WidgetType
          label="Text"
          icon="pencil-alt"
          onClick={() => selectType(WIDGET_TYPES.TEXT)}
          tooltipContent="A customizable text widget"
        />
      </div>
    </div>
  )
}

WidgetTypePicker.propTypes = {
  selectType: PropTypes.func.isRequired,
}

export default WidgetTypePicker
