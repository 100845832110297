import React from 'react'
import PropTypes from 'prop-types'

const styles = {
  previewContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fafafa',
    borderRadius: '5px',
    padding: '2em',
  },
  previewTitle: {
    marginBottom: '1em',
    color: '#aaa',
  },
}

export const PreviewContainer = ({ children }) => {
  return (
    <div style={styles.previewContainer}>
      <p style={styles.previewTitle}>Preview</p>
      {children}
    </div>
  )
}

PreviewContainer.propTypes = {
  children: PropTypes.node,
}

export default PreviewContainer
