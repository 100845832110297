import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { SUPPORT_EMAIL } from 'appConstants'
import Error from 'components/Error'
import messages from './messages'

export default class SolutionCenterErrorBoundry extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      errorMessage: '',
      errorSource: '',
      componentStack: '',
      errorStack: '',
    }
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      errorMessage: error.message,
      errorStack: error.stack,
      componentStack: info.componentStack,
      errorSource: error.source ? error.source.body : '',
    })

    // eslint-disable-next-line no-console
    console.error({ json: JSON.stringify(this.state), ...this.state })
  }

  render() {
    if (this.state.hasError) {
      return (
        <Error
          description={
            <div>
              <p>
                <FormattedMessage {...messages.error} />
              </p>
              <p>
                <FormattedMessage {...messages.errorContact} />{' '}
                <a href={`mailto: ${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
              </p>
            </div>
          }
          errorMessage={this.state.errorMessage}
        />
      )
    }

    return this.props.children
  }
}

SolutionCenterErrorBoundry.propTypes = {
  children: PropTypes.node,
}
