import { fromJS, Map } from 'immutable'
import { handleActions } from 'redux-actions'
import {
  SET_SELECTED_PIVOT,
  CLEAR_SELECTED_PIVOTS,
  ADD_TAG_FILTER,
} from './constants'

const initialState = fromJS({
  selectedPivots: {},
  tagFilters: [],
})

const solutionCenterReducer = handleActions(
  {
    [ADD_TAG_FILTER]: (state, { payload }) =>
      state.updateIn(['tagFilters'], tagFilters => tagFilters.push(payload)),
    [CLEAR_SELECTED_PIVOTS]: state => state.set('selectedPivots', Map()),
    [SET_SELECTED_PIVOT]: (state, { payload }) => {
      return state.setIn(
        ['selectedPivots', payload.type],
        fromJS(payload.value)
      )
    },
  },
  initialState
)

export default solutionCenterReducer
