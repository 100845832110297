import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import SelectBar from 'components/SelectBar'
import _ from 'lodash'
import { List } from 'immutable'
import FormLabel from 'components/FormLabel'
import { QueryBuilder } from 'query-builder'
import {
  getFields,
  getTableSavedSearchQuery,
  getSelection,
  getSonraiSearchQueryString,
  getSonraiSearchData,
} from 'query-builder'
import CombinedSearches from 'components/CombinedSearches'
import { flattenData } from 'utils/sonraiUtils'
import PieChartWidget from 'components/PieChartWidget'
import PreviewContainer from './PreviewContainer'

const styles = {
  wrapperWithPreview: {
    display: 'grid',
    gridTemplateColumns: '35% 1fr',
    gridColumnGap: '2em',
  },
}

class PieChartWidgetConfig extends React.Component {
  componentDidUpdate() {
    this.updateValidity()
  }

  updateValidity = () => {
    const hasSonraiSearch = !!this.props.widgetSonraiSearches.pie
    const hasSavedSearch =
      !!this.props.widgetSavedSearches.pie && !!this.props.widgetSearchCards.pie
    const hasTitle = this.props.widgetTitle

    const hasDisplayField = !!this.props.widgetOptions.properties

    const valid =
      (hasSonraiSearch || hasSavedSearch) && hasTitle && hasDisplayField

    this.props.setValidity(valid)
  }

  setTitle = e => {
    this.props.setWidgetTitle(e.target.value)
  }

  setDescription = e => {
    const string = e.target.value

    this.props.setWidgetOptions({
      description: string,
    })
  }

  setSavedSearch = selectedOption => {
    this.props.setWidgetSavedSearch(
      selectedOption ? selectedOption.value : null,
      'pie'
    )

    this.setListField(null)
  }

  setSearchField = selectedOption => {
    this.props.setWidgetSearchField(
      selectedOption ? selectedOption.value : null,
      'pie'
    )

    this.setListField(null)
  }

  setSonraiSearch = selectedValue => {
    this.props.setWidgetSonraiSearch(
      selectedValue ? selectedValue.value : undefined,
      'pie'
    )

    this.setListField(null)
  }

  setListField = selectedOption => {
    this.props.setWidgetOptions({
      ...this.props.widgetOptions,
      properties: selectedOption,
    })
  }

  getQueryBuilder = query => {
    return new QueryBuilder({
      query,
      types: this.props.queryTypes,
    })
  }

  getSearchQuery = () => {
    if (this.props.widgetSonraiSearches.pie) {
      return getSonraiSearchQueryString(this.props.widgetSonraiSearches.pie)
    } else if (
      this.props.widgetSavedSearches['pie'] &&
      !!this.getSearchCard()
    ) {
      const queryConfig = getTableSavedSearchQuery(
        this.getQueryBuilder,
        this.props.savedSearches,
        this.props.widgetSavedSearches['pie'],
        { limit: 1 }
      )

      return queryConfig.gqlStatement
    }
  }

  getSearchCard = () => {
    return this.props.widgetSearchCards['pie']
  }

  getSampleItemFromData = data => {
    if (this.props.widgetSonraiSearches.pie) {
      const results = getSonraiSearchData(data).items
      return results ? flattenData(results)[0] : {}
    } else {
      const fields = getFields(
        this.props.savedSearches,
        this.props.widgetSavedSearches['pie']
      ).toJS()

      const path = getSelection(fields, this.getSearchCard(), 'items')

      const results = _.get(data, path) || []
      return results.length > 0 ? results[0] : {}
    }
  }

  renderPropertyDropDown = () => {
    const query = this.getSearchQuery()

    if (!query) {
      return (
        <SelectBar
          isClearable
          name="selectField"
          id="selectField"
          placeholder="Search Field..."
          disabled
        />
      )
    }

    return (
      <Query
        query={gql`
          ${query}
        `}
        notifyOnNetworkStatusChange
        variables={{ limit: 1 }}
      >
        {({ data, networkStatus }) => {
          if (networkStatus === 1) {
            return (
              <SelectBar
                isClearable
                name="selectField"
                id="selectField"
                placeholder="Loading..."
                disabled
              />
            )
          }

          if (!_.isEmpty(data)) {
            const item = this.getSampleItemFromData(data)

            if (!_.isEmpty(item)) {
              const fields = Object.keys(item).map(val => ({
                label: val,
                value: val,
              }))

              return (
                <SelectBar
                  isClearable
                  name="selectField"
                  id="selectField"
                  value={_.get(this.props.widgetOptions.properties, 'value')}
                  options={fields}
                  onChange={this.setListField}
                />
              )
            }
          }

          return (
            <SelectBar
              isClearable
              name="selectField"
              id="selectField"
              placeholder="Please select a search that has data"
              disabled
            />
          )
        }}
      </Query>
    )
  }

  render() {
    const sonraiSearchName = this.props.widgetSonraiSearches['pie']
    const savedSearchId = this.props.widgetSavedSearches['pie']
    const savedSearchField = this.props.widgetSearchCards['pie']
    const searchFieldsList = this.props.searchCards.get(
      this.props.widgetSavedSearches['pie'],
      List()
    )

    return (
      <div style={this.props.previewWidget ? styles.wrapperWithPreview : {}}>
        {this.props.previewWidget && (
          <PreviewContainer>
            <div
              style={{ minWidth: '215px', height: '240px', maxHeight: '240px' }}
            >
              <PieChartWidget
                disableToolbar
                data={
                  !_.isEmpty(this.props.widgetSavedSearches) &&
                  !_.isEmpty(this.props.widgetSearchCards) &&
                  !_.isEmpty(this.props.widgetOptions.properties)
                    ? undefined
                    : { labels: ['Data', 'Sample'], slices: [33, 66] }
                }
                getQueryBuilder={this.props.getQueryBuilder}
                savedSearches={this.props.savedSearches}
                resultLayout={{
                  widgetOptions: this.props.widgetOptions,
                  indexedSearches: this.props.widgetSavedSearches,
                  searchCards: this.props.widgetSearchCards,
                }}
                options={{ sonraiSearches: this.props.widgetSonraiSearches }}
                title={
                  this.props.widgetTitle ? (
                    this.props.widgetTitle
                  ) : (
                    <span style={{ fontStyle: 'italic' }}>Widget Title</span>
                  )
                }
              />
            </div>
          </PreviewContainer>
        )}
        <div>
          <FormLabel label="Title" for="widgetTitle" required={true} />
          <Input
            type="text"
            name="widgetTitle"
            id="widgetTitle"
            placeholder="Title"
            value={this.props.widgetTitle || ''}
            onChange={this.setTitle}
          />

          <FormLabel label="Description" for="widgetDescription" />
          <Input
            id="widgetDescription"
            type="textarea"
            name="widgetDescription"
            onChange={this.setDescription}
            value={this.props.widgetOptions.description || ''}
          />

          <FormLabel label="Search" for="widgetSearch" required={true} />
          <CombinedSearches
            savedSonraiSearches={this.props.savedSonraiSearches}
            setSonraiSearch={this.setSonraiSearch}
            selectedSonraiValue={sonraiSearchName}
            savedSearches={this.props.savedSearches}
            selectedSearchId={savedSearchId}
            selectSavedSearch={this.setSavedSearch}
            savedSearchDisabled={!!sonraiSearchName}
            selectedFieldId={savedSearchField ? savedSearchField.id : null}
            searchCards={searchFieldsList}
            searchFieldDisabled={searchFieldsList.isEmpty()}
            selectSearchField={this.setSearchField}
            searchFieldRequired
          />

          <FormLabel label="Display Field" for="selectField" required={true} />
          {this.renderPropertyDropDown()}
        </div>
      </div>
    )
  }
}

PieChartWidgetConfig.propTypes = {
  getQueryBuilder: PropTypes.func,
  previewWidget: PropTypes.bool,
  queryTypes: ImmutablePropTypes.map,
  savedSearches: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      sid: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  savedSonraiSearches: ImmutablePropTypes.list,
  searchCards: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  setWidgetSavedSearch: PropTypes.func,
  setWidgetSearchField: PropTypes.func,
  setWidgetSonraiSearch: PropTypes.func,
  setWidgetOptions: PropTypes.func,
  setWidgetTitle: PropTypes.func,
  setValidity: PropTypes.func,
  widgetOptions: PropTypes.shape({
    items: PropTypes.array,
    properties: PropTypes.array,
    description: PropTypes.string,
  }).isRequired,
  widgetSavedSearches: PropTypes.objectOf(PropTypes.array),
  widgetSearchCards: PropTypes.objectOf(PropTypes.object),
  widgetSonraiSearches: PropTypes.object,
  widgetTitle: PropTypes.string,
}

export default PieChartWidgetConfig
