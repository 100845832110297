import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'

import {
  ADD_SC_WIDGET,
  UPDATE_EDIT_WIDGET,
  UPDATE_WIDGET_SUCCESS,
  ADD_WIDGET_SUCCESS,
} from 'containers/SolutionCenter/constants'

const initialState = fromJS({
  savingWidget: false,
})

const SolutionCenterWidgetsReducer = handleActions(
  {
    [ADD_SC_WIDGET]: state => state.set('savingWidget', true),
    [ADD_WIDGET_SUCCESS]: state => state.set('savingWidget', false),
    [UPDATE_EDIT_WIDGET]: state => state.set('savingWidget', true),
    [UPDATE_WIDGET_SUCCESS]: state => state.set('savingWidget', false),
  },
  initialState
)

export default SolutionCenterWidgetsReducer
