import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'

import TextLink from 'components/TextLink'
import Icon from 'components/Icon'
import FormLabel from 'components/FormLabel'
import Button from 'components/Button'
import { updateSolutionCard } from 'containers/SolutionCenter/actions'

import {
  selectSavingScEdits,
  selectSelectedCardName,
} from 'containers/SolutionCenterCards/selectors'
import messages from './messages.js'

export class ModalRenameSC extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: props.originalName,
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.savingScEdits && !this.props.savingScEdits) {
      this.props.toggle()
    }

    if (oldProps.originalName !== this.props.originalName) {
      this.setState({
        name: this.props.originalName,
      })
    }
  }

  setName = e => {
    this.setState({
      name: e.target.value,
    })
  }

  updateSolutionCard = () => {
    this.props.updateSolutionCard({
      srn: this.props.editCardId,
      name: this.state.name,
    })
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>
          Rename Solution Card
        </ModalHeader>
        <ModalBody>
          <FormLabel for="scTitle" required>
            New Name
          </FormLabel>
          <Input
            type="text"
            name="scTitle"
            id="scTitle"
            value={this.state.name}
            onChange={this.setName}
          />
        </ModalBody>
        <ModalFooter>
          <TextLink color="primary" onClick={this.props.toggle}>
            <FormattedMessage {...messages.cancelScEditButton} />
          </TextLink>
          <Button
            disabled={this.props.savingScEdits || !this.state.name}
            color="primary"
            onClick={this.updateSolutionCard}
          >
            {this.props.savingScEdits ? (
              <Icon spin fa name="sync" transform="shrink-3" />
            ) : (
              <FormattedMessage {...messages.saveScEditButton} />
            )}
          </Button>{' '}
        </ModalFooter>
      </Modal>
    )
  }
}

ModalRenameSC.propTypes = {
  isOpen: PropTypes.bool,
  originalName: PropTypes.string,
  savingScEdits: PropTypes.bool,
  toggle: PropTypes.func,
  updateSolutionCard: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  originalName: selectSelectedCardName,
  savingScEdits: selectSavingScEdits,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateSolutionCard,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

export default compose(withConnect)(ModalRenameSC)
