import { createSelector } from 'reselect'
import qs from 'query-string'
import { fromJS, List } from 'immutable'
import { Map } from 'immutable'

import { selectCards } from 'containers/SolutionCenter/selectors'
import { sortCards } from 'utils/sonraiUtils'
import { selectUserProfile } from 'containers/UserProfileData/selectors'

const selectSolutionCenterCardsDomain = state =>
  state.get('solutionCenterCards') || Map()

export const selectAddingSolutionCard = createSelector(
  selectSolutionCenterCardsDomain,
  state => state.get('addingSolutionCard')
)

export const selectLastSaved = createSelector(
  selectSolutionCenterCardsDomain,
  state => {
    return state.get('lastSaved')
  }
)

export const selectHasUnsavedChanges = createSelector(
  selectSolutionCenterCardsDomain,
  solutionCenter => solutionCenter.get('hasUnsavedChanges', false)
)

export const selectSavingScEdits = createSelector(
  selectSolutionCenterCardsDomain,
  solutionCenter => solutionCenter.get('savingScEdits', false)
)

export const selectSavingLayout = createSelector(
  selectSolutionCenterCardsDomain,
  solutionCenter => solutionCenter.get('savingLayout', false)
)

export const selectSortedCards = createSelector(
  [selectCards, selectUserProfile],
  (cards, userProfile) => {
    const hiddenCards = userProfile.get('hiddenSolutionCards', List())

    const visibleCards = cards.filterNot(card =>
      hiddenCards.includes(card.get('srn'))
    )

    return fromJS(sortCards(visibleCards.toJS()))
  }
)

export const selectSortedCardIds = createSelector(
  [selectSortedCards],
  cards => cards.map(card => card.get('srn'))
)

export const selectUrlTabId = state => {
  const search = state.getIn(['router', 'location', 'search']) || ''
  return qs.parse(search).tabId
}

export const selectSelectedCardIndex = createSelector(
  [selectUrlTabId, selectSortedCards],
  (tabId, cards) => {
    if (tabId) {
      const cardIndex = cards.findIndex(card => card.get('srn') === tabId)
      if (cardIndex < 0) {
        return 0
      } else {
        return cardIndex
      }
    } else {
      return 0
    }
  }
)

export const selectSelectedCard = createSelector(
  [selectSortedCards, selectSelectedCardIndex],
  (cards, index) => cards.get(index, Map())
)

export const selectSelectedCardSrn = createSelector(
  selectSelectedCard,
  card => card.get('srn')
)

export const selectSelectedCardName = createSelector(
  selectSelectedCard,
  card => card.get('name')
)
