import React from 'react'
import PropTypes from 'prop-types'
import { Input, Label } from 'reactstrap'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import { Table, Form } from 'reactstrap'
import _ from 'lodash'

import TextLink from 'components/TextLink'
import Button from 'components/Button'
import ColorPicker from 'components/ColorPicker'
import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'
import CombinedSearches from 'components/CombinedSearches'
import FormLabel from 'components/FormLabel'
import GaugeWidget from 'components/GaugeWidget'
import PreviewContainer from './PreviewContainer'

const styles = {
  cancelAddSlice: {
    marginLeft: '0.5em',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '50% 1fr',
    gridColumnGap: '1em',
  },
  sectionTitle: {
    fontSize: '1em',
    fontWeight: '400',
    margin: '0',
    padding: '1em 0 0 0',
  },
  colorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  addSliceButton: {
    display: 'flex',
    alignItems: 'center',
  },
  addSliceIcon: {
    marginRight: '0.5em',
    fontSize: '20px',
  },
  configs: {
    marginTop: '1em',
    backgroundColor: '#fff',
    borderBottom: '1px solid #dee2e6',
  },
  addMarkerContainer: {
    display: 'grid',
    gridTemplateColumns: '30% 1fr',
    padding: '0.5em 0',
  },
  markerInput: {
    maxWidth: '100px',
  },
  wrapperWithPreview: {
    display: 'grid',
    gridTemplateColumns: '35% 1fr',
    gridColumnGap: '2em',
  },
  config: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '2em',
  },
}

export class GaugeConfig extends React.Component {
  constructor(props) {
    super(props)

    !this.props.editMode
      ? this.props.setWidgetOptions({
          slices: [{ marker: 100 }],
          checkBoxValue: '',
        })
      : null

    this.state = {
      showAddSlice: false,
      newSliceMarker: '',
      newSliceColor: '',
      denom: false,
      num: false,
    }
  }

  componentDidUpdate() {
    this.updateValidity()
  }

  updateValidity = () => {
    const hasNumSonraiSearch = !!this.props.widgetSonraiSearches.num
    const hasNumSavedSearch =
      !!this.props.widgetSavedSearches.num && !!this.props.widgetSearchCards.num

    const hasDenomSonraiSearch = !!this.props.widgetSonraiSearches.denom
    const hasDenomSavedSearch =
      !!this.props.widgetSavedSearches.denom &&
      !!this.props.widgetSearchCards.denom

    const hasValidNumSearch = hasNumSavedSearch || hasNumSonraiSearch
    const hasValidDenomSearch = hasDenomSavedSearch || hasDenomSonraiSearch

    const hasTitle = this.props.widgetTitle

    const valid = hasValidNumSearch && hasValidDenomSearch && hasTitle
    this.props.setValidity(valid)
  }

  setTitle = e => {
    this.props.setWidgetTitle(e.target.value)
  }

  setDescription = e => {
    const string = e.target.value

    this.props.setWidgetOptions({
      description: string,
    })
  }

  setNumeratorSearch = option => {
    this.props.setWidgetSavedSearch(option ? option.value : null, 'num')
  }

  setDenominatorSearch = option => {
    this.props.setWidgetSavedSearch(option ? option.value : null, 'denom')
  }

  setOnclickSearch = option => {
    this.props.setWidgetSavedSearch(option ? option.value : null, 'onclick')
  }

  setSonraiOnclickSearch = selectedOption => {
    this.props.setWidgetSonraiSearch(
      selectedOption ? selectedOption.value : null,
      'sonraionclick'
    )
  }

  setNumeratorField = option => {
    this.props.setWidgetSearchField(option ? option.value : null, 'num')
  }

  setDenominatorField = option => {
    this.props.setWidgetSearchField(option ? option.value : null, 'denom')
  }

  setSonraiSearchDenom = selectedOption => {
    this.props.setWidgetSonraiSearch(
      selectedOption ? selectedOption.value : null,
      'denom'
    )
  }

  setSonraiSearchNum = selectedOption => {
    this.props.setWidgetSonraiSearch(
      selectedOption ? selectedOption.value : null,
      'num'
    )
  }

  editSliceColor = (sliceId, newColor) => {
    const slices = [...this.props.widgetOptions.slices]
    slices[sliceId].color = newColor

    this.props.setWidgetOptions({
      ...this.props.widgetOptions,
      slices,
    })
  }

  renderSlicesConfig = () => {
    const slices = this.props.widgetOptions.slices || []
    return (
      <Table striped style={styles.configs}>
        <tbody>
          {slices.map((slice, index) => (
            <tr key={slice.marker}>
              <td>
                <BorderlessButton
                  disabled={slice.marker >= 100}
                  onClick={() => this.removeMarker(index)}
                >
                  <Icon fa name="trash" size="1x" />
                </BorderlessButton>
              </td>
              <td>Ending at {slice.marker}%</td>

              <td style={styles.colorContainer}>
                <ColorPicker
                  onPickColor={this.editSliceColor.bind(this, index)}
                  color={slice.color}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  removeMarker = index => {
    const slices = [...this.props.widgetOptions.slices]

    if (slices[index].marker === 100) {
      return
    }

    slices.splice(index, 1)
    this.props.setWidgetOptions({
      ...this.props.widgetOptions,
      slices,
    })
  }

  addMarker = () => {
    const value = parseInt(this.state.newSliceMarker)
    if (value < 0 || value > 100) {
      return
    }

    const color = this.state.newSliceColor

    const slices = [...this.props.widgetOptions.slices]

    slices.push({
      marker: value,
      color,
    })

    slices.sort((a, b) => a.marker - b.marker)

    this.props.setWidgetOptions({
      ...this.props.widgetOptions,
      slices,
    })

    this.resetAddSlice()
  }

  toggleAddSlice = () => {
    this.setState(state => ({
      showAddSlice: !state.showAddSlice,
    }))
  }

  resetAddSlice = () => {
    this.setState({
      showAddSlice: false,
      newSliceMarker: '',
      newSliceColor: '',
    })
  }

  setNewSliceMarker = e => {
    const intValue = parseInt(e.target.value)
    if (intValue > 100 || intValue < 0) {
      return
    }

    this.setState({
      newSliceMarker: e.target.value,
    })
  }

  onPickColor = color => {
    this.setState({
      newSliceColor: color,
    })
  }

  renderAddNewSlice = () => {
    return (
      <Form>
        <div style={styles.addMarkerContainer}>
          <Label for="newSliceMarker">Slice end percentage</Label>
          <div>
            <Input
              name="newSliceMarker"
              min={0}
              max={100}
              type="number"
              value={this.state.newSliceMarker}
              onChange={this.setNewSliceMarker}
              style={styles.markerInput}
            />
          </div>
        </div>

        <div style={styles.addMarkerContainer}>
          <Label>Slice color</Label>
          <ColorPicker
            onPickColor={this.onPickColor}
            color={this.state.newSliceColor}
          />
        </div>

        <Button
          color="primary"
          disabled={!this.state.newSliceMarker}
          onClick={this.addMarker}
        >
          Add Slice
        </Button>
        <TextLink
          style={styles.cancelAddSlice}
          color="secondary"
          onClick={this.resetAddSlice}
        >
          Cancel
        </TextLink>
      </Form>
    )
  }

  render() {
    const denominatorSearchId = this.props.widgetSavedSearches['denom']
    const denominatorField = this.props.widgetSearchCards['denom']

    const numeratorSearchId = this.props.widgetSavedSearches['num']
    const numeratorField = this.props.widgetSearchCards['num']

    const onclickSearchId = this.props.widgetSavedSearches['onclick']
    const sonraiOnclickSearch = this.props.widgetSonraiSearches['sonraionclick']

    const numeratorSonraiSearchName = this.props.widgetSonraiSearches['num']
    const denomSonraiSearchName = this.props.widgetSonraiSearches['denom']

    const denomSearchFieldsList = this.props.searchCards.get(
      denominatorSearchId,
      List()
    )
    const numSearchFieldsList = this.props.searchCards.get(
      numeratorSearchId,
      List()
    )

    return (
      <div style={this.props.previewWidget ? styles.wrapperWithPreview : {}}>
        {this.props.previewWidget && (
          <PreviewContainer>
            <div
              style={{ minWidth: '225px', height: '225px', maxHeight: '225px' }}
            >
              <GaugeWidget
                disableToolbar
                data={
                  !_.isEmpty(this.props.widgetSavedSearches.num) &&
                  !_.isEmpty(this.props.widgetSavedSearches.denom) &&
                  !_.isEmpty(this.props.widgetSearchCards)
                    ? undefined
                    : 20
                }
                getQueryBuilder={this.props.getQueryBuilder}
                savedSearches={this.props.savedSearches}
                resultLayout={{
                  widgetOptions: this.props.widgetOptions,
                  indexedSearches: this.props.widgetSavedSearches,
                  searchCards: this.props.widgetSearchCards,
                }}
                options={{ sonraiSearches: this.props.widgetSonraiSearches }}
                title={
                  this.props.widgetTitle ? (
                    this.props.widgetTitle
                  ) : (
                    <span style={{ fontStyle: 'italic' }}>Widget Title</span>
                  )
                }
              />
            </div>
          </PreviewContainer>
        )}

        <div style={styles.config}>
          <div>
            <FormLabel label="Title" for="widgetTitle" required={true} />
            <Input
              type="text"
              name="widgetTitle"
              id="widgetTitle"
              placeholder="Number of Identities"
              value={this.props.widgetTitle || ''}
              onChange={this.setTitle}
            />

            <FormLabel label="Description" for="widgetDescription" />
            <Input
              id="widgetDescription"
              type="textarea"
              name="widgetDescription"
              onChange={this.setDescription}
              value={this.props.widgetOptions.description || ''}
            />

            <FormLabel
              label="Target Count (Numerator)"
              for="widgetSearchNum"
              required={true}
            />
            <CombinedSearches
              name="widgetSearchNum"
              savedSonraiSearches={this.props.savedSonraiSearches}
              setSonraiSearch={this.setSonraiSearchNum}
              selectedSonraiValue={numeratorSonraiSearchName}
              savedSearches={this.props.savedSearches}
              selectedSearchId={numeratorSearchId}
              selectSavedSearch={this.setNumeratorSearch}
              savedSearchDisabled={!!numeratorSonraiSearchName}
              selectedFieldId={numeratorField ? numeratorField.id : null}
              searchCards={numSearchFieldsList}
              searchFieldDisabled={numSearchFieldsList.isEmpty()}
              selectSearchField={this.setNumeratorField}
              searchFieldRequired={true}
            />

            <FormLabel
              label="Total Count (Denominator)"
              for="widgetSearchDenom"
              required={true}
            />
            <CombinedSearches
              savedSonraiSearches={this.props.savedSonraiSearches}
              setSonraiSearch={this.setSonraiSearchDenom}
              selectedSonraiValue={denomSonraiSearchName}
              savedSearches={this.props.savedSearches}
              selectedSearchId={denominatorSearchId}
              selectSavedSearch={this.setDenominatorSearch}
              savedSearchDisabled={!!denomSonraiSearchName}
              selectedFieldId={denominatorField ? denominatorField.id : null}
              searchCards={denomSearchFieldsList}
              searchFieldDisabled={denomSearchFieldsList.isEmpty()}
              selectSearchField={this.setDenominatorField}
              searchFieldRequired={true}
            />

            <FormLabel
              label="Search Linked from Widget Title"
              for="widgetSearchNav"
            />
            <CombinedSearches
              name="widgetNavSearch"
              savedSearches={this.props.savedSearches}
              selectedSearchId={onclickSearchId}
              selectSavedSearch={this.setOnclickSearch}
              savedSonraiSearches={this.props.savedSonraiSearches}
              setSonraiSearch={this.setSonraiOnclickSearch}
              selectedSonraiValue={sonraiOnclickSearch}
            />
          </div>

          <div>
            <h3 style={styles.sectionTitle}>Slices</h3>
            {this.renderSlicesConfig()}
            {this.state.showAddSlice ? (
              this.renderAddNewSlice()
            ) : (
              <TextLink
                color="secondary"
                style={styles.addSliceButton}
                onClick={this.toggleAddSlice}
              >
                <Icon name="plus" fa style={styles.addSliceIcon} /> Add new
                slice
              </TextLink>
            )}
          </div>
        </div>
      </div>
    )
  }
}

GaugeConfig.propTypes = {
  editMode: PropTypes.bool,
  getQueryBuilder: PropTypes.func,
  previewWidget: PropTypes.bool,
  savedSearches: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      sid: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  searchCards: ImmutablePropTypes.mapOf(
    ImmutablePropTypes.contains({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  setWidgetOptions: PropTypes.func.isRequired,
  setWidgetSavedSearch: PropTypes.func.isRequired,
  setWidgetSearchField: PropTypes.func.isRequired,
  setWidgetTitle: PropTypes.func.isRequired,
  setValidity: PropTypes.func.isRequired,
  widgetSavedSearches: PropTypes.objectOf(PropTypes.array),
  widgetSearchCards: PropTypes.objectOf(PropTypes.object),
  widgetTitle: PropTypes.string,
  widgetOptions: PropTypes.shape({
    slices: PropTypes.array,
    checkBoxValue: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  widgetSonraiSearches: PropTypes.object,
  savedSonraiSearches: ImmutablePropTypes.iterableOf(
    ImmutablePropTypes.contains({
      sid: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  setWidgetSonraiSearch: PropTypes.func,
}

export default GaugeConfig
