import { all, put, takeLatest } from 'redux-saga/effects'

import { getClient } from 'apolloClient'
import gql from 'graphql-tag'

function* mySaga() {
  // yield all(([])
}

export default mySaga
